import React from 'react';
import { FlatButton } from '../../components/StyledButton';
import { StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { Entypo, MaterialIcons, AntDesign, FontAwesome } from '@expo/vector-icons';

import Colors from '../../constants/Colors';

const IconHelper = (iconName, iconSet, active) => {
  const defaultIconStyle = { marginRight: 12 };
  const iconColor = active ? { color: Colors.green } : {};
  const iconSize = 24;

  switch (iconSet) {
    case 'Entypo':
      return <Entypo name={iconName} size={iconSize} style={[defaultIconStyle, iconColor]} />;
    case 'MaterialIcons':
      return (
        <MaterialIcons name={iconName} size={iconSize} style={[defaultIconStyle, iconColor]} />
      );
    case 'AntDesign':
      return (
        <AntDesign
          name={iconName}
          size={iconName === 'play' ? iconSize - 2 : iconSize}
          style={[defaultIconStyle, iconColor]}
        />
      );
    case 'FontAwesome':
      return <FontAwesome name={iconName} size={iconSize} style={[defaultIconStyle, iconColor]} />;
    default:
      break;
  }
};

const CustomDrawerContentComponentItem = ({
  navigation,
  to,
  title,
  active,
  iconName,
  iconSet,
  currentUser,
  loginRequired,
  isMobile,
}) => {
  const handlePress = () => {
    if (active) {
      navigation.closeDrawer();
    } else {
      navigation.navigate(to);
    }
  };

  const show = loginRequired ? !!currentUser : true;
  const showIsMobile = isMobile ? Platform.OS !== 'web' : true;

  return (
    show &&
    showIsMobile && (
      <TouchableOpacity
        style={styles.container}
        onPress={handlePress}
        testID={`Nav.Drawer.Item.${title}`}>
        <FlatButton
          style={{ alignSelf: 'flex-start' }}
          textStyle={[active ? styles.activeText : styles.normalText, styles.defaultText]}
          title={title}
          onPress={handlePress}
          iconComponent={IconHelper(iconName, iconSet, active)}
        />
      </TouchableOpacity>
    )
  );
};

const styles = StyleSheet.create({
  activeText: { color: Colors.green },
  normalText: { color: Colors.black },
  defaultText: { fontWeight: 'bold' },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default CustomDrawerContentComponentItem;
