import React, { useState } from 'react';
import { StyleSheet, View, FlatList, TouchableOpacity, Image } from 'react-native';
// import { StyleSheet, View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { debounce, uniqBy } from 'lodash';
import Colors from '../../constants/Colors';
// import redirect from '../lib/web/redirect';
// import { withRouter } from 'next/router';
import {
  currentUserQuery,
  getMyChallengesQuery,
  myPrivateChallengesQuery,
  myPublishedChallengesQuery,
  getChallengesQuery,
} from '../../api/graphql/queries';
import PromptCard from '../../components/PromptCard';
import LoadingIndicator from '../../components/LoadingIndicator';

// import MyChallenges from './mychallenges';
// import MyDraft from './mydrafts';
// import MyResponses from './myresponses';
import {
  ProfileLinkText,
  ProfileBioText,
  ProfileUsernameText,
  PageHeaderText,
  BrandText,
} from '../../components/StyledText';
import { FlatButton, RoundButton } from '../../components/StyledButton';
import { PopUp as EditProfilePopup } from '../../components/StyledPopup';
import { FormInput } from '../../components/StyledForm';
import { updateUserMutation } from '../../api/graphql/mutations';
import { analyticsActionForButtons } from '../../lib/analyticsButtonClickHelper';
import Anchor from '../../components/Anchor';

// import { BottomNavigation } from 'react-native-material-ui';

// const Card = ({ id, title, tag, content, username, responsesCount, anonymous }) => (
//   <View accessibilityRole="link" href={`/prompts/${id}`} as={`/prompts/${id}`}>
//     <View>
//       <Text>{title}</Text>
//       <Text>{tag}</Text>
//     </View>
//     <Text>{content}</Text>
//     <Text>{username}</Text>
//     <Text>{responsesCount}</Text>
//     <Text>{anonymous}</Text>
//   </View>
// );
const TreeImage = () => (
  <Image
    style={{ width: 122, height: 102, marginVertical: 50 }}
    source={require('../../assets/images/tree.png')}
  />
);

const challengeMapper = challenge => ({
  ...challenge,
  content: challenge?.steps?.[0]?.content || '',
  username: challenge?.user?.username,
  response: challenge?.myResponse?.content,
});

const Profile = ({ navigation }) => {
  const { data: userData = {} } = useQuery(currentUserQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const { currentUser } = userData;

  // responses query
  const {
    data: myPublishedChallengesData,
    fetchMore: fetchMoreMyPublishedChallengesQuery,
    loading: myPublishedChallengesLoading,
  } = useQuery(myPublishedChallengesQuery, {
    variables: { limit: 30, offset: 0, sort: 'hotRank' },
    notifyOnNetworkStatusChange: true,
  });
  const mappedmMyPublishedChallengesData = myPublishedChallengesData?.myPublishedChallenges?.map(
    challengeMapper
  );

  const {
    data: myPrivateChallengesData,
    fetchMore: fetchMoreMyPrivateChallengesQuery,
    loading: myPrivateChallengesLoading,
  } = useQuery(myPrivateChallengesQuery, {
    variables: { limit: 30, offset: 0, sort: 'hotRank' },
    notifyOnNetworkStatusChange: true,
  });

  const mappedMyPrivateChallengesData = myPrivateChallengesData?.myPrivateChallenges?.map(
    challengeMapper
  );

  const {
    data: myChallengedData,
    fetchMore: fetchMoreMyChallengesQuery,
    loading: getMyChallengesLoading,
  } = useQuery(getMyChallengesQuery, {
    variables: { limit: 30, offset: 0, sort: 'hotRank' },
    notifyOnNetworkStatusChange: true,
  });
  const mappedmMyChallengedData = myChallengedData?.myChallenges?.map(challengeMapper);

  const {
    data: pinnedChallenges,
    fetchMore: fetchMoreMyPinnedChallenges,
    loading: pinnedChallengesLoading,
  } = useQuery(getChallengesQuery, {
    variables: { limit: 30, offset: 0, pinned: true, sort: 'createdAt' },
    notifyOnNetworkStatusChange: true,
  });

  const mappedPinnedChallengesData = pinnedChallenges?.challenges?.map(challengeMapper);

  const {
    data: followedChallenges,
    fetchMore: fetchMoreMyFollowedChallenges,
    loading: followedChallengesLoading,
  } = useQuery(getChallengesQuery, {
    variables: { limit: 30, offset: 0, isFollowing: true, sort: 'createdAt' },
    notifyOnNetworkStatusChange: true,
  });

  const mappedFollowedChallenges = followedChallenges?.challenges?.map(challengeMapper);

  const {
    data: scheduledChallenges,
    fetchMore: fetchMoreScheduledChallenges,
    loading: scheduledChallengesLoading,
  } = useQuery(getChallengesQuery, {
    variables: { limit: 30, offset: 0, sort: 'createdAt', isScheduled: true },
  });

  const mappedScheduledChallenges = scheduledChallenges?.challenges?.map(challengeMapper);

  const [selectedTab, updateSelectedTab] = useState('prompts');
  const [modalVisible, setModalVisible] = useState(false);
  const [bio, setBio] = useState();
  const [website, setWebsite] = useState();
  const [callCreateChallenge] = useMutation(updateUserMutation, {
    refetchQueries: [{ query: currentUserQuery }],
  });

  const handleSaveChanges = async () => {
    await callCreateChallenge({
      notifyOnNetworkStatusChange: true,
      variables: {
        profileDetails: {
          bio: bio || currentUser?.bio,
          website: website || currentUser?.website,
        },
      },
    });
    setModalVisible(false);
  };

  let data = getDataForProfileTab({
    selectedTab,
    mappedmMyChallengedData,
    mappedmMyPublishedChallengesData,
    mappedScheduledChallenges,
    mappedMyPrivateChallengesData,
    mappedFollowedChallenges,
    mappedPinnedChallengesData,
  });

  const onListEndReachedWithoutDebounce = () => {
    // possible selectedTab Values: prompts, responses, drafts, scheduled, pinned, isFollowing

    if (navigation?.state?.routeName === 'profile' && !isLoading) {
      // possible selectedTab Values: prompts, responses, private, pinned, isFollowing
      const dataKeys = {
        prompts: 'myChallenges',
        responses: 'myPublishedChallenges',
        scheduled: 'challenges',
        private: 'myPrivateChallenges',
        pinned: 'challenges',
        isFollowing: 'challenges',
      };

      const mapedFetchMoreChallengeQueries = {
        prompts: fetchMoreMyChallengesQuery,
        responses: fetchMoreMyPublishedChallengesQuery,
        private: fetchMoreMyPrivateChallengesQuery,
        scheduled: fetchMoreScheduledChallenges,
        pinned: fetchMoreMyPinnedChallenges,
        isFollowing: fetchMoreMyFollowedChallenges,
      };

      mapedFetchMoreChallengeQueries &&
        selectedTab &&
        mapedFetchMoreChallengeQueries[selectedTab] &&
        mapedFetchMoreChallengeQueries[selectedTab]({
          variables: {
            offset: data.length || 0,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }
            const updatedResult = Object.assign({}, prev, {
              [dataKeys[selectedTab]]: uniqBy(
                [...prev[dataKeys[selectedTab]], ...fetchMoreResult[dataKeys[selectedTab]]],
                ch => ch?.id
              ),
            });

            return updatedResult;
          },
        });
    }
  };

  const onListEndReached = debounce(onListEndReachedWithoutDebounce, 500);

  const isLoading =
    getMyChallengesLoading ||
    myPublishedChallengesLoading ||
    myPrivateChallengesLoading ||
    pinnedChallengesLoading ||
    followedChallengesLoading ||
    scheduledChallengesLoading;

  return (
    <View style={styles.container}>
      {isLoading && <LoadingIndicator />}

      <ProfileModal
        username={currentUser?.username}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        website={currentUser?.website}
        setWebsite={setWebsite}
        bio={currentUser?.bio}
        setBio={setBio}
        navigation={navigation}
        handleSaveChanges={handleSaveChanges}
      />
      <FlatList
        ListHeaderComponent={
          <HeaderListComponent
            username={currentUser?.username}
            bio={currentUser?.bio}
            website={currentUser?.website}
            selectedTab={selectedTab}
            updateSelectedTab={updateSelectedTab}
            mappedmMyChallengedData={mappedmMyChallengedData}
            mappedmMyPublishedChallengesData={mappedmMyPublishedChallengesData}
            mappedMyPrivateChallengesData={mappedMyPrivateChallengesData}
            mappedFollowedChallenges={mappedFollowedChallenges}
            mappedPinnedChallengesData={mappedPinnedChallengesData}
            mappedScheduledChallenges={mappedScheduledChallenges}
            setModalVisible={setModalVisible}
          />
        }
        data={data}
        renderItem={({ item }) => (
          <PromptCard
            {...item}
            currentUser={currentUser}
            userId={item.user.id}
            cardType="prompt"
            testID={`Profile.PropmptListItem.${selectedTab}.${item.id}`}
            onPress={() => {
              navigation.navigate('responses', { id: item.id });
            }}
          />
        )}
        keyExtractor={({ id }) => id}
        ListEmptyComponent={ListEmptyComponent}
        contentContainerStyle={{
          alignContent: 'center',
          paddingBottom: 30,
        }}
        onEndReached={onListEndReached}
        testID={'Profile.PromptsList'}
        // style={{ width: '100%', flex: 1 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // TODO: centrilize
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignContent: 'center',

    backgroundColor: Colors.backgroundGrey,
  },
  loading: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    color: 'blue',
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 16,
  },
  text: {
    alignItems: 'center',
    fontSize: 24,
    marginBottom: 24,
  },
});

export default Profile;

const ProfileModal = ({
  username,
  setModalVisible,
  modalVisible,
  navigation,
  website,
  setWebsite,
  bio,
  setBio,
  handleSaveChanges,
}) => (
  <EditProfilePopup
    setModalVisible={setModalVisible}
    modalVisible={modalVisible}
    navigation={navigation}>
    <PageHeaderText>Edit Profile</PageHeaderText>
    <TreeImage />
    <FormInput
      label={'Name'}
      value={username}
      disabled
      // onChangeText={setWebsite}
      // placeholder="A link on your profile page"
    />
    <FormInput
      label={'Website'}
      value={website}
      onChangeText={setWebsite}
      placeholder="A link to your book or website"
    />
    <FormInput
      label={'Bio'}
      value={bio}
      onChangeText={setBio}
      placeholder="Write a little about yourself"
      multiline
      wordlimit={150}
    />
    <View style={{ flexDirection: 'row', marginTop: 25 }}>
      <FlatButton style={{ margin: 40 }} title="Cancel" onPress={() => setModalVisible(false)} />
      <RoundButton
        style={{ marginVertical: 40, marginLeft: 30 }}
        title="Save Changes"
        onPress={handleSaveChanges}
      />
    </View>
  </EditProfilePopup>
);

const HeaderListComponent = ({
  username,
  bio,
  website,
  selectedTab,
  updateSelectedTab,
  mappedmMyChallengedData,
  mappedmMyPublishedChallengesData,
  mappedScheduledChallenges,
  mappedMyPrivateChallengesData,
  setModalVisible,
  mappedPinnedChallengesData,
  mappedFollowedChallenges,
}) => (
  <View style={{ alignSelf: 'center', maxWidth: 800, width: '100%', paddingTop: 40 }}>
    <PageHeaderText style={{ alignSelf: 'flex-start' }}>MY PROFILE</PageHeaderText>
    <View
      style={{
        backgroundColor: Colors.white,
        // maxWidth: 1000,
        width: '100%',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
      }}>
      <TouchableOpacity
        style={{
          alignSelf: 'flex-end',
          flexDirection: 'row',
          position: 'absolute',
          top: 10,
          right: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={() => {
          setModalVisible(true);
          analyticsActionForButtons('edit profile button');
        }}>
        <Image
          source={require('../../assets/images/icons/edit.png')}
          style={{
            width: 22,
            height: 23,
          }}
          resizeMode="contain"
        />
      </TouchableOpacity>
      <TreeImage />
      <ProfileUsernameText>@{username}</ProfileUsernameText>
      <ProfileBioText style={{ marginTop: 20 }}>{bio}</ProfileBioText>

      <ProfileLinkText style={{ marginTop: 20 }}>
        <Anchor href={website} />
      </ProfileLinkText>
    </View>
    <View
      style={{
        flex: 1,
        backgroundColor: Colors.white,
        // maxWidth: 1000,
        width: '100%',
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: 10,
      }}>
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('prompts');
        }}
        title={`Prompts (${mappedmMyChallengedData?.length || 0})`}
        titleForAnalytics="Prompts (X) - profile"
        active={selectedTab === 'prompts'}
        testID={'Profile.Prompts'}
      />
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('responses');
        }}
        title={`Responses (${mappedmMyPublishedChallengesData?.[0]?.userResponseCount || 0})`}
        titleForAnalytics="Responses (X) - profile"
        active={selectedTab === 'responses'}
        testID={'Profile.Responses'}
      />
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('private');
        }}
        title={`Private (${mappedMyPrivateChallengesData?.length || 0})`}
        titleForAnalytics="Private (X) - profile"
        active={selectedTab === 'private'}
        testID={'Profile.Private'}
      />
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('scheduled');
        }}
        title={`Scheduled (${mappedScheduledChallenges?.length || 0})`}
        titleForAnalytics="Scheduled (X) - profile"
        active={selectedTab === 'scheduled'}
        testID={'Profile.Scheduled'}
      />
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('pinned');
        }}
        title={`Saved (${mappedPinnedChallengesData?.length || 0})`}
        titleForAnalytics="Saved (X) - profile"
        active={selectedTab === 'pinned'}
        testID={'Profile.Pinned'}
      />
      <FlatButton
        style={{ marginRight: 10 }}
        onPress={() => {
          updateSelectedTab('isFollowing');
        }}
        title={`Followed (${mappedFollowedChallenges?.length || 0})`}
        titleForAnalytics="Followed (X) - profile"
        active={selectedTab === 'isFollowing'}
        testID={'Profile.isFollowing'}
      />
    </View>
  </View>
);

const ListEmptyComponent = () => (
  <View
    style={{
      alignSelf: 'center',
      maxWidth: 800,
      width: '100%',
    }}>
    <BrandText style={{ textAlign: 'center' }}>
      {'\n'}No posts to display{'\n'}
    </BrandText>
  </View>
);

const getDataForProfileTab = ({
  selectedTab,
  mappedmMyChallengedData,
  mappedmMyPublishedChallengesData,
  mappedMyPrivateChallengesData,
  mappedPinnedChallengesData,
  mappedFollowedChallenges,
  mappedScheduledChallenges,
}) => {
  let data = mappedmMyChallengedData;
  if (selectedTab === 'responses') {
    data = mappedmMyPublishedChallengesData;
  } else if (selectedTab === 'private') {
    data = mappedMyPrivateChallengesData;
  } else if (selectedTab === 'pinned') {
    data = mappedPinnedChallengesData;
  } else if (selectedTab === 'isFollowing') {
    data = mappedFollowedChallenges;
  } else if (selectedTab === 'scheduled') {
    data = mappedScheduledChallenges;
  }
  return data;
};
