import * as React from 'react';
import { Text, View, TouchableOpacity, Linking, Image } from 'react-native';

import { analyticsActionForButtons } from '../lib/analyticsButtonClickHelper';

const DownloadApp = ({ match }) => (
  <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, paddingTop: 50 }}>
    <Text>Download Baobab's mobile app</Text>

    <Image
      style={{ width: 205, height: 240, marginVertical: 50 }}
      source={require('../assets/images/icon.png')}
    />

    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL('https://itunes.apple.com/us/app/baobab/id1358563785');
          analyticsActionForButtons('Download button IOS');
        }}>
        <Image
          style={{ width: 135, height: 40, marginHorizontal: 25 }}
          source="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-09-23&kind=iossoftware&bubble=ios_apps"
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(
            'https://play.google.com/store/apps/details?id=com.arrygoo.baobab&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          );
          analyticsActionForButtons('Download button Android');
        }}>
        <Image
          style={{ width: 140, height: 60, marginHorizontal: 25 }}
          source="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
        />
      </TouchableOpacity>
    </View>
  </View>
);

export default DownloadApp;
