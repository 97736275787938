import React, { useState, useRef, useContext } from 'react';
import { View } from 'react-native';
// import Modal from 'modal-enhanced-react-native-web';
// import { get } from 'lodash';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { Menu, MenuOptions, MenuOption, MenuTrigger, renderers } from 'react-native-popup-menu';
// import DateTimePicker from '@react-native-community/datetimepicker';
import { useMutation } from '@apollo/client';
import Colors from '../../constants/Colors';
import { createChallengeMutation } from '../../api/graphql/mutations';
import {
  myPrivateChallengesQuery,
  getMyChallengesQuery,
  getChallengesQuery,
} from '../../api/graphql/queries';
import { PageHeaderText, BrandText, ContentText } from '../../components/StyledText';
import { FlatButton, BackButton } from '../../components/StyledButton';
import useWindowDimensions from '../../lib/useWindowDimensions';
import MultiLineTextInput from '../../components/MultiLineForm';
import ModalContext from '../../lib/context';

const NewChallenge = ({ navigation }) => {
  let ArrowDownRef = useRef(null);
  // const headerData = navigation.getParam('comments');
  const content = navigation.getParam('content');
  const title = 'Comment';
  // console.log(headerData);
  // const content = headerData?.[0]?.content;
  // console.log(headerData);
  const { isLargeScreen } = useWindowDimensions();

  const [createChallenge] = useMutation(createChallengeMutation, {
    // onError: err => {
    //   const errorMessage = err?.graphQLErrors?.[0]?.message;
    // },
    refetchQueries: [
      { query: getMyChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
      { query: myPrivateChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, sort: 'createdAt', isScheduled: true },
      },
    ],
  });

  const [responseVisible, setResponseVisible] = useState(true);
  // const [title, setTitle] = useState('');
  // const [content, setContent] = useState('');
  const [schedule, setSchedule] = useState(); // TODO: turn to days from now
  const [tag] = useState();
  const [isAnonymouse, setIsAnonymouse] = useState(false);
  // const isAdmin = get(userData, 'currentUser.isAdmin', false);
  const [, setModal] = useContext(ModalContext);

  const submitChallenge = async () => {
    console.log(title, content, schedule, isAnonymouse, tag);
    const createChallengeResult = await createChallenge({
      notifyOnNetworkStatusChange: true,
      variables: {
        challenge: {
          title,
          publishInXDays: schedule,
          steps: [
            {
              order: 0,
              content,
            },
          ],
          anonymous: isAnonymouse,
          tag,
        },
      },
    });
    console.log('----', createChallengeResult, createChallengeResult?.data?.createChallenge?.id);
    navigation.navigate('responses', { id: createChallengeResult?.data?.createChallenge?.id });
  };

  // const MIN_HEIGHT = 500;
  // const [pageHeight, setHeight] = useState(MIN_HEIGHT);
  // const [steps, setSteps] = useState([
  //   {
  //     order: 0,
  //     content: '',
  //   },
  // ]);

  // const handleSetSchedule = event => {
  //   setSchedule(event?.nativeEvent?.text || '');
  // };

  const flipAnonymouse = () => {
    setIsAnonymouse(!isAnonymouse);
    return false;
  };

  // const handleSetSteps = (stepText, order) => {
  //   setSteps(prevState => {
  //     const newSteps = prevState.steps;
  //     newSteps[order].content = stepText;
  //     return {
  //       steps: newSteps,
  //     };
  //   });
  // };

  // const onContentSizeChange = event => {
  //   const {
  //     nativeEvent: {
  //       contentSize: { height },
  //     },
  //   } = event;
  //   setHeight(Math.max(MIN_HEIGHT, height));
  // };

  const onSetSchedulePressed = () => {
    // setModal({
    //   title: 'Schedule post',
    //   message: "You're about report this post as inappropriate.",
    //   ContentComponent: DatePicker,
    //   buttons: {
    //     ok: {
    //       title: 'Save',
    //       action: async () => {},
    //     },
    //   },
    // });
    setModal({
      title: 'Coming soon',
      message:
        "Scheduling posts isn't available for all users yet, please check back in a few weeks :).",
      buttons: {
        ok: {
          title: 'OK',
          action: async () => {},
        },
      },
    });
  };

  const SaveMenu = () => (
    <Menu ref={ArrowDownRef} renderers={renderers.Popover} rendererProps={{ placement: 'bottom' }}>
      <MenuTrigger>
        <FlatButton
          titleForAnalytics="newComment icon up"
          onPress={() => ArrowDownRef.current.open()}
          style={{
            borderRightWidth: 1,
            backgroundColor: Colors.orange,
            borderBottomRightRadius: 10,
            borderTopRightRadius: 10,
          }}
          imageSource={require('../../assets/images/icons/up.png')}
          imageStyle={{
            width: 20,
            tintColor: 'white',
            ...(true && { transform: [{ rotate: '180deg' }] }),
          }}
        />
      </MenuTrigger>
      <MenuOptions style={{ padding: 10 }}>
        <MenuOption
          style={{
            flexDirection: 'row',
            paddingBottom: 10,
            alignContent: 'center',
          }}
          onSelect={onSetSchedulePressed}>
          <MaterialIcons name="schedule" size={20} style={{ alignSelf: 'center' }} />
          <BrandText style={{ marginLeft: 5, color: Colors.mattGrey }}>Schedule: now</BrandText>
        </MenuOption>
        <MenuOption
          style={{
            flexDirection: 'row',
            alignContent: 'center',
          }}
          onSelect={flipAnonymouse}>
          <Entypo name="mask" size={20} style={{ alignSelf: 'center' }} />
          <BrandText style={{ marginLeft: 5 }}>
            Privacy: {isAnonymouse ? 'Anonymous' : 'Public'}
          </BrandText>
        </MenuOption>
      </MenuOptions>
    </Menu>
  );

  return (
    <MultiLineTextInput
      containerStyle={{
        backgroundColor: Colors.backgroundGrey,
        // padding: 30,
        paddingHorizontal: '10%',
        // paddingTop: 10,
        // flex: 1,
        // alignItems: 'center',
      }}
      multiLineTextStyle={{ backgroundColor: Colors.white, padding: 10, lineHeight: 20 }}
      multilinePlaceholder="Challenge Description. e.g: Describe the standard life of a piece of chewing gum"
      HeaderComponent={() => (
        <>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <BackButton navigation={navigation} isLargeScreen={isLargeScreen} />
            <View style={{ flexDirection: 'row' }}>
              <FlatButton
                title="Save"
                onPress={submitChallenge}
                style={{
                  borderRightWidth: 1,
                  borderColor: Colors.lighterOranger,
                  backgroundColor: Colors.orange,
                  borderBottomLeftRadius: 10,
                  borderTopLeftRadius: 10,
                  paddingHorizontal: 10,
                }}
                important
              />
              <SaveMenu />
            </View>
          </View>
          <View style={{ marginTop: 5 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <PageHeaderText>{title}</PageHeaderText>
              <FlatButton
                titleForAnalytics="newComment up icon"
                style={{
                  borderColor: Colors.lightGrey,
                  zIndex: 999,
                }}
                testID={'Nav.Settings'}
                imageSource={require('../../assets/images/icons/up.png')}
                imageRightSide
                imageStyle={{
                  width: 20,
                  ...(responseVisible && { transform: [{ rotate: '180deg' }] }),
                }}
                onPress={() => setResponseVisible(!responseVisible)}
                active={responseVisible}
              />
            </View>
            {responseVisible && <ContentText>{content}</ContentText>}
          </View>
          <View
            style={{
              borderBottomColor: Colors.blueGrey,
              borderBottomWidth: 1,
              opacity: 0.3,
              // marginLeft: 30,
              marginVertical: 20,
            }}
          />
        </>
      )}
    />
  );
};

export default NewChallenge;
