import React from 'react';
import { Platform } from 'react-native';
import { createBottomTabNavigator, createMaterialTopTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';

import Prompts from '../screens/Prompts';
import LoginScreen from '../screens/LoginScreen';
import RegistrationScreen from '../screens/RegistrationScreen';
import NewChallenge from '../screens/NewChallenge';
import Responses from '../screens/Responses';
import DownloadApp from '../screens/DownloadApp';
import Profile from '../screens/Profile';
import HelpAndSupportScreen from '../screens/HelpAndSupportScreen';
import SettingsScreen from '../screens/SettingsScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import { createDrawerNavigator } from 'react-navigation-drawer';

// import useWindowDimensions from '../lib/useWindowDimensions';
import Navbar from './Navbar';
import CustomDrawerContentComponent from './DrawerNavigation/CustomDrawerContentComponent';
import { FlatButton } from '../components/StyledButton';
import MyChallenges from '../screens/Profile/mychallenges';
import OtherUserProfile from '../screens/Profile/otherUser';
import NewResponse from '../screens/NewResponse';
import NewComment from '../screens/NewComment';

// const TabBarComponent = props => <BottomTabBar {...props} />;

// const TabBarComponent = props => {
//   const screenData = useWindowDimensions();
//   const { isLargeScreen } = screenData;

//   return isLargeScreen ? <></> : <BottomTabBar {...props} />;
// };

const config = Platform.select({
  web: { headerMode: 'none' },
  default: { headerMode: 'none' },
});

const HomeStack = createStackNavigator(
  {
    prompts: Prompts,
    responses: Responses,
    login: LoginScreen,
    registration: RegistrationScreen,
    create: {
      screen: NewChallenge,
      mode: 'modal',
    },
    respond: NewResponse,
    comment: NewComment,
    profile: Profile,
    user: {
      screen: OtherUserProfile,
      path: 'user/u/:username',
    },
    download: DownloadApp,
    mychallenges: MyChallenges,
    notifications: NotificationsScreen,
    settings: SettingsScreen,
    help: HelpAndSupportScreen,
  },
  config
);

HomeStack.navigationOptions = ({ navigation }) => ({
  tabBarLabel: 'Prompts',
  tabBarIcon: ({ focused }) => (
    <FlatButton
      titleForAnalytics="prompts icon (on nav)"
      imageSource={
        focused
          ? require('../assets/images/navigation/promptsActive.png')
          : require('../assets/images/navigation/promptsInactive.png')
      }
      transparent
      onPress={() => navigation.navigate('prompts')}
      // style={{ backgroundColor: 'white', color: route === '/' ? 'black' : 'white' }}
      // color={route === '/' ? Colors.grey : Colors.green}
    />
  ),
});

HomeStack.path = '';

// const LinksStack = createStackNavigator(
//   {
//     play: LinksScreen,
//   },
//   config
// );

// LinksStack.navigationOptions = ({ navigation }) => ({
//   tabBarLabel: 'Play',
//   tabBarIcon: ({ focused }) => (
//     <FlatButton
//       titleForAnalytics="goals inactive icon"
//       imageSource={require('../assets/images/navigation/goalsInactive.png')}
//       transparent
//       onPress={() => navigation.navigate('play')}

//       // style={{ backgroundColor: 'white', color: route === '/' ? 'black' : 'white' }}
//       // color={route === '/' ? Colors.grey : Colors.green}
//     />
//   ),
// });

// LinksStack.path = '';

// const SettingsStack = createStackNavigator(
//   {
//     settings: LoginScreen,
//   },
//   config
// );

// SettingsStack.navigationOptions = {
//   tabBarLabel: 'Login',
//   tabBarIcon: ({ focused }) => (
//     <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-options' : 'md-options'} />
//   ),
// };

// SettingsStack.path = '';

const TabNavigator = createBottomTabNavigator(
  {
    HomeStack,
    // LinksStack,
    // SettingsStack,
  },
  {
    swipeEnabled: false,
  }
  // {
  //   tabBarComponent: props => (
  //     <TabBarComponent {...props} style={{ borderTopColor: '#605F60' }} />
  //   )
  // }
);

const TabNavigatorTop = createMaterialTopTabNavigator(
  {
    HomeStack,
    // LinksStack,
    // SettingsStack,
  },
  {
    tabBarComponent: Navbar,
    swipeEnabled: false,
    animationEnabled: false,
  }
);

TabNavigator.path = '';
TabNavigatorTop.path = '';

const CustomNavigator = props => {
  // const { navigation } = props;
  // const screenData = useWindowDimensions();
  // const { isLargeScreen } = screenData;
  const isLargeScreen = true;
  return isLargeScreen ? <TabNavigatorTop {...props} /> : <TabNavigator {...props} />;
};
CustomNavigator.router = TabNavigator.router;

const DraverNavigation = createDrawerNavigator(
  {
    baobab: {
      screen: TabNavigatorTop,
      navigationOptions: {
        drawerLockMode: Platform.OS === 'web' ? 'locked-closed' : 'unlocked',
      },
      path: '',
    },
  },
  {
    drawerPosition: 'right',
    drawerWidth: 220,
    contentComponent: CustomDrawerContentComponent,
  }
);

export default DraverNavigation;
