import React from 'react';
import {
  View,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
  SafeAreaView,
  Platform,
  StyleSheet,
} from 'react-native';

const onPressContainer = Platform.OS === 'web' ? () => {} : Keyboard.dismiss;

const MultiLineTextInput = ({
  placeholder,
  HeaderComponent = () => <></>,
  FooterComponent = () => <></>,
  containerStyle,
  multiLineTextStyle,
  multilinePlaceholder,
}) => (
  <View style={[styles.container, containerStyle]}>
    <TouchableWithoutFeedback onPress={onPressContainer}>
      <SafeAreaView style={styles.flexContainer}>
        <HeaderComponent />
        <KeyboardAvoidingView
          behavior="padding"
          style={styles.flexContainer}
          keyboardVerticalOffset={70}
          onPress={Keyboard.dismiss}>
          <View style={styles.flexContainer}>
            <TextInput
              placeholder={multilinePlaceholder}
              style={[styles.multiLineInputStyles, multiLineTextStyle]}
              multiline
            />
            {/* <View style={{ flex: 1 }} /> */}
          </View>
          <FooterComponent />
        </KeyboardAvoidingView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  </View>
);

const styles = StyleSheet.create({
  container: { flex: 1, paddingHorizontal: 15 },
  flexContainer: {
    flex: 1,
  },
  multiLineInputStyles: {
    minHeight: '100%',
  },
});

export default MultiLineTextInput;
