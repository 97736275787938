import React from 'react';
import { Text } from 'react-native';
import Colors from '../constants/Colors';

export const BrandText = props => {
  return (
    <Text
      {...props}
      style={[
        { color: Colors.promptText, fontSize: 14 },
        props.style,
        { fontFamily: props.active ? 'montserrat-bold' : 'montserrat' },
      ]}
      active={undefined}
    />
  );
};

export const UsernameText = props => {
  return <BrandText {...props} style={[props.style, { opacity: 0.7, fontSize: 14 }]} />;
};

export const DateText = props => {
  return <BrandText {...props} style={[props.style, { opacity: 0.3, fontSize: 12 }]} />;
};

export const TagText = props => {
  return <BrandText {...props} style={[props.style, { fontSize: 12, opacity: 0.5 }]} />;
};

export const ContentText = props => {
  return (
    <BrandText {...props} style={[props.style, { fontSize: 16, lineHeight: 26, opacity: 0.7 }]} />
  );
};

export const TitleText = props => {
  // TODO: Switch font back to jomolhari
  return (
    <BrandText {...props} style={[props.style, { fontFamily: 'comfortaa-bold', fontSize: 25 }]} />
  );
};

export const PageHeaderText = props => {
  // TODO: Switch font back to jomolhari
  return (
    <BrandText
      {...props}
      style={[
        {
          fontFamily: 'comfortaa-bold',
          fontSize: 25,
          textTransform: 'uppercase',
          marginVertical: 10,
        },

        props.style,
      ]}
    />
  );
};

export const ProfileUsernameText = props => {
  return <BrandText {...props} style={[props.style, { fontSize: 20, color: Colors.promptText }]} />;
};

export const ProfileBioText = props => {
  return (
    <BrandText
      {...props}
      style={[props.style, { fontSize: 16, color: Colors.promptText, opacity: 0.7 }]}
    />
  );
};

export const ProfileLinkText = props => {
  return <BrandText {...props} style={[props.style, { fontSize: 16, color: Colors.link }]} />;
};

export const WelcomeText = props => {
  return (
    <BrandText
      {...props}
      style={[props.style, { fontFamily: 'montserrat-bold', fontSize: 45, textAlign: 'center' }]}
    />
  );
};

export const FormLabel = props => {
  return (
    <BrandText {...props} style={[props.style, { marginVertical: 7, color: Colors.blueGrey }]} />
  );
};

export const ListHeaderText = props => (
  <BrandText {...props} style={[props.style, { fontSize: 18, lineHeight: 26, opacity: 0.7 }]} />
);
