import * as SecureStore from 'expo-secure-store';

import React, { useState, useRef, useContext } from 'react';
import { View, Image, Platform, Text } from 'react-native';
import cookie from 'cookie';
import useWindowDimensions from '../../lib/useWindowDimensions';
import Colors from '../../constants/Colors';
import formValidators from '../../lib/formValidators';
import styles from '../LoginScreen/style';
import { BackgroundTrees } from '../LoginScreen';
import { WelcomeText, BrandText } from '../../components/StyledText';
import { RoundButton, FlatButton } from '../../components/StyledButton';
import { registerUserMutation } from '../../api/graphql/mutations';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { currentUserQuery } from '../../api/graphql/queries';
import { googleanalytics } from '../../lib/apolloClient';
import { FormInput } from '../../components/StyledForm';
import SingleLineForm from '../../components/SingleLineForm';

import registerForPushNotifications from '../../lib/registerForPushNotifications';
import { setPushNotificationToken } from '../../lib/pushNotificationTokenHelpers';
import notificationModalContext from '../../lib/notificationModalContext';
const genericErrorMsgText = 'Something went wrong, please try again later.';

const RegistrationScreen = props => {
  // constructor() {
  //   super();

  //   // const today = new Date();

  //   state = {
  //     // today,

  //     // penname: '',
  //     emailAddress: '',
  //     password: '',
  //     passwordConfirm: '',

  //     errorMessage: '',
  //     errorMessageUsername: '',
  //     errorMessageEmailAddress: '',
  //     errorMessagePassword: '',
  //     errorMessagePasswordConfirm: '',
  //   };

  // }
  const client = useApolloClient();

  const [penname, setPenname] = useState('');
  const [errorMessageUsername, setErrorMessageUsername] = useState('');

  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmValid, setPasswordConfirmValid] = useState(true);
  const [errorMessagePasswordConfirm, setErrorMessagePasswordConfirm] = useState('');

  const [emailAddressValid, setEmailAddressValid] = useState(true);
  const [errorMessageEmailAddress, setErrorMessageEmailAddress] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const [genericErrorMsg, setGenericErrorMsg] = useState('');
  const [, setModal] = useContext(notificationModalContext);

  const [callRegisterUser, { loading }] = useMutation(registerUserMutation, {
    refetchQueries: [{ query: currentUserQuery }],
    awaitRefetchQueries: true,
    onCompleted: async ({ registerUser }) => {
      const { token } = registerUser;
      console.log('--Seting token', token);

      if (Platform.OS === 'web') {
        document.cookie = cookie.serialize('TOKEN_KEY', token, {
          maxAge: 30 * 24 * 60 * 60, // 30 days
        });
      } else {
        await SecureStore.setItemAsync('TOKEN_KEY', token);
      }

      // Force a reload of all the current queries now that the user is
      // logged in
      client.resetStore().then(() => {
        props.navigation.navigate('prompts');
      });
    },
    onError: err => {
      const errorMessage = err?.graphQLErrors?.[0]?.message;
      if (errorMessage === 'Username already exists. [403]') {
        setErrorMessageUsername('Username already exists');
      } else if (errorMessage.includes('EMAIL_ALREADY_EXISTS')) {
        setErrorMessageEmailAddress('Email already exist');
      } else {
        setGenericErrorMsg(genericErrorMsgText);
      }
    },
  });

  const register = async () => {
    setGenericErrorMsg('');

    callRegisterUser({
      notifyOnNetworkStatusChange: true,
      variables: {
        profileDetails: {
          username: penname,
          password,
          emailAddress,
        },
      },
    })
      .then(result => {
        const userData = result?.data?.registerUser;

        if (userData && Platform.OS !== 'web') {
          setTimeout(() => {
            setModal({
              title: 'Push notifications',
              message: 'Would you like to receive push notifications from us?',
              buttons: {
                ok: {
                  title: 'Yes',
                  action: registerForPushNotifications,
                },
                cancel: {
                  title: 'No',
                  action: () => setPushNotificationToken('', 'SAIDNO'),
                },
              },
            });
          }, 600000); // note: 600000 (10mins in ms), change it to 4000 etc while testing
        }
      })
      .catch(e => {
        setGenericErrorMsg(genericErrorMsgText);
      });

    googleanalytics.addParameter('uid', penname);
  };

  const { data: userData = {} } = useQuery(currentUserQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const { currentUser } = userData;
  if (currentUser) {
    props.navigation.navigate('prompts');
  }

  const { isLargeScreen } = useWindowDimensions();
  let EmailInputRef = useRef(null);
  let PasswordInputRef = useRef(null);
  let PasswordRetypeInputRef = useRef(null);

  const isFormValid = () =>
    penname &&
    emailAddress &&
    emailAddressValid &&
    password &&
    passwordValid &&
    passwordConfirmValid &&
    password === passwordConfirm;

  const validatePenname = event => {
    const pennameLocal = event.nativeEvent.text;
    const valid = formValidators.validateEntered(pennameLocal);

    setPenname(pennameLocal.replace(/\s/g, ''));
    setErrorMessageUsername(valid ? '' : 'Please enter your penname.');
  };

  const validateEmailAddress = event => {
    const emailAddressLocal = event.nativeEvent.text;
    const message = 'Please enter a valid email';

    setEmailAddress(emailAddressLocal.replace(/\s/g, ''));

    const valid =
      formValidators.validateEntered(emailAddressLocal) &&
      formValidators.validateEmail(emailAddressLocal);

    setErrorMessageEmailAddress(!valid ? message : '');

    if (!valid) {
      setEmailAddressValid(false);
    } else {
      setEmailAddressValid(true);
    }

    // // Only do this when we know it's valid email address and on blur for performance reasons
    // if (valid && eventType === 'onEndEditing') {
    //   // So far it's a valid email address, now let's see if it's already registered
    //   try {
    //     const apolloClient = props.client;

    //     const queryResult = await apolloClient.query({
    //       fetchPolicy: 'network-only',
    //       query: userWithEMailAddressExistsQuery,
    //       variables: {
    //         emailAddressLocal,
    //       },
    //     });

    //     if (queryResult.data.userWithEMailAddressExists) {
    //       valid = false;
    //       message =
    //         'Wait. That email address is already registered. Maybe you meant to login?';
    //     }
    //   } catch (e) {
    //     console.log('error', e); // eslint-disable-line no-console
    //   }
    // } else {
    //   message = 'Please enter an email address like name@domain.com.';
    // }
  };

  const validatePassword = event => {
    validatePasswords({ firstPassword: event.nativeEvent.text, secondPassword: passwordConfirm });
  };

  const validatePasswordConfirm = event => {
    validatePasswords({ firstPassword: password, secondPassword: event.nativeEvent.text });
  };

  const validatePasswords = ({ firstPassword, secondPassword }) => {
    const messageDontMatch = 'Your passwords do not match';
    const messageNotEntered = 'Please enter a password';

    console.log('---firstPassword', firstPassword, '---secondPassword:', secondPassword);
    const enteredPassword = formValidators.validateEntered(firstPassword);
    const enteredConfirm = formValidators.validateEntered(secondPassword);
    // console.log('---enteredPassword, validPassword---', enteredPassword, enteredConfirm);
    const passwordsMatch = firstPassword === secondPassword;
    // console.log('---passwordsMatch---', passwordsMatch);

    let message = '';

    if (!enteredPassword) {
      message = messageNotEntered;
      setErrorMessagePassword(message);
      setPasswordValid(false);
    } else if (enteredPassword) {
      setPasswordValid(true);
      setErrorMessagePassword(message);
    }

    if (enteredConfirm && !passwordsMatch) {
      message = messageDontMatch;
      setErrorMessagePasswordConfirm(message);
      setPasswordConfirmValid(false);
    } else if (enteredPassword && enteredConfirm && passwordsMatch) {
      setPasswordConfirmValid(true);
      setErrorMessagePasswordConfirm(message);
    }

    setPassword(firstPassword);
    setPasswordConfirm(secondPassword);
  };

  return (
    // <KeyboardAwareScrollView
    //   extraHeight={200}
    //   contentContainerStyle={[
    //     styles.container,
    //     ...(!isLargeScreen
    //       ? []
    //       : [
    //           {
    //             backgroundColor: Colors.backgroundGrey,
    //           },
    //         ]),
    //   ]}>
    <SingleLineForm
      contentContainerStyle={[
        styles.container,
        ...(!isLargeScreen
          ? []
          : [
              {
                backgroundColor: Colors.backgroundGrey,
              },
            ]),
      ]}>
      {isLargeScreen && <BackgroundTrees />}
      {/* <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}> */}
      {/* <View style={[styles.imageContainer, { marginBottom: 40 }]}>
              <Image
                style={[styles.logo]}
                source={require('./../../assets/images/icon.png')}
              />
            </View> */}
      {/* <View
        style={{
          flex: 1,
        }}> */}
      <View style={styles.welcomeTextContainer}>
        {!isLargeScreen && (
          <Image
            source={require('../../assets/images/tree.png')}
            style={{ width: 256, height: 213 }}
          />
        )}
        <WelcomeText>Welcome to Baobab!</WelcomeText>
        {genericErrorMsg ? (
          <Text style={[styles.formErrorText, styles.genericErrorMessage]}>{genericErrorMsg}</Text>
        ) : null}
      </View>
      <View
        style={[
          styles.signupContainer,
          {
            ...(isLargeScreen && {
              backgroundColor: Colors.white,
              padding: 50,
            }),
          },
        ]}>
        <BrandText style={styles.title}>Get Started</BrandText>

        <FormInput
          label={'Username'}
          value={penname}
          style={[styles.textInput, errorMessageUsername && { borderColor: Colors.orange }]}
          placeholderColor={Colors.lightGrey}
          onChange={validatePenname}
          onEndEditing={validatePenname}
          onSubmitEditing={() => {
            EmailInputRef.focus();
          }}
          returnKeyType="next"
          errorMessage={errorMessageUsername}
        />

        <FormInput
          label={'Email'}
          value={emailAddress}
          style={[styles.textInput, errorMessageEmailAddress && { borderColor: Colors.orange }]}
          keyboardType="email-address"
          placeholderColor={Colors.lightGrey}
          onChange={validateEmailAddress}
          onEndEditing={validateEmailAddress}
          onSubmitEditing={() => {
            PasswordInputRef.focus();
          }}
          reference={input => {
            EmailInputRef = input;
          }}
          returnKeyType="next"
          errorMessage={errorMessageEmailAddress}
        />

        <FormInput
          label={'Password'}
          value={password}
          style={[styles.textInput, errorMessagePassword && { borderColor: Colors.orange }]}
          placeholderColor={Colors.lightGrey}
          secureTextEntry
          onChange={validatePassword}
          onEndEditing={validatePassword}
          onSubmitEditing={() => {
            PasswordRetypeInputRef.focus();
          }}
          reference={input => {
            PasswordInputRef = input;
          }}
          returnKeyType="next"
          errorMessage={errorMessagePassword}
        />

        <FormInput
          label={'Re-type password'}
          value={passwordConfirm}
          style={[styles.textInput, errorMessagePasswordConfirm && { borderColor: Colors.orange }]}
          placeholderColor={Colors.lightGrey}
          secureTextEntry
          onChange={validatePasswordConfirm}
          onEndEditing={validatePasswordConfirm}
          reference={input => {
            PasswordRetypeInputRef = input;
          }}
          errorMessage={errorMessagePasswordConfirm}
        />
        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <RoundButton
            onPress={register}
            important
            disabled={!isFormValid() || loading}
            title="Create Account"
          />
        </View>
        <View
          style={{
            marginTop: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <BrandText style={styles.dontHaveAnAccountText}>Are you already a user?</BrandText>
          <FlatButton
            onPress={() => {
              props.navigation.navigate('login');
            }}
            title=" Sign In"
            active
          />
        </View>
      </View>
    </SingleLineForm>
    // {/* </View> */}
    // {/* </KeyboardAvoidingView> */}
    // </KeyboardAwareScrollView>
  );
};

export default RegistrationScreen;
