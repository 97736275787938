// import { Container, Content, Item, Input } from 'native-base';
import React, { useState, useRef } from 'react';
// import { graphql } from 'react-apollo';
// import { Image, TextInput, View, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Image, View, Platform } from 'react-native';

import cookie from 'cookie';
import { useMutation, useApolloClient } from '@apollo/client';
import Colors from '../../constants/Colors';
import formValidators from '../../lib/formValidators';
import styles from './style';
import { loginUserMutation } from '../../api/graphql/mutations';
import { currentUserQuery } from '../../api/graphql/queries';
import * as SecureStore from 'expo-secure-store';
import { RoundButton, FlatButton } from '../../components/StyledButton';
import useWindowDimensions from '../../lib/useWindowDimensions';
import { BrandText, WelcomeText } from '../../components/StyledText';
import { googleanalytics } from '../../lib/apolloClient';
import { FormInput } from '../../components/StyledForm';
import SingleLineForm from '../../components/SingleLineForm';
import registerForPushNotifications from '../../lib/registerForPushNotifications';
import { setPushNotificationToken } from '../../lib/pushNotificationTokenHelpers';
import notificationModalContext from '../../lib/notificationModalContext';

const LoginScreen = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUsername, setErrorMessageUsername] = useState('');
  const [errorMessagePassword, seterrorMessagePassword] = useState('');

  const [, setModal] = React.useContext(notificationModalContext);

  const client = useApolloClient();
  const { isLargeScreen } = useWindowDimensions();

  const isFormValid = () => username && password;

  const validateUsername = event => {
    // NOTE: This feels a bit sketchy
    event.dispatchConfig.phasedRegistrationNames.bubbled;
    const newUsername = event.nativeEvent.text;
    const valid = formValidators.validateEntered(newUsername);
    // formValidators.validateEmail(username);
    setUsername(valid ? newUsername.replace(/\s/g, '') : '');
    setErrorMessageUsername(valid ? '' : 'Please enter an email address like name@domain.com.');
  };

  const validatePassword = event => {
    // NOTE: This feels a bit sketchy
    event.dispatchConfig.phasedRegistrationNames.bubbled;
    const newPassword = event.nativeEvent.text;
    const valid =
      formValidators.validateEntered(newPassword) && formValidators.validatePassword(newPassword);

    setPassword(valid ? newPassword : '');
    seterrorMessagePassword(valid ? '' : 'Please enter a password.');
  };

  const [loginUser] = useMutation(loginUserMutation, {
    refetchQueries: [{ query: currentUserQuery }],
    awaitRefetchQueries: true,
    onCompleted: async ({ loginUser }) => {
      const { token } = loginUser;
      console.log('--Seting token', token);

      if (Platform.OS === 'web') {
        document.cookie = cookie.serialize('TOKEN_KEY', token, {
          maxAge: 30 * 24 * 60 * 60, // 30 days
        });
      } else {
        await SecureStore.setItemAsync('TOKEN_KEY', token);
      }

      // Force a reload of all the current queries now that the user is
      // logged in
      client.resetStore().then(() => {
        props.navigation.navigate('prompts');
      });
    },
    onError: err => {
      const errorMessage = err?.graphQLErrors?.[0]?.message;

      if (errorMessage === 'INVALID_PASSWORD' || errorMessage === 'USER_NOT_FOUND') {
        console.log('setting Error', errorMessage);
        setErrorMessage('Invalid username or password');
      }
    },
  });

  const login = async () => {
    loginUser({
      notifyOnNetworkStatusChange: true,
      variables: {
        details: {
          username,
          password,
        },
      },
    }).then(result => {
      const pusIsNotSetYet = result?.data?.loginUser?.user?.pushEnabled === null;

      if (result && pusIsNotSetYet && Platform.OS !== 'web') {
        console.log('here?2');
        setTimeout(() => {
          setModal({
            title: 'Push notifications',
            message: 'Would you like to receive push notifications from us?',
            buttons: {
              ok: {
                title: 'Yes',
                action: registerForPushNotifications,
              },
              cancel: {
                title: 'No',
                action: () => setPushNotificationToken('', 'SAIDNO'),
              },
            },
          });
        }, 180000); // note: 180000 (3mins in ms), lower it to 4000 etc while testing
      }
    });
    googleanalytics.addParameter('uid', username);
  };

  const onSingUpPress = () => {
    props.navigation.navigate('registration');
  };

  let PasswordInputRef = useRef(null);
  return (
    // <KeyboardAwareScrollView
    //   extraHeight={200}
    //   contentContainerStyle={[
    //     styles.container,
    //     ...(!isLargeScreen
    //       ? []
    //       : [
    //           {
    //             backgroundColor: Colors.backgroundGrey,
    //           },
    //         ]),
    //   ]}>
    <SingleLineForm
      contentContainerStyle={[
        styles.container,
        ...(!isLargeScreen
          ? []
          : [
              {
                backgroundColor: Colors.backgroundGrey,
              },
            ]),
      ]}>
      {isLargeScreen && <BackgroundTrees />}

      {/* <View
        style={{
          flex: 1,
        }}> */}
      <View style={styles.welcomeTextContainer}>
        {!isLargeScreen && (
          <Image
            source={require('../../assets/images/tree.png')}
            style={{ width: 256, height: 213 }}
          />
        )}
        <WelcomeText>Welcome to Baobab!</WelcomeText>
      </View>
      <View
        style={[
          styles.signupContainer,
          {
            ...(isLargeScreen && {
              backgroundColor: Colors.white,
              padding: 50,
            }),
          },
        ]}>
        <BrandText style={styles.title}>Sign In</BrandText>

        <FormInput
          label={'Username'}
          onChange={validateUsername}
          onSubmitEditing={() => {
            console.log(PasswordInputRef);
            PasswordInputRef.focus();
          }}
          returnKeyType="next"
          value={username}
        />

        <FormInput
          label={'Password'}
          errorMessage={errorMessage}
          secureTextEntry
          onChange={validatePassword}
          reference={input => {
            PasswordInputRef = input;
          }}
          // ref={PasswordInputRef}
        />

        {/* <View style={[styles.fieldErrorMessage]}>
            <BrandText style={[styles.fieldErrorText]}>{errorMessagePassword || ' '}</BrandText>
          </View> */}
        {/* {errorMessage && ( */}

        {/* )} */}

        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <RoundButton onPress={login} important disabled={!isFormValid()} title="Sign In" />
        </View>
        <View
          style={{
            marginTop: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <BrandText style={styles.dontHaveAnAccountText}>Don't have an account?</BrandText>
          <FlatButton onPress={onSingUpPress} title=" Sign Up" active />
        </View>
      </View>
      {/* </View> */}
    </SingleLineForm>
  );
};

export default LoginScreen;

export const BackgroundTrees = () => {
  return (
    <>
      <Image
        source={require('../../assets/images/shortTree.png')}
        style={{
          width: 248,
          height: 268,
          position: 'absolute',
          left: '15%',
          bottom: 0,
          zIndex: -998,
        }}
      />
      <Image
        source={require('../../assets/images/longTree.png')}
        style={{
          width: 435,
          height: 534,
          position: 'absolute',
          left: '2%',
          bottom: 0,
          zIndex: -999,
        }}
      />
    </>
  );
};
