import React from 'react';
import { TextInput, View, StyleSheet, Text } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import moment from 'moment';

import { FlatButton } from '../StyledButton';
import { FormError, FormSuccess } from '../StyledForm';
import { daysFromNowOn as daysFromNowOnHelper } from '../../lib/utils';

const validateHowManyDays = daysFromNowOn => {
  // if its an empty string return true, we will count that as 0
  if (!daysFromNowOn) {
    return true;
  }

  return (
    !Number.isNaN(Number(daysFromNowOn)) &&
    Number(daysFromNowOn) >= 0 &&
    Number.isInteger(Number(daysFromNowOn))
  );
};

const DayPicker = ({ currentValue, handleChange }) => {
  const [howManyDays, setHowManyDays] = React.useState(String(daysFromNowOnHelper(currentValue)));
  const [errorMsg, setErrorMsg] = React.useState('');

  const handleValidity = isValid => {
    if (!isValid) {
      setErrorMsg('Please enter a positive integer (e.g. 5)');
    } else {
      setErrorMsg('');
    }
  };

  return (
    <View>
      <View style={styles.inputView}>
        <Text style={styles.inputLabel}>Post in X days</Text>
        <TextInput
          style={styles.textInput}
          onChangeText={text => {
            if (Number(text) > 730) {
              text = '730';
            } else if (Number(text) < -730) {
              text = '-730';
            }
            const isValid = validateHowManyDays(text);

            setHowManyDays(text);

            if (!text) {
              handleChange(new Date());
            } else {
              if (Number.isInteger(+text)) {
                const scheduledDay = moment()
                  .add(text, 'days')
                  .toDate();

                handleChange(scheduledDay);
              } else {
                handleChange(new Date());
              }
            }

            handleValidity(isValid);
          }}
          onBlur={() => {
            const isValid = validateHowManyDays(howManyDays);
            handleValidity(isValid);
          }}
          value={howManyDays}
          keyboardType="numeric"
        />
        <FlatButton
          titleForAnalytics="Day picker cross/cancel icon"
          style={styles.flatButton}
          onPress={() => {
            setHowManyDays('0');
            setErrorMsg('');
            handleChange(false);
          }}
          iconComponent={<Entypo name="cross" size={20} />}
        />
      </View>
      {errorMsg ? (
        <FormError
          errorMessage={errorMsg}
          style={styles.inputMsg}
          textStyle={styles.inputMsgTextStyle}
        />
      ) : null}
      {!errorMsg && howManyDays ? (
        <FormSuccess
          successMessage={moment()
            .add(howManyDays, 'days')
            .format('dddd, MMMM Do YYYY')}
          style={styles.inputMsg}
          textStyle={styles.inputMsgTextStyle}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  inputView: { position: 'relative', width: 170, flexDirection: 'row', alignItems: 'center' },
  textInput: {
    height: 40,
    width: 70,
    borderColor: 'gray',
    borderWidth: 1,
    paddingVertical: 10,
    paddingLeft: 10,
    paddingRight: 20,
  },
  inputLabel: {
    width: 100,
  },
  flatButton: { position: 'absolute', right: 0, top: 0, bottom: 0, padding: 5 },
  inputMsg: { alignSelf: 'flex-start', paddingTop: 6, width: 220 },
  inputMsgTextStyle: { textAlign: 'left' },
});

export default DayPicker;
