import { ScrollView, SafeAreaView, StyleSheet } from 'react-native';
import React from 'react';
import { getActiveRouteState } from '../navigationUtilities';
import { useQuery } from '@apollo/client';

import CustomDrawerItem from './CustomDrawerItem';
import routes from './drawer-routes';
import SignOutButton from '../../components/SignOutButton';
import { currentUserQuery } from '../../api/graphql/queries';

const CustomDrawerContentComponent = props => {
  const { routeName } = getActiveRouteState(props.navigation.state);
  const { data: currentUserData } = useQuery(currentUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const currentUser = currentUserData?.currentUser;

  return (
    <ScrollView style={styles.scrollView}>
      <SafeAreaView
        style={styles.container}
        forceInset={{ top: 'always', horizontal: 'never' }}
        testID={'Nav.Drawer.Content'}>
        {routes.map(route => (
          <CustomDrawerItem
            key={route.title}
            to={route.to}
            title={route.title}
            iconName={route.iconName}
            iconSet={route.iconSet}
            active={routeName === route.to}
            navigation={props.navigation}
            loginRequired={route.loginRequired}
            isMobile={route.isMobile}
            currentUser={currentUser}
          />
        ))}
        {currentUser ? <SignOutButton navigation={props.navigation} isLink /> : null}
      </SafeAreaView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    paddingVertical: 50,
    paddingHorizontal: 20,
  },
});

export default CustomDrawerContentComponent;
