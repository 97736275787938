import { Platform, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

export default StyleSheet.create({
  container: {
    ...(Platform.OS === 'web' && { flex: 1 }),
    backgroundColor: Colors.white,
    alignItems: 'center',
  },
  errorView: {
    paddingTop: 20,
    paddingBottom: 70,
  },
  imageStyle: { width: 256, height: 213 },
  buttonStyle: { width: 240 },
});
