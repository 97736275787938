import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: { paddingBottom: 30, width: '100%', paddingHorizontal: 25, flex: 1 },
  modalHeader: {
    paddingVertical: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalTitle: {
    paddingBottom: 16,
  },
  section: { flex: 1, marginVertical: 10 },
  buttonContainer: { flex: 1, flexDirection: 'row', justifyContent: 'center' },
  buttonStyle: { height: 45, width: 140, marginBottom: 20, marginTop: 30 },
  titleStyle: { marginBottom: 8 },
});
