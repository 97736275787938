import React, { useState } from 'react';
import { StyleSheet, View, FlatList, Image, Share } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';

import Colors from '../../constants/Colors';
import {
  getUserByUsernameQuery,
  getChallengesQuery,
  getOtherUserResponsesQuery,
} from '../../api/graphql/queries';
import PromptCard from '../../components/PromptCard';
import {
  ProfileLinkText,
  ProfileBioText,
  ProfileUsernameText,
  BrandText,
} from '../../components/StyledText';
import { FlatButton, BackButton } from '../../components/StyledButton';
import useWindowDimensions from '../../lib/useWindowDimensions';
import { followOtherUserMutation, unfollowOtherUserMutation } from '../../api/graphql/mutations';
import ShareIcon from '../../components/ShareIcon';

// import { BottomNavigation } from 'react-native-material-ui';

// const Card = ({ id, title, tag, content, username, responsesCount, anonymous }) => (
//   <View accessibilityRole="link" href={`/prompts/${id}`} as={`/prompts/${id}`}>
//     <View>
//       <Text>{title}</Text>
//       <Text>{tag}</Text>
//     </View>
//     <Text>{content}</Text>
//     <Text>{username}</Text>
//     <Text>{responsesCount}</Text>
//     <Text>{anonymous}</Text>
//   </View>
// );
const TreeImage = () => (
  <Image
    style={{ width: 122, height: 102, marginVertical: 50 }}
    source={require('../../assets/images/tree.png')}
  />
);

const challengeMapper = challenge => ({
  ...challenge,
  content: challenge?.steps?.[0]?.content || '',
  username: challenge?.user?.username,
  response: challenge?.myResponse?.content,
});

const OtherUserProfile = ({ navigation }) => {
  const username = navigation.state.params.username;

  const { data: userData = {}, refetch: refetchOtherUser } = useQuery(getUserByUsernameQuery, {
    variables: { username },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const { getOneUserByUsername: user } = userData;

  const { data: userChallenges } = useQuery(getChallengesQuery, {
    variables: { limit: 200, offset: 0, sort: 'hotRank', otherUserId: user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  // responses query
  const { data: myPublishedChallengesData } = useQuery(getOtherUserResponsesQuery, {
    variables: {
      limit: 30,
      offset: 0,
      sort: 'hotRank',
      username,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const mappedmMyPublishedChallengesData = myPublishedChallengesData?.getOtherUserResponses?.map(
    challengeMapper
  );

  const mappedmMyChallengedData = userChallenges?.challenges?.map(challengeMapper);

  const [runFollowUserMutation] = useMutation(followOtherUserMutation, {
    refetchQueries: [
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, isFollowing: true, sort: 'createdAt' },
      },
    ],
  });
  const [runUnfollowUserMutation] = useMutation(unfollowOtherUserMutation, {
    refetchQueries: [
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, isFollowing: true, sort: 'createdAt' },
      },
    ],
  });

  const showFollowIcon = true; //currentUserData?.id;
  const onFollowPress = !showFollowIcon
    ? undefined
    : async () => {
        const variables = { otherUserId: user?.id };
        // const pinnedByMe = true;
        if (!userData?.getOneUserByUsername?.followedByMe) {
          await runFollowUserMutation({ variables });
          refetchOtherUser();
        } else {
          await runUnfollowUserMutation({ variables });
          refetchOtherUser();
        }
      };

  // const { data: myDraftChallengesData } = useQuery(myDraftChallengesQuery, {
  //   variables: { limit: 200, offset: 0, sort: 'hotRank' },
  //   notifyOnNetworkStatusChange: true,
  // });
  // const mappedMyDraftChallengesData = [];
  // myDraftChallengesData?.myDraftChallenges?.map(
  //   challengeMapper
  // );

  // const { data: myChallengedData } = useQuery(getMyChallengesQuery, {
  //   variables: { limit: 200, offset: 0, sort: 'hotRank' },
  //   notifyOnNetworkStatusChange: true,
  // });
  // const mappedmMyChallengedData = []; // myChallengedData?.myChallenges?.map(challengeMapper);

  const [selectedTab, updateSelectedTab] = useState('prompts');

  let data = getDataForProfileTab({
    selectedTab,
    mappedmMyChallengedData,
    mappedmMyPublishedChallengesData,
    // mappedMyDraftChallengesData,
  });

  return (
    <View style={styles.container}>
      <FlatList
        ListHeaderComponent={
          <HeaderListComponent
            username={user?.username}
            bio={user?.bio}
            website={user?.website}
            followedByMe={user?.followedByMe}
            selectedTab={selectedTab}
            updateSelectedTab={updateSelectedTab}
            mappedmMyChallengedData={mappedmMyChallengedData}
            mappedmMyPublishedChallengesData={mappedmMyPublishedChallengesData}
            onFollowPress={onFollowPress}
            navigation={navigation}
          />
        }
        data={data}
        renderItem={({ item }) => (
          <PromptCard
            {...item}
            cardType="prompt"
            testID={`OtherUserPrompt.${selectedTab}.${item.id}`}
            onPress={() => {
              navigation.navigate('responses', { id: item.id });
            }}
          />
        )}
        keyExtractor={({ id }) => id}
        ListEmptyComponent={ListEmptyComponent}
        contentContainerStyle={{
          alignContent: 'center',
        }}
        // style={{ width: '100%', flex: 1 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  shareIcon: {
    marginHorizontal: 15,
  },
  container: {
    // TODO: centrilize
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'center',
    // alignContent: 'center',

    backgroundColor: Colors.backgroundGrey,
  },
  link: {
    color: 'blue',
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 16,
  },
  text: {
    alignItems: 'center',
    fontSize: 24,
    marginBottom: 24,
  },
});

export default OtherUserProfile;

const HeaderListComponent = ({
  signOut,
  username,
  bio,
  website,
  selectedTab,
  updateSelectedTab,
  mappedmMyChallengedData,
  mappedmMyPublishedChallengesData,
  mappedMyDraftChallengesData,
  followedByMe,
  onFollowPress,
  navigation,
}) => {
  const { isLargeScreen } = useWindowDimensions();
  return (
    <View style={{ alignSelf: 'center', maxWidth: 800, width: '100%', paddingTop: 40 }}>
      {/* <PageHeaderText style={{ alignSelf: 'flex-start' }}>{username}'s' PROFILE</PageHeaderText> */}
      <View
        style={{
          backgroundColor: Colors.white,
          // maxWidth: 1000,
          width: '100%',
          padding: 20,
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
          position: 'relative',
        }}>
        <View
          style={{
            top: 0,
            left: 10,
            right: 10,
            position: 'absolute',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          // onPress={() => setModalVisible(true)}
        >
          <View style={{ height: 75 }}>
            <BackButton navigation={navigation} isLargeScreen={isLargeScreen} />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <ShareIcon
              style={styles.shareIcon}
              partialUrl={`user/u/${username}`}
              title="Share user"
            />
            {followedByMe !== null ? (
              <FlatButton
                title={followedByMe ? 'Unfollow' : 'Follow'}
                style={{
                  borderWidth: 1,
                  alignSelf: 'center',
                  paddingHorizontal: 30,
                  borderColor: Colors.blueGrey,
                  borderRadius: 50,
                  backgroundColor: followedByMe ? Colors.blueGrey : Colors.white,
                }}
                textStyle={{
                  color: followedByMe ? Colors.white : Colors.black,
                }}
                onPress={onFollowPress}
              />
            ) : null}
          </View>
        </View>
        <TreeImage />
        <ProfileUsernameText>@{username}</ProfileUsernameText>
        <ProfileBioText style={{ marginTop: 20 }}>{bio}</ProfileBioText>
        <ProfileLinkText style={{ marginTop: 20 }}>{website}</ProfileLinkText>
      </View>
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.white,
          // maxWidth: 1000,
          width: '100%',
          marginTop: 20,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <FlatButton
          onPress={() => {
            updateSelectedTab('prompts');
          }}
          title={`Prompts (${mappedmMyChallengedData?.length || 0})`}
          titleForAnalytics="Prompts (X) - profile"
          active={selectedTab === 'prompts'}
          testID={'OtherUserProfile.Pinned'}
        />
        <FlatButton
          onPress={() => {
            updateSelectedTab('responses');
          }}
          title={`Responses (${mappedmMyPublishedChallengesData?.[0]?.userResponseCount || 0})`}
          titleForAnalytics="Responses (X) - profile"
          active={selectedTab === 'responses'}
          testID={'OtherUserProfile.Responses'}
        />
        {mappedMyDraftChallengesData && (
          <FlatButton
            onPress={() => {
              updateSelectedTab('drafts');
            }}
            title={`Drafts (${mappedMyDraftChallengesData?.length || 0})`}
            titleForAnalytics="Drafts (X) - profile"
            active={selectedTab === 'drafts'}
            testID={'OtherUserProfile.Drafts'}
          />
        )}
      </View>
    </View>
  );
};

const ListEmptyComponent = () => (
  <View
    style={{
      alignSelf: 'center',
      maxWidth: 800,
      width: '100%',
    }}>
    <BrandText style={{ textAlign: 'center' }}>
      {'\n'}No posts to display{'\n'}
    </BrandText>
  </View>
);

const getDataForProfileTab = ({
  selectedTab,
  mappedmMyChallengedData,
  mappedmMyPublishedChallengesData,
  mappedMyDraftChallengesData,
}) => {
  let data = mappedmMyChallengedData;
  if (selectedTab === 'responses') {
    data = mappedmMyPublishedChallengesData;
  } else if (selectedTab === 'drafts') {
    data = mappedMyDraftChallengesData;
  }
  return data;
};
