import React from 'react';
import { Image, View } from 'react-native';

import styles from './style';
import { RoundButton } from '../../components/StyledButton';
import { WelcomeText } from '../../components/StyledText';

const NotFound = ({ errorMessage, navigation }) => {
  return (
    <View style={styles.container}>
      <View>
        <Image source={require('../../assets/images/tree.png')} style={styles.imageStyle} />
      </View>

      <View style={styles.errorView}>
        <WelcomeText style={styles.title}>{errorMessage}</WelcomeText>
      </View>

      <RoundButton
        style={styles.buttonStyle}
        title="Go to the Main Screen"
        onPress={() => navigation.navigate('prompts')}
      />
    </View>
  );
};

export default NotFound;
