const tintColor = '#44B973';

const Colors = {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',

  red: 'rgb(234,72,72)',

  black: 'rgb(18, 18, 18)',
  white: '#FFFFFF',
  blue: '#66CED6',
  blueGrey: '#7C90A0',
  linkBlue: '#007aff',
  green: '#44B973',
  brightGreen: 'rgba(68, 185, 115, 0.2)',
  grey: '#EAEAF0',
  textGrey: '#909090',
  textDarkGrey: '#666666',
  darkGrey: '#263238',
  mattGrey: '#C9D1D5',
  mediumGrey: '#90A4AE',
  lightGrey: '#ECEFF1',
  lighterGrey: '#EEEEEE',
  lightYellow: '#FFB21B',
  gold: '#FFD700',
  defaultBackground: '#FFFFFF',
  deactiveFlag: '#007aff',
  actionSheet: '#44B973',
  pageTitleColor: 'grey',
  arrowBack: 'red',

  textInputBorder: 'rgba(48, 54, 80, 0.20)',
  backgroundGrey: '#f7f9fd',
  orange: '#fe5c19',
  lighterOranger: 'rgba(254, 166, 128, 1)',
  brightOrange: 'rgba(254, 35, 25, 0.20)',
  brightBlueGrey: 'rgba(124,144,160, 0.1)',
  promptText: '#303650',
  link: '#424fd9',
};

export default Colors;
