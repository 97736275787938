import React from 'react';
import { StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';
import { FlatButton } from './StyledButton';
import { TitleText } from './StyledText';
import { capitalize } from 'lodash';

export default ({
  color = Colors.brightOrange,
  segmentsArray = [],
  title = 'Settings',
  selectedSegmentIndex,
  setSelectedSegmentIndex,
}) => {
  const styles = StyleGenerator({ color });

  return (
    <View style={styles.container}>
      <TitleText style={{ paddingHorizontal: 0 }}>{capitalize(title)}</TitleText>
      <View style={styles.segmentContainer}>
        {segmentsArray.map((segmentText, i) => {
          // comibne styles for segment
          let segmentStyles = [styles.segmentDefault];
          if (i === selectedSegmentIndex) {
            segmentStyles.push(styles.segmentSelected);
          }
          if (i === 0) {
            // if its first segment, add left border radius
            segmentStyles.push(styles.segmentFirst);
          }
          if (i === segmentsArray.length - 1) {
            // if its last segment, add right border radius
            segmentStyles.push(styles.segmentLast);
          }

          return (
            <FlatButton
              key={segmentText}
              style={segmentStyles}
              title={capitalize(segmentText)}
              textStyle={{ fontSize: 13 }}
              onPress={() => {
                setSelectedSegmentIndex(i);
              }}
            />
          );
        })}
      </View>
    </View>
  );
};

const StyleGenerator = ({ color }) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      paddingVertical: 5,
    },
    segmentContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 15,
    },
    segmentDefault: {
      paddingHorizontal: 20,
      paddingVertical: 0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderColor: color,
    },
    segmentSelected: {
      backgroundColor: color,
    },
    segmentFirst: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    segmentLast: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderRightWidth: 1,
    },
  });
};
