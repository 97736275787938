import React, { useState } from 'react';
import { View, Switch, StyleSheet, Platform, TextInput } from 'react-native';
import { PopUp } from './StyledPopup';
import { BackButton, FlatButton, RoundButton } from './StyledButton';
import { PageHeaderText, TitleText, FormLabel } from './StyledText';
import { SimpleLineIcons } from '@expo/vector-icons';

import StyledSegment from './StyledSegment';
import Colors from '../constants/Colors';
import useWindowDimensions from '../lib/useWindowDimensions';
import DatePicker from './DatePicker/';
import { isSameDay, dateToYYYYMMDD } from '../lib/utils';

const SettingsModal = ({
  setModalVisible,
  modalVisible,
  navigation,
  modalTitle = 'Settings',
  privacyOptions = {},
  tags = [],
  tag,
  setTag,
  privacy,
  setPrivacy,
  promotion,
  setPromotion,
  onPublish,
  myResponse,
  schedule,
  setSchedule,
  setModalMyResponse,
}) => {
  const { isLargeScreen } = useWindowDimensions();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(
    Object.values(privacyOptions).indexOf(privacy)
  );

  const [localPromotion, setLocalPromotion] = useState(promotion);
  const [isTagButtonClicked, setIsTagButtonClicked] = useState(false);
  const [isTagSet, setIsTagSet] = useState(false);
  const [tagInput, setTagInput] = useState('');
  const [localTags, setLocalTags] = useState(tags);
  const [localTag, setLocalTag] = useState(tag);
  const keys = Object.keys(privacyOptions);

  const handleBackPress = () => {
    const key = keys[selectedSegmentIndex];
    updateParent(key);
    setModalVisible(false);
  };

  const handlePublish = () => {
    const key = keys[selectedSegmentIndex];

    onPublish({
      content: myResponse,
      modalSelections: {
        privacy: privacyOptions[key],
        tag: localTag,
        promotion: localPromotion,
      },
    });

    setModalVisible(false);
    updateParent(key);

    if (typeof setModalMyResponse === 'function') {
      setModalMyResponse('');
    }
  };

  const updateParent = key => {
    if (typeof setPrivacy === 'function') {
      setPrivacy(privacyOptions[key]);
    }
    if (typeof setPromotion === 'function') {
      setPromotion(localPromotion);
    }
    if (typeof setTag === 'function') {
      setTag(localTag);
    }
  };

  let datePickerButtonText = 'Pick a date';

  const isDateSetAndNotToday = schedule && !isSameDay(schedule, new Date());

  if (setSchedule && Platform.OS !== 'web' && isDateSetAndNotToday) {
    datePickerButtonText = dateToYYYYMMDD(schedule);
  }

  return (
    <PopUp setModalVisible={setModalVisible} modalVisible={modalVisible} navigation={navigation}>
      <View style={[styles.container]}>
        <View style={styles.modalHeader}>
          <BackButton
            navigation={navigation}
            isLargeScreen={isLargeScreen}
            style={styles.backButtonStyle}
            handlePress={handleBackPress}
          />
        </View>
        <View style={styles.modalTitle}>
          <PageHeaderText>{modalTitle.toUpperCase()}</PageHeaderText>
        </View>
        {/* Privacy Section */}
        {Object.values(privacyOptions).length > 0 ? (
          <View style={styles.section}>
            <StyledSegment
              color={Colors.brightGreen}
              segmentsArray={Object.values(privacyOptions)}
              title="Privacy"
              selectedSegmentIndex={selectedSegmentIndex}
              setSelectedSegmentIndex={setSelectedSegmentIndex}
            />
          </View>
        ) : null}

        {/* Promotion Section */}
        {privacy ? (
          <View style={styles.section}>
            <TitleText>Promotion</TitleText>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}>
              <View style={{ width: '80%' }}>
                <FormLabel style={{ flexWrap: 'wrap' }}>
                  Promote On Babobab's Social Media
                </FormLabel>
              </View>
              <Switch
                disabled={privacyOptions[keys[selectedSegmentIndex]] === privacyOptions.PRIVATE}
                onValueChange={value => {
                  setLocalPromotion(value);
                }}
                value={
                  localPromotion &&
                  privacyOptions[keys[selectedSegmentIndex]] !== privacyOptions.PRIVATE
                }
                thumbColor={Colors.orange}
                activeThumbColor={Colors.orange}
                // trackColor={Colors.brightOrange}
                activeTrackColor={Platform.OS === 'ios' ? Colors.orange : Colors.brightOrange}
                trackColor={
                  Platform.OS === 'ios'
                    ? {
                        false: Colors.brightBlueGrey,
                        true: Colors.brightOrange,
                      }
                    : { false: Colors.brightOrange, true: Colors.lighterOranger }
                }
                // ios_backgroundColor={Colors.brightOrange}
              />
            </View>
          </View>
        ) : null}

        {/* Schedule Section */}
        {setSchedule ? (
          <View style={styles.section}>
            <TitleText>Schedule</TitleText>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}>
              <View style={{ width: '70%', paddingRight: 10, paddingVertical: 10 }}>
                <FormLabel style={{ flexWrap: 'wrap', marginBottom: 20 }}>
                  Instead of publishing it now, schedule it for later :)
                </FormLabel>
                <View>
                  {!schedule ? (
                    <FlatButton
                      style={{
                        width: 180,
                        height: 40,
                        backgroundColor: Colors.linkBlue,
                      }}
                      textStyle={{ color: Colors.grey, fontWeight: 'bold' }}
                      onPress={() => {
                        setSchedule(new Date());
                      }}
                      title="Pick a Date"
                    />
                  ) : (
                    <DatePicker
                      handleChange={value => {
                        setSchedule(value);
                      }}
                      title={datePickerButtonText}
                      currentValue={schedule || new Date()}
                      isDateSetAndNotToday={isDateSetAndNotToday}
                      setSchedule={setSchedule}
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        ) : null}

        {/* Tag Section */}
        {tags.length > 0 ? (
          <View style={styles.section}>
            <TitleText style={{ marginBottom: 5 }}>Tag</TitleText>
            <View style={styles.tagsContainer}>
              {localTags.map((tag, i) => {
                return (
                  <FlatButton
                    key={i}
                    style={{
                      borderRadius: 10,
                      paddingVertical: 3,
                      paddingHorizontal: 8,
                      backgroundColor: localTag === tag ? Colors.blueGrey : Colors.grey,
                      marginRight: 12,
                      marginVertical: 10,
                    }}
                    textStyle={[
                      styles.tagText,
                      { color: localTag === tag ? Colors.grey : Colors.black },
                    ]}
                    onPress={() => {
                      setLocalTag(tag);
                    }}
                    title={`#${tag}`}
                    titleForAnalytics="tag - settings modal"
                  />
                );
              })}

              {!isTagButtonClicked && !isTagSet && (
                <SimpleLineIcons
                  style={{ marginLeft: 15, marginTop: 18 }}
                  name="plus"
                  size={30}
                  color={Colors.green}
                  onPress={() => setIsTagButtonClicked(true)}
                />
              )}
            </View>
            {isTagButtonClicked && !isTagSet && (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>
                <TextInput
                  inlineImageLeft="search_icon"
                  style={{
                    flex: 1,
                    marginRight: 12,
                    borderWidth: 1,
                    borderColor: Colors.textInputBorder,
                    padding: 10,
                    borderRadius: 5,
                    marginVertical: 7,
                    // padding: 10,
                    // textAlign: 'center',
                    fontFamily: 'avenirnext-bold',
                    height: 40,
                  }}
                  placeholder="New tag"
                  onChangeText={text => setTagInput(text)}
                  value={tagInput}
                />
                <SimpleLineIcons
                  style={{ marginHorizontal: 5 }}
                  name="plus"
                  size={30}
                  color={Colors.green}
                  onPress={() => {
                    if (tagInput) {
                      const normalizedTagInput = tagInput.replace(/\s+/g, '-').toLowerCase();
                      setLocalTags(prev => [...prev, normalizedTagInput]);
                      setLocalTag(normalizedTagInput);
                      setTagInput('');
                      setIsTagSet(true);
                    }
                    setIsTagButtonClicked(false);
                  }}
                />
                <SimpleLineIcons
                  style={{ marginLeft: 10 }}
                  name="close"
                  size={30}
                  color={Colors.red}
                  onPress={() => setIsTagButtonClicked(false)}
                />
              </View>
            )}
          </View>
        ) : null}
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
          <RoundButton
            style={{ height: 45, width: 140, marginBottom: 10, marginTop: 20 }}
            important
            title="Publish"
            onPress={handlePublish}
          />
        </View>
      </View>
    </PopUp>
  );
};

const styles = StyleSheet.create({
  container: { paddingBottom: 30, width: '100%', paddingHorizontal: 15 },
  modalHeader: {
    paddingVertical: 12,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalTitle: {},
  section: { flex: 1, marginVertical: 10 },
  promotion: {
    paddingVertical: 10,
    marginVertical: 10,
  },
  privacy: {
    marginVertical: 10,
  },
  tagsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
  },
  tagStyle: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: Colors.grey,
    marginRight: 10,
    marginVertical: 10,
  },
  tagText: {
    fontSize: 11,
  },
  activetagStyle: {
    backgroundColor: Colors.linkBlue,
    color: Colors.grey,
  },
});

export default SettingsModal;
