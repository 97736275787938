import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, Platform, SafeAreaView, StatusBar } from 'react-native';
import { useQuery } from '@apollo/client';

import {
  currentUserQuery,
  notificationsCountQuery,
  allNotificationsQuery,
} from '../api/graphql/queries';
import useWindowDimensions from '../lib/useWindowDimensions';
import Colors from '../constants/Colors';
import { RoundButton, FlatButton } from '../components/StyledButton';
import { analyticsActionForButtons } from '../lib/analyticsButtonClickHelper';
import { BrandText } from '../components/StyledText';
import { getActiveRouteState } from './navigationUtilities';

const Navbar = ({ navigation }) => {
  // const { route } = router;
  // let route = navigation?.state?.routeName;
  let { routeName } = getActiveRouteState(navigation.state);

  // let isLargeScreen = false;
  const { isLargeScreen } = useWindowDimensions();

  const { data: currentUserData } = useQuery(currentUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const currentUser = currentUserData?.currentUser;
  const currentUserId = currentUser?.id;

  const { data: notificationsCountData } = useQuery(notificationsCountQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 4000,
  });

  useEffect(() => {}, [currentUserId]);

  if (
    routeName.includes('create') ||
    routeName.includes('respond') ||
    routeName.includes('comment')
  ) {
    return Platform.OS === 'android' ? (
      <View style={{ height: StatusBar.currentHeight ? StatusBar.currentHeight : 0 }} />
    ) : (
      <></>
    );
  }
  return (
    <SafeAreaView
      style={{
        width: '100%',
        minHeight: Platform.select({ ios: 70, android: 130, web: 100 }),
        // position: 'fixed',
        zIndex: 999,
        backgroundColor: 'white',
        elevation: 3,
        paddingTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomWidth: 0.5,
        borderColor: 'black',
        // flex: 1,
        flexDirection: 'row',
        paddingHorizontal: isLargeScreen ? 50 : 10,
      }}>
      {/* {Platform.OS === 'android' ? <StatusBar /> : null} */}
      {Platform.OS === 'android' ? (
        <View style={{ height: StatusBar.currentHeight ? StatusBar.currentHeight : 0 }} />
      ) : null}
      {/** For pixel 3 xl and other android phones with notch */}
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          {isLargeScreen && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('prompts');
                analyticsActionForButtons('navbar prompts button');
              }}>
              <View
                style={{
                  width: 70,
                  height: 70,
                  borderRadius: 70 / 2,
                  backgroundColor: Colors.green,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    fontFamily: 'comfortaa-bold',
                    color: Colors.white,
                  }}>
                  b a o
                </Text>
                <Text style={{ fontFamily: 'comfortaa-bold', color: Colors.white }}>b a b</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 3,
                  }}>
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 50 / 2,
                      backgroundColor: Colors.white,
                      marginRight: 3,
                    }}
                  />
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 50 / 2,
                      backgroundColor: Colors.white,
                      marginRight: 3,
                    }}
                  />
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 50 / 2,
                      backgroundColor: Colors.white,
                    }}
                  />
                </View>
              </View>
              {/* <Image
                style={{ width: 41, height: 48 }}
                source={require('../assets/images/icon.png')}
              /> */}
            </TouchableOpacity>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flex: 1,
            }}>
            {/* <View accessibilityRole="link" href="/"> */}

            <FlatButton
              testID={'Prompts.Button'}
              title="Prompts"
              imageSource={require('../assets/images/navigation/promptsActive.png')}
              transparent
              style={{
                marginLeft: isLargeScreen ? 90 : 0,
                paddingHorizontal: isLargeScreen ? 0 : 5,
              }}
              onPress={() => navigation.navigate('prompts', {})}
              hitSlop={{ right: isLargeScreen ? 0 : 30, top: 5, left: 5, bottom: 5 }}
              active={routeName === 'prompts'}
              // style={{ backgroundColor: 'white', color: route === '/' ? 'black' : 'white' }}
              // color={route === '/' ? Colors.grey : Colors.green}
            />
            {/* </View> */}

            {/* <View accessibilityRole="link" href="/"> */}
            {/* <FlatButton
              title="Goals"
              imageSource={require('../assets/images/navigation/goalsInactive.png')}
              transparent
              style={{ marginLeft: 36 }}
              onPress={() => {
                Alert.alert("We're excited to release this feature on January 1st!");
                // navigation.navigate('play')
              }}

              // style={{ backgroundColor: 'white', color: route === '/' ? 'black' : 'white' }}
              // color={route === '/' ? Colors.grey : Colors.green}
            /> */}
            {/* </View> */}

            {isLargeScreen && (
              <RoundButton
                style={{ marginLeft: isLargeScreen ? 36 : 10 }}
                title={isLargeScreen ? 'Download App' : 'Mobile App'}
                important
                onPress={() => navigation.navigate('download')}
                active={routeName === 'download'}
              />
            )}
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          {!currentUser?.id && (
            <>
              <FlatButton
                transparent
                title="Sign in"
                onPress={() => navigation.navigate('login')}
                active={routeName === 'login'}
                style={{ marginRight: 9 }}
              />

              <RoundButton
                style={{ marginLeft: '2%' }}
                important
                title="Sign up"
                onPress={() => navigation.navigate('registration')}
                active={routeName === 'registration'}
              />
            </>
          )}
          {currentUser?.id && (
            <View style={{ position: 'relative' }}>
              <FlatButton
                titleForAnalytics="bell icon - navbar"
                transparent
                imageSource={require('./../assets/images/icons/bell.png')}
                imageStyle={{
                  width: 23,
                  height: 27,
                  marginRight: isLargeScreen ? 10 : 0,
                }}
                onPress={() => navigation.navigate('notifications')}
                active={routeName === 'notifications'}
                style={{ marginRight: 6 }}
              />
              {notificationsCountData?.notificationsCount?.count > 0 && (
                <View
                  style={{
                    position: 'absolute',
                    left: '60%',
                    top: '10%',
                    width: 10,
                    height: 10,
                    borderRadius: 50,
                    backgroundColor: Colors.green,
                  }}></View>
              )}
            </View>
          )}
          {currentUser?.id && (
            <>
              <BrandText
                style={{
                  fontWeight: '500',
                  fontSize: 16,
                }}
                active>
                {isLargeScreen ? `@${currentUser?.username}` : ''}
              </BrandText>
              <FlatButton
                titleForAnalytics="tree icon / drawer button"
                transparent
                testID={'Nav.Drawer.Button'}
                imageSource={require('./../assets/images/tree.png')}
                imageStyle={{
                  width: 61,
                  height: 50,
                  marginLeft: isLargeScreen ? 10 : 0,
                  marginRight: isLargeScreen ? 0 : 12,
                }}
                imageRightSide
                textStyle={{ color: Colors.promptText }}
                onPress={() => navigation.openDrawer()}
              />
            </>
          )}
          {/* <View accessibilityRole="link" href="/profile">
              <Button
                title="Profile"
                color={
                  route === "/profile" ? Colors.grey : Colors.green
                }
              />
            </View> */}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Navbar;
