import * as React from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
// import { StyleSheet, View } from 'react-native';
import { useQuery } from '@apollo/client';
// import { withRouter } from 'next/router';
import { getMyChallengesQuery } from '../../api/graphql/queries';
import PromptCard from '../../components/PromptCard';

const MyChallenges = () => {
  const { data } = useQuery(getMyChallengesQuery, {
    variables: { limit: 200, offset: 0, sort: 'hotRank' },
    notifyOnNetworkStatusChange: true,
  });
  console.log(data);
  const mappedData = data?.myChallenges?.map(challenge => ({
    ...challenge,
    content: challenge?.steps?.[0]?.content || '',
    username: challenge?.user?.username,
  }));

  //   console.log('currentUserData----', currentUserData);
  return (
    <View style={styles.container}>
      <FlatList
        data={mappedData}
        renderItem={({ item }) => <PromptCard {...item} cardType="prompt" />}
        keyExtractor={({ id }) => id}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  link: {
    color: 'blue',
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 16,
  },
  text: {
    alignItems: 'center',
    fontSize: 24,
    marginBottom: 24,
  },
});
export default MyChallenges;
