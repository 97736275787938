import { client } from './apolloClient';
import { Notifications } from 'expo';

import { setPushTokenMutation } from '../api/graphql/mutations';

/**
 * Helper for setting push notification token on server side
 *
 * @param {string} token - token to set
 * @returns {Promise} Apollo graphql mutation
 */
export const setPushNotificationToken = async (
  token,
  usersResponseToEnablePushNotificationsPrompt = 'SAIDYES'
) => {
  return client.mutate({
    variables: {
      pushToken: token,
      usersResponseToEnablePushNotificationsPrompt,
    },
    mutation: setPushTokenMutation,
  });
};

/**
 * Helper for getting push notification token
 *
 * @returns {Promise<string>}
 */
export const getPushNotificaitonToken = async () => {
  let token = await Notifications.getExpoPushTokenAsync().catch(e => console.error(e.message));

  // web returns undefined, so we are defaulting it to prevent apollo string error
  return token || '';
};

export default setPushNotificationToken;
