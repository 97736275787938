// - [ ]  progress
// - [ ]  Play (beta) - Commented out until play is added
// - [ ]  settings

// to needs to be defined in HomeStack stack navigation
const routes = [
  {
    title: 'Profile',
    to: 'profile',
    iconName: 'user',
    iconSet: 'AntDesign',
    loginRequired: true,
  },
  {
    title: 'Settings',
    to: 'settings',
    iconName: 'settings',
    iconSet: 'MaterialIcons',
    loginRequired: true,
    isMobile: true,
  },
  {
    title: 'Help & Support',
    to: 'help',
    iconName: 'support',
    iconSet: 'FontAwesome',
  },
  // {
  //   title: 'Progress',
  //   to: 'progress',
  //   iconName: 'progress-two',
  //   iconSet: 'Entypo',
  // },
  // {
  //   title: 'Play (beta)',
  //   to: 'play',
  //   iconName: 'play',
  //   iconSet: 'AntDesign',
  // },
];

export default routes;
