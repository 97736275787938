import React from 'react';
import { View, FlatList, TouchableOpacity, Image, Text } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { SimpleLineIcons } from '@expo/vector-icons';

import gql from 'graphql-tag';
import _ from 'lodash';
import Colors from '../../constants/Colors';
import styles from './styles';
// import { notificationsCountQuery } from '../ListChallenges/ListChallengesHeader';
import { PageHeaderText } from '../../components/StyledText';
import { allNotificationsQuery, notificationsCountQuery } from '../../api/graphql/queries';
import { analyticsActionForButtons } from '../../lib/analyticsButtonClickHelper';
import LoadingIndicator from '../../components/LoadingIndicator';

import generateNotificationTextAndColorUsernameHelper from './generateNotificationTextAndColorUsernameHelper';

const markAllNotificationsAsReadMutation = gql`
  mutation markAllNotificationsAsRead {
    markAllNotificationsAsRead
  }
`;

const markNotificationAsReadMutation = gql`
  mutation markNotificationAsRead($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId) {
      id
    }
  }
`;

const NotificationsScreen = props => {
  const { loading, data, fetchMore, networkStatus, updateQuery, refetch } = useQuery(
    allNotificationsQuery,
    {
      variables: {
        limit: 20,
        offset: 0,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [markNotificationAsRead] = useMutation(markNotificationAsReadMutation, {
    refetchQueries: [{ query: notificationsCountQuery }],
  });
  const [markAllNotificationsAsRead] = useMutation(markAllNotificationsAsReadMutation, {
    refetchQueries: [{ query: notificationsCountQuery }],
  });
  // type responseOnMyChallenge will be a link to card

  // markAllNotificationsAsRead = () => {
  //   client
  //     .mutate({
  //       mutation: markAllNotificationsAsRead,
  //       refetchQueries: () => [
  //         {
  //           query: allNotificationsQuery,
  //           variables: {
  //             limit: 20,
  //             offset: 0,
  //           },
  //         },
  //         {
  //           query: notificationsCountQuery,
  //         },
  //       ],
  //     })
  //     .then(result => {
  //       console.log('---markedAllAsRead', result);
  //     })
  //     .catch(err => {
  //       console.log('--------------------', err.message, '---------');
  //     });
  // };

  // markNotificationAsRead = ({ notificationId, challengeId }) => {
  //   console.log(notificationId);

  //   client
  //     .mutate({
  //       variables: {
  //         notificationId,
  //       },
  //       mutation: markNotificationAsRead,
  //       refetchQueries: () => [
  //         {
  //           query: allNotificationsQuery,
  //           variables: {
  //             limit: 20,
  //             offset: 0,
  //           },
  //         },
  //         {
  //           query: notificationsCountQuery,
  //         },
  //       ],
  //     })
  //     .then(result => {
  //       const resetAction = StackActions.reset({
  //         index: 1,
  //         actions: [
  //           // add other screen if you want
  //           NavigationActions.navigate({ routeName: 'Root' }),
  //           NavigationActions.navigate({
  //             routeName: 'ListAttempts',
  //             params: { challenge: { id: challengeId } },
  //           }),
  //         ],
  //       });
  //       this.props.navigation.dispatch(resetAction);

  //       console.log('---markedAllAsRead', result);
  //     })
  //     .catch(err => {
  //       console.log('--------------------', err.message, '---------');
  //     });
  // };

  const handleMarkAllNotificationsAsRead = () => {
    markAllNotificationsAsRead();
    updateQuery(prev => {
      let prevData = JSON.parse(JSON.stringify(prev.allNotifications));
      let updatedData = prevData.map(item => {
        item.read = true;
        return item;
      });
      return { allNotifications: updatedData };
    });
  };

  const onListEndReached = (fetchMore, data) => {
    fetchMore({
      variables: {
        offset: data?.allNotifications?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        // Temperory hack, added because refetchQueries is causing dupes
        const allNotificationsRaw = [...prev.allNotifications, ...fetchMoreResult.allNotifications];
        const allNotificationsDupesRemoved = _.uniqBy(allNotificationsRaw, 'id');

        return Object.assign({}, prev, {
          allNotifications: allNotificationsDupesRemoved,
        });
      },
    });
  };

  const renderItem = ({ item }) => {
    const { body, type, read, id, challengeId, followerName, commentUsername } = item;
    const notificationId = id;
    let imageSource = require('../../assets/images/icons/bubble.png');
    if (type === 'responseWinner') {
      imageSource = require('../../assets/images/icons/cup.png');
    } else if (type === 'achievement') {
      imageSource = require('../../assets/images/icons/clap.png');
    }

    const handleMarkNotificationAsRead = (notificationId, challengeId, type) => {
      markNotificationAsRead({
        variables: { notificationId, challengeId, type },
      });
      updateQuery(prev => {
        let prevData = JSON.parse(JSON.stringify(prev.allNotifications));
        let updatedData = prevData.map(item => {
          if (item.id === id) {
            item.read = true;
          }
          return item;
        });
        return { allNotifications: updatedData };
      });
    };

    return (
      <View
        style={{
          marginTop: 5,
          marginBottom: 5,
          paddingHorizontal: 20,
          paddingVertical: 10,
          maxWidth: 800,
          width: '100%',
          alignSelf: 'center',
          backgroundColor: Colors.white,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          key={id}
          style={[styles.attemptCell, { flexDirection: 'row', flex: 1, alignItems: 'center' }]}
          onPress={() => {
            if (type === 'responseOnMyChallenge') {
              props.navigation.navigate('responses', { id: challengeId });
            } else if (type === 'newFollower' || type === 'commentOnMyResponse') {
              const username = followerName || commentUsername;
              props.navigation.navigate('user', { username });
            }
            handleMarkNotificationAsRead(notificationId, challengeId, type);
            analyticsActionForButtons(`notification click - type: ${type}`);
          }}>
          <View style={{}}>
            <Image
              style={{
                width: 30,
                height: 30,
                resizeMode: 'contain',
                marginRight: 10,
              }}
              source={imageSource}
            />
          </View>
          <View style={{ flex: 1, marginRight: 5 }}>
            {generateNotificationTextAndColorUsernameHelper(body, read)}
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const ListHeaderComponent = () => (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'center',
        maxWidth: 800,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20,
      }}>
      <PageHeaderText>Notifications</PageHeaderText>
      <SimpleLineIcons
        style={{ marginVertical: 20, alignSelf: 'flex-end' }}
        name="check"
        size={25}
        color={Colors.green}
        onPress={handleMarkAllNotificationsAsRead}
      />
      {/* <RoundButton
        transparent
        title="Back"
        onPress={() => {
          props.navigation.goBack();
        }}
      /> */}
      {/* <RoundButton title="Mark all as read" onPress={markAllNotificationsAsRead} success block /> */}
    </View>
  );

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colors.backgroundGrey,
      }}>
      {data && data.allNotifications && (
        <FlatList
          refreshing={false}
          onRefresh={refetch}
          ListHeaderComponent={ListHeaderComponent}
          keyExtractor={item => item.id}
          data={data.allNotifications}
          renderItem={renderItem}
          style={styles.notificationsList}
          onEndReached={_.debounce(() => onListEndReached(fetchMore, data), 500)}
        />
      )}

      {/* <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FlatList
            data={[{ key: 'a' }, { key: 'b' }]}
            renderItem={({ item }) => <Text>{item.key}</Text>}
          />
          <Text
            style={{
              fontSize: 24,
              fontFamily: 'avenirnext',
              textAlign: 'center',
              color: 'white'
            }}
          >
            You have no notifications yet...
          </Text>
        </View> */}

      {(loading || networkStatus === 4) && <LoadingIndicator />}
    </View>
  );
};

export default NotificationsScreen;
