import React from 'react';
import { TouchableOpacity, Text, StyleSheet, Image, View } from 'react-native';

import { analyticsActionForButtons } from '../lib/analyticsButtonClickHelper';
import Colors from '../constants/Colors';
import { BrandText } from './StyledText';

const buttonStates = {
  danger: {
    color: Colors.red,
    textColor: Colors.white,
  },
  disabled: {
    color: Colors.brightOrange,
    textColor: Colors.white,
  },
  important: {
    color: Colors.orange,
    textColor: Colors.white,
  },
  transparent: {
    color: Colors.white,
    textColor: Colors.black,
  },
  default: {
    color: Colors.green,
    textColor: Colors.promptText,
  },
};

const getStyleForProps = props => {
  if (props.danger) {
    return buttonStates.danger;
  } else if (props.disabled) {
    return buttonStates.disabled;
  } else if (props.important) {
    return buttonStates.important;
  } else if (props.transparent) {
    return buttonStates.transparent;
  }
  return buttonStates.default;
};

export const RoundButton = props => {
  const { style = {}, textStyle = {}, active, TextComponent = BrandText } = props;
  const stylesBasedOnProps = getStyleForProps(props);
  const defaultStyle = { backgroundColor: stylesBasedOnProps.color };

  return (
    <TouchableOpacity
      {...props}
      onPress={() => {
        props.onPress();
        analyticsActionForButtons(
          `Button: ${props.titleForAnalytics ? props.titleForAnalytics : props.title}`
        );
      }}
      style={[styles.button, styles.roundButton, defaultStyle, style]}>
      <TextComponent active={active} style={[styles.text, textStyle, { color: Colors.white }]}>
        {props.title}
      </TextComponent>
    </TouchableOpacity>
  );
};

export const FlatButton = props => {
  const {
    title = '',
    titleForAnalytics = '',
    style = {},
    textStyle = {},
    onPress,
    imageSource,
    iconComponent,
    imageStyle,
    imageRightSide = false,
    iconRightSide = false,
    TextComponent = BrandText,
    active,
    testID,
  } = props;
  const stylesBasedOnProps = getStyleForProps(props);
  const defaultStyle = {
    // backgroundColor: stylesBasedOnProps.color,
    flexDirection: imageRightSide || iconRightSide ? 'row-reverse' : 'row',
    marginHorizontal: 0,
  };
  const defaultTextStyle = { color: stylesBasedOnProps.textColor };

  // let imageSource
  // if (image && images[image]) {
  //   imageSource = images[image].source;
  // }

  return (
    <TouchableOpacity
      {...props}
      style={[styles.button, defaultStyle, style]}
      onPress={() => {
        onPress();
        analyticsActionForButtons(`Button: ${titleForAnalytics ? titleForAnalytics : title}`);
      }}
      testID={testID}>
      {imageSource && (
        <View style={{ margin: 5 }}>
          <Image
            resizeMode="contain"
            source={imageSource}
            style={[styles.image, imageStyle, active === false && { tintColor: Colors.blueGrey }]}
          />
          {active === false && (
            <Image
              resizeMode="contain"
              source={imageSource}
              style={[styles.image, imageStyle, { position: 'absolute', opacity: 0.8 }]}
            />
          )}
        </View>
      )}
      {iconComponent ? iconComponent : null}
      <TextComponent active={active} style={[styles.text, defaultTextStyle, textStyle]}>
        {title}
      </TextComponent>
    </TouchableOpacity>
  );
};

export const BackButton = ({ navigation, isLargeScreen, style, handlePress }) => (
  <View style={{}}>
    <Text />
    {/* HACK: Without this the height shrinks to nothing */}
    <FlatButton
      title="Back"
      imageSource={require('../assets/images/icons/back.png')}
      imageStyle={{ width: 25, height: 7 }}
      style={{ alignSelf: 'flex-start' }}
      // style={[style, { position: 'absolute', left: isLargeScreen ? -100 : -5 }]}
      onPress={() => {
        if (handlePress) {
          handlePress();
        } else {
          navigation.goBack();
        }
      }}
    />
  </View>
);

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  roundButton: {
    paddingHorizontal: 30,
    borderRadius: 20,
  },
  text: {
    //Note: moved to BrandText
    marginVertical: 10,
    fontSize: 16,
    fontWeight: '500',
    fontFamily: 'montserrat',
    color: '#FFFFFF',
  },
  image: {
    width: 33,
    height: 33,
  },
});
