import React, { useContext, useState } from 'react';
import { View, TouchableOpacity, Platform, TextInput, KeyboardAvoidingView } from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';

import moment from 'moment';
import { isUndefined, truncate } from 'lodash';
import { useMutation } from '@apollo/client';
// import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';

import Colors from '../../constants/Colors';
import styles from './styles';
import { FlatButton, RoundButton } from '../StyledButton';
import { TitleText, ContentText, DateText, UsernameText, TagText } from '../StyledText';
import NavigationService from '../../navigation/NavigationService';
import {
  likeResponseMutation,
  unlikeResponseMutation,
  flagChallengeMutation,
  flagResponseMutation,
  deleteChallengeMutation,
  makeChallengePrivateMutation,
  deleteResponseMutation,
  makeResponsePrivateMutation,
  pinItemMutation,
  unpinItemMutation,
} from '../../api/graphql/mutations';
import {
  getChallengesQuery,
  getMyChallengesQuery,
  myPrivateChallengesQuery,
  myPublishedChallengesQuery,
} from '../../api/graphql/queries';
import ModalContext from '../../lib/context';
import { useToastContext } from '../../lib/ToastContext';
import { analyticsActionForButtons } from '../../lib/analyticsButtonClickHelper';
import ShareIcon from '../../components/ShareIcon';

// const Entypo = dynamic(() => import('@expo/vector-icons').then(mod => mod.Entypo), {
//   ssr: false
// });

// const INITIAL_PAGINATION_LIMIT = 3; // TODO Find a sensible number here

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1s',
    ss: '%ss',
    m: '1min',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1day',
    dd: '%dd',
    M: '1month',
    MM: '%dmonth',
    y: '1y',
    yy: '%dy',
  },
});

const getChallengeButtonLabel = step => {
  const isThereAResponse = !!step?.response;

  const lastResponseIsPublished = step?.response?.published;
  const lastResponseIsPublic = step?.response?.public;
  if (!isThereAResponse) {
    return 'Start';
  } else if (!lastResponseIsPublished && !lastResponseIsPublic) {
    return 'Continue';
  }
  return 'View';
};

const PromptCard = ({
  tag,
  currentUser,
  username,
  flagChallenge,
  deleteChallenge,
  content,
  title,
  buttonColor,
  buttonLabel,
  createdAt,
  anonymous,
  private: isPrivate,
  responsesCount,
  likesCount,
  likedByMe,
  flaggedByMe,
  pinnedByMe,
  steps,
  id,
  cardType,
  response,
  // onPinPress,
  // onFlagPress,
  onPress,
  userId,
  refetch = () => {},
  saveComment,
  __typename,
  comments,
  testID,
  setTag,
}) => {
  const [runLikeMutation] = useMutation(likeResponseMutation);
  const [runUnlikeMutation] = useMutation(unlikeResponseMutation);
  const [runFlagChallengeMutation] = useMutation(flagChallengeMutation);
  const [runFlagResponseMutation] = useMutation(flagResponseMutation);

  const refetchQueriesForDeleteAndMakePrivateChallenge = [
    { query: getMyChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
    { query: myPrivateChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
  ];

  const [runDeleteChallengeMutation] = useMutation(deleteChallengeMutation, {
    refetchQueries: [
      ...refetchQueriesForDeleteAndMakePrivateChallenge,
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, sort: 'createdAt', isScheduled: true },
      },
    ],
  });
  const [runMakeChallengePrivateMutation] = useMutation(makeChallengePrivateMutation, {
    refetchQueries: refetchQueriesForDeleteAndMakePrivateChallenge,
  });
  const refetchQueriesForDeleteAndMakeItPrivateResponse = [
    {
      query: myPublishedChallengesQuery,
      variables: { limit: 30, offset: 0, sort: 'hotRank' },
    },
    { query: myPrivateChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
  ];
  const [runDeleteResponseMutation] = useMutation(deleteResponseMutation, {
    refetchQueries: refetchQueriesForDeleteAndMakeItPrivateResponse,
  });
  const [runMakeResponsePrivateMutation] = useMutation(makeResponsePrivateMutation, {
    refetchQueries: refetchQueriesForDeleteAndMakeItPrivateResponse,
  });
  const [runPinItemMutation] = useMutation(pinItemMutation, {
    refetchQueries: [
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, pinned: true, sort: 'createdAt' },
      },
    ],
  });
  const [runUnpinItemMutation] = useMutation(unpinItemMutation, {
    refetchQueries: [
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, pinned: true, sort: 'createdAt' },
      },
    ],
  });
  const ctx = useContext(ModalContext);
  const { show } = useToastContext();
  const amIThePoster = currentUser?.id && currentUser?.id === userId;
  // const [modalVisible, setModalVisible] = useState(false);
  const [, setModal] = ctx;
  const isChallenge = __typename === 'Challenge';
  const isResponse = __typename === 'Response';
  const isLoggedIn = !!currentUser;
  const showFlagIcon = !amIThePoster && isLoggedIn;
  const showPinIcon = !amIThePoster && isLoggedIn && isChallenge;
  const showSecretIcon = isLoggedIn && isPrivate;

  // console.log('----pinnedByMe', pinnedByMe);
  const onFlagPress = !showFlagIcon
    ? undefined
    : () => {
        setModal({
          title: 'Are you sure?',
          message: "You're about report this post as inappropriate.",
          buttons: {
            ok: {
              title: 'Flag',
              action: async () => {
                if (isChallenge) {
                  runFlagChallengeMutation({ variables: { challengeId: id } }).then(() => {
                    show('Challenge successfully flagged.');
                  });
                } else if (isResponse) {
                  runFlagResponseMutation({ variables: { responseId: id } }).then(() => {
                    show('Response successfully flagged.');
                  });
                }
                refetch();
              },
            },
          },
        });
      };

  const extraButton = isPrivate
    ? {}
    : {
        extra: {
          title: 'Make it private',
          action: async () => {
            if (isChallenge) {
              await runMakeChallengePrivateMutation({ variables: { challengeId: id } });
            } else if (isResponse) {
              await runMakeResponsePrivateMutation({ variables: { responseId: id } });
            }
            refetch();
          },
        },
      };

  const onDeletePress = !amIThePoster
    ? undefined
    : () => {
        setModal({
          title: 'Are you sure?',
          message: "You're about to delete this post",
          buttons: {
            ok: {
              title: 'Delete',
              action: async () => {
                console.log('----DELETING ', isChallenge, isResponse);
                if (isChallenge) {
                  await runDeleteChallengeMutation({ variables: { challengeId: id } });
                } else if (isResponse) {
                  console.log('---- REMOVING RESPONSE', id);
                  await runDeleteResponseMutation({ variables: { responseId: id } });
                }
                refetch();
              },
            },
            ...extraButton,
          },
        });
      };

  const onPinPress = !showPinIcon
    ? undefined
    : async () => {
        const variables = { id, itemType: 'Challenge' };
        // const pinnedByMe = true;
        if (!pinnedByMe) {
          await runPinItemMutation({ variables });
          refetch();
        } else {
          await runUnpinItemMutation({ variables });
          refetch();
        }
      };

  const onLikePress = async () => {
    const variables = { responseId: id };
    if (likedByMe) {
      await runUnlikeMutation({ variables });
      refetch();
    } else {
      await runLikeMutation({ variables });
      refetch();
    }
  };

  const handleLikeAndCommentPress = callback => {
    if (!isLoggedIn) {
      setModal({
        message: 'You need to be logged in for this :)',
        buttons: {
          ok: {
            title: 'Login',
            action: async () => {
              NavigationService.navigate('login');
            },
          },
        },
      });
    }

    callback();
  };

  const CommentsView = ({ currentUserUsername }) => (
    <>
      {comments?.map((comment, index) => (
        <View
          key={index}
          style={{
            borderColor: Colors.backgroundGrey,
            borderTopWidth: 0.2,
            paddingVertical: 15,
          }}>
          <TouchableOpacity
            testID={`Prompts.Comments.${id}`}
            style={{ flexDirection: 'row' }}
            onPress={() => {
              if (currentUserUsername === comment?.user?.username) {
                NavigationService.navigate('profile');
              } else {
                NavigationService.navigate('user', {
                  username: comment?.user?.username,
                });
              }
              analyticsActionForButtons(`comment username click - ${comment?.user?.username}`);
            }}>
            <UsernameText style={styles.textFooter}>@{comment?.user?.username}</UsernameText>
            <DateText>{moment(comment?.createdAt).fromNow()}</DateText>
          </TouchableOpacity>
          <ContentComponent content={comment?.content} />
        </View>
      ))}
    </>
  );
  // constructor() {
  //   super();
  //   this.state = {
  //     title: '',
  //     PAGINATION_LIMIT: INITIAL_PAGINATION_LIMIT,
  //     PAGINATION_OFFSET: 0,
  //     username: null,
  //   };
  // }

  // console.log('-----', this.props);
  let linkingProps = {};

  // TODO: Uncomment ffor linking
  // if (cardType === 'prompt') {
  //   linkingProps = {
  //     href: `/prompts/${id}`,
  //     as: `/prompts/${id}`,
  //     accessibilityRole: 'link'
  //   };
  // }

  if (flaggedByMe) {
    return <></>;
  }

  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        backgroundColor: Colors.white,
        padding: 15,
        marginVertical: 15,
        marginBottom: 0,
        borderColor: Colors.grey,
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
        ...Platform.select({
          android: {
            elevation: 1,
          },
          default: {
            shadowColor: 'rgba(0,0,0, .2)',
            shadowOffset: { height: 0, width: 0 },
            shadowOpacity: 1,
            shadowRadius: 1,
          },
        }),
      }}
      testID={testID}
      onPress={() => {
        if (onPress) {
          onPress();
        }
        analyticsActionForButtons('prompt card click');
      }}
      {...linkingProps}>
      <View style={{ width: '100%' }}>
        <TitleComponent
          id={id}
          title={title}
          username={username}
          userId={userId}
          anonymous={anonymous}
          createdAt={createdAt && moment(createdAt).fromNow()}
          isChallenge={isChallenge}
          onPinPress={onPinPress}
          onFlagPress={onFlagPress}
          onDeletePress={onDeletePress}
          pinnedByMe={pinnedByMe}
          isCurrentUserPrompt={currentUser?.username === username}
          showSecretIcon={showSecretIcon}
        />
        <ContentComponent content={content} />

        <CommentsView comments={comments} currentUserUsername={currentUser?.username} />

        {response ? (
          <View
            style={{
              borderColor: Colors.lightGrey,
              borderTopWidth: 0.1,
            }}>
            <ContentComponent content={response} />
          </View>
        ) : null}

        {/* <ButtonComponent /> */}
        <FooterComponent
          content={content}
          comments={comments}
          onPress={onPress}
          responsesCount={responsesCount}
          tag={tag}
          step={steps?.[0]}
          likesCount={likesCount}
          currentUser={currentUser}
          likedByMe={likedByMe ? true : false}
          handleLikeAndCommentPress={handleLikeAndCommentPress}
          isResponse={isResponse}
          saveComment={saveComment}
          id={id}
          setTag={setTag}
          onLikePress={onLikePress}
        />
      </View>
    </TouchableOpacity>
  );
};

const TitleComponent = ({
  title,
  tag,
  username,
  responsesCount,
  anonymous,
  showSecretIcon,
  createdAt,
  isChallenge,
  onPinPress,
  onFlagPress,
  onDeletePress,
  userId,
  pinnedByMe,
  id,
  isCurrentUserPrompt,
}) => (
  <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      {/* <TouchableOpacity
          // disabled={anonymous || !_.isUndefined(this.props.otherUserId)}
          onPress={() => {
            this.props.navigation.navigate('OtherUserChallenges', {
              otherUserId
            });
          }}
       */}
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        onPress={() => {
          if (isCurrentUserPrompt) {
            NavigationService.navigate('profile');
          } else {
            NavigationService.navigate('user', { username });
          }
          analyticsActionForButtons('prompt card - username click');
        }}>
        {username || anonymous ? (
          <UsernameText
            style={[
              styles.textFooter,
              isCurrentUserPrompt && { fontWeight: 'bold', color: Colors.black },
            ]}>
            {anonymous ? 'Anonymous' : `@${truncate(username, { length: 10 })}`}
          </UsernameText>
        ) : null}
        <DateText>{createdAt}</DateText>
      </TouchableOpacity>
      <View style={{ flex: 1 }} />
      <View
        style={{
          flexDirection: 'row',
          maxHeight: 10,
          alignItems: 'center',
        }}>
        {showSecretIcon ? (
          <MaterialCommunityIcons
            name="key"
            size={20}
            style={{ color: Colors.textGrey }}
            testID={`Button.Private.${id}`}
          />
        ) : null}
        {isChallenge && <ShareIcon partialUrl={`responses?id=${id}`} />}
        {onFlagPress && (
          <FlatButton
            titleForAnalytics="flag image of prompt card"
            imageSource={require('../../assets/images/icons/flag.png')}
            hitSlop={{ top: 15, bottom: 15, left: 10, right: 10 }}
            imageStyle={{ width: 18, height: 20, marginLeft: 10, resizeMode: 'contain' }}
            onPress={onFlagPress}
            testID={`Button.Flag.${id}`}
          />
        )}
        {onPinPress && (
          <FlatButton
            titleForAnalytics="pin icon of prompt card"
            // active={pinnedByMe}
            hitSlop={{ top: 15, bottom: 15, left: 10, right: 10 }}
            imageSource={
              pinnedByMe
                ? require('../../assets/images/icons/pinnedByMe.png')
                : require('../../assets/images/icons/pin.png')
            }
            testID={`Button.Pin.${id}`}
            imageStyle={{
              width: 13,
              height: 20,
              marginLeft: 10,
              resizeMode: 'contain',
            }}
            onPress={onPinPress}
          />
        )}
        {onDeletePress && (
          <FlatButton
            titleForAnalytics="delete icon of prompt card"
            testID={`Button.Delete.${id}`}
            imageSource={require('../../assets/images/icons/trash.png')}
            hitSlop={{ top: 15, bottom: 15, left: 10, right: 10 }}
            imageStyle={{ width: 18, height: 20, marginLeft: 10, resizeMode: 'contain' }}
            onPress={onDeletePress}
          />
        )}
      </View>
    </View>
    <View style={{ flex: 1 }}>
      <TitleText
        style={{
          textAlign: 'center',
          marginTop: 10,
        }}>
        {title}
      </TitleText>
    </View>
    {/* <View style={{ position: 'absolute', right: 5 }}> */}
    {/* <Menu>
        <MenuTrigger>
          <Text>:</Text>
          <Entypo name="dots-three-vertical" size={20} />
        </MenuTrigger>
        <MenuOptions>
          <MenuOption onSelect={() => {}}>
            <Text style={{ fontSize: 20, margin: 5 }}>Flag</Text>
          </MenuOption>
          <MenuOption onSelect={() => {}}>
            <Text style={{ color: 'red', fontSize: 20, margin: 5 }}>Delete</Text>
          </MenuOption>
        </MenuOptions>
      </Menu> */}
    {/* </View> */}
  </View>
);

const ContentComponent = ({ content }) => (
  <ContentText style={{ marginTop: 22 }}>{content}</ContentText>
);

const FooterComponent = ({
  onPress,
  createdAt,
  username,
  responsesCount,
  likesCount,
  anonymous,
  tag,
  step,
  currentUser,
  likedByMe,
  handleLikeAndCommentPress,
  isResponse,
  saveComment,
  id,
  comments,
  content,
  setTag,
  onLikePress,
  // onBubblePress,
}) => {
  const [replyVisible, setReplyVisible] = useState(false);
  const [myComment, setMyComment] = useState();

  const handleSetMyComment = event => {
    // setIsResponding(!isResponding);
    setMyComment(event?.nativeEvent?.text || '');
  };

  const handleSaveComment = () => {
    saveComment({ content: myComment, parentId: id });
    setMyComment('');
  };

  const onBubblePress = !isResponse
    ? undefined
    : () => {
        // if (Platform.OS === 'web') {
        setReplyVisible(!replyVisible);
        // } else {
        //   NavigationService.navigate('comment', { content });
        // }
      };

  return (
    <View style={{ marginTop: 20 }}>
      {onPress && currentUser && (
        <FlatButton
          title={getChallengeButtonLabel(step)}
          titleForAnalytics="promt card - Button.Start.(id)"
          onPress={onPress}
          testID={`Button.Start.${id}`}
          style={{
            borderWidth: 1,
            alignSelf: 'center',
            paddingHorizontal: 40,
            borderColor: Colors.blueGrey,
            borderRadius: 50,
          }}
        />
      )}
      <View
        style={{
          flexDirection: 'row',
          // alignItems: 'center',
          // textAlign: 'center',
          // marginHorizontal: 10,
        }}>
        {/* <RoundButton title="Reply" /> */}
        {(tag && (
          <FlatButton
            type="outline"
            TextComponent={TagText}
            // onPress={() => {
            //   this.onRepliesPress(challenge.item);
            // }}
            transparent
            // buttonStyle={{
            //   borderColor: Colors.blueGrey,
            //   borderRadius: 30,
            //   borderWidth: 0.1,
            //   paddingHorizontal: 10,
            //   paddingVertical: 2
            // }}
            // raised
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            // icon=<SimpleLineIcons name="bubble" size={18} color={Colors.textGrey} />
            title={`#${tag}`}
            titleForAnalytics="prompt card tag"
            // titleStyle={{
            //   color: Colors.blueGrey
            // }}
            onPress={() => setTag(tag)}
          />
        )) || <View />}
        {/* <View> */}
        {/* <SimpleLineIcons name="bubble" size={18} color={Colors.blue} /> */}
        <View style={{ flex: 1 }} />
        {!isUndefined(responsesCount) && (
          <FlatButton
            type="outline"
            // onPress={() => {
            //   this.onRepliesPress(challenge.item);
            // }}
            onPress={onPress}
            transparent
            imageSource={require('../../assets/images/icons/responses.png')}
            imageStyle={{ width: 19.6, height: 17 }}
            // buttonStyle={{
            //   borderColor: Colors.blueGrey,
            //   borderRadius: 30,
            //   borderWidth: 0.1,
            //   paddingHorizontal: 10,
            //   paddingVertical: 2
            // }}
            // raised
            hitSlop={{ top: 15, left: 5, right: 15, bottom: 15 }}
            // icon=<SimpleLineIcons name="bubble" size={18} color={Colors.textGrey} />
            title={`${responsesCount}`}
            titleForAnalytics="responsesCount"
            // titleStyle={{
            //   color: Colors.blueGrey
            // }}
          />
        )}
        {onBubblePress && (
          <FlatButton
            type="outline"
            onPress={() => handleLikeAndCommentPress(onBubblePress)}
            transparent
            imageSource={require('../../assets/images/icons/responses.png')}
            imageStyle={{ width: 19.6, height: 17 }}
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            style={{ marginRight: 15 }}
            title="Comment"
            titleForAnalytics="Comment.(id)"
            testID={`Comment.${id}`}
          />
        )}
        {!isUndefined(likesCount) && (
          <FlatButton
            type="outline"
            // onPress={() => {
            //   this.onRepliesPress(challenge.item);
            // }}
            testID={`Like.${id}`}
            onPress={() => handleLikeAndCommentPress(onLikePress)}
            active={likedByMe}
            transparent
            imageSource={require('../../assets/images/icons/leaf.png')}
            imageStyle={{ width: 17, height: 17 }}
            // buttonStyle={{
            //   borderColor: Colors.blueGrey,
            //   borderRadius: 30,
            //   borderWidth: 0.1,
            //   paddingHorizontal: 10,
            //   paddingVertical: 2
            // }}
            // raised
            hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
            // icon=<SimpleLineIcons name="bubble" size={18} color={Colors.textGrey} />
            title={`${likesCount}`}
            titleForAnalytics="prompt card - likesCount"
            // titleStyle={{
            //   color: Colors.blueGrey
            // }}
          />
        )}
        {/* </View> */}
      </View>
      {replyVisible && (
        <>
          <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }} keyboardVerticalOffset={70}>
            <TextInput
              style={[
                {
                  borderColor: 'gray',
                  // borderWidth: 1,
                  opacity: 0.75,
                  padding: 10,
                  backgroundColor: Colors.white,
                  lineHeight: 20,
                  minHeight: 100,
                },
              ]}
              multiline
              // placeholderTextColor={Colors.promptText}
              placeholder="Start typing..."
              onChange={handleSetMyComment}
              value={myComment}
              testid="comment-input"
            />
            <View style={{ alignItems: 'flex-start', marginTop: 10 }}>
              <RoundButton
                important
                // disabled
                title="Save"
                const
                onPress={handleSaveComment}
              />
            </View>
          </KeyboardAvoidingView>
        </>
      )}
    </View>
  );
};
export default PromptCard;
