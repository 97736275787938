import { gql } from '@apollo/client';

export const createChallengeMutation = gql`
  mutation CreateChallengeMutation($challenge: ChallengeInput!) {
    createChallenge(challenge: $challenge) {
      id
      title
      createdAt
      tag
      user {
        id
        username
      }
      responsesCount
      steps {
        id
        order
        content
        createdAt
        response {
          id
          content
          createdAt
          public
          published
          private
        }
      }
    }
  }
`;

export const loginUserMutation = gql`
  mutation LoginUserMutation($details: LoginDetails!) {
    loginUser(details: $details) {
      token
      user {
        id
        username
        pushEnabled
        pushEnabledForPromotedPrompts
      }
    }
  }
`;

export const registerUserMutation = gql`
  mutation RegisterUserMutation($profileDetails: RegistrationProfileDetails!) {
    registerUser(profileDetails: $profileDetails) {
      token
      user {
        id
        username
      }
    }
  }
`;

export const createResponseMutation = gql`
  mutation CreateResponseMutation($response: ResponseInput!) {
    createResponse(response: $response) {
      id
      content
    }
  }
`;

export const createCommentMutation = gql`
  mutation CreateCommentMutation($response: CommentInput!) {
    createComment(response: $response) {
      id
      content
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($profileDetails: UpdateProfileDetails!) {
    updateUser(profileDetails: $profileDetails) {
      id
      username
      bio
      website
    }
  }
`;

export const unlikeResponseMutation = gql`
  mutation unlikeResponse($responseId: ID!) {
    unlikeResponse(responseId: $responseId)
  }
`;
export const likeResponseMutation = gql`
  mutation likeResponse($responseId: ID!) {
    likeResponse(responseId: $responseId)
  }
`;

export const deleteChallengeMutation = gql`
  mutation deleteChallenge($challengeId: ID!) {
    deleteChallenge(challengeId: $challengeId)
  }
`;

export const makeChallengePrivateMutation = gql`
  mutation makeChallengePrivate($challengeId: ID!) {
    makeChallengePrivate(challengeId: $challengeId)
  }
`;

export const deleteResponseMutation = gql`
  mutation deleteResponse($responseId: ID!) {
    deleteResponse(responseId: $responseId)
  }
`;

export const makeResponsePrivateMutation = gql`
  mutation makeResponsePrivate($responseId: ID!) {
    makeResponsePrivate(responseId: $responseId)
  }
`;

export const flagChallengeMutation = gql`
  mutation flagChallenge($challengeId: ID!) {
    flagChallenge(challengeId: $challengeId)
  }
`;

export const flagResponseMutation = gql`
  mutation flagResponse($responseId: ID!) {
    flagResponse(responseId: $responseId)
  }
`;

export const pinItemMutation = gql`
  mutation pinItem($id: ID!, $itemType: ItemType!) {
    pinItem(id: $id, itemType: $itemType)
  }
`;

export const unpinItemMutation = gql`
  mutation unpinItem($id: ID!, $itemType: ItemType!) {
    unpinItem(id: $id, itemType: $itemType)
  }
`;

export const followOtherUserMutation = gql`
  mutation followOtherUser($otherUserId: ID!) {
    followOtherUser(otherUserId: $otherUserId)
  }
`;

export const unfollowOtherUserMutation = gql`
  mutation unfollowOtherUser($otherUserId: ID!) {
    unfollowOtherUser(otherUserId: $otherUserId)
  }
`;

export const setPushTokenMutation = gql`
  mutation setPushToken(
    $pushToken: String!
    $usersResponseToEnablePushNotificationsPrompt: ResponseToEnablePushNotificationsPromptEnum
  ) {
    setPushToken(
      pushToken: $pushToken
      usersResponseToEnablePushNotificationsPrompt: $usersResponseToEnablePushNotificationsPrompt
    ) {
      id
    }
  }
`;

export const setPushEnabledForPromotedPromptsMutation = gql`
  mutation setPushEnabledForPromotedPrompts($pushEnabledForPromotedPrompts: Boolean!) {
    setPushEnabledForPromotedPrompts(
      pushEnabledForPromotedPrompts: $pushEnabledForPromotedPrompts
    ) {
      id
    }
  }
`;
