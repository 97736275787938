import React, { createContext, useMemo, useState, useEffect } from 'react';

const ToastContext = createContext();

const useToastContext = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within a ToastContextProvider');
  }

  const { setTime, msg, setMsg, visible, setVisible } = context;

  const show = (message, delay, duration) => {
    setMsg(message);
    if (duration && duration > 0) {
      setTime(duration);
    }
    setTimeout(() => {
      setVisible(true);
    }, delay);
  };

  return { show, visible, msg };
};

const ToastContextProvider = props => {
  const [msg, setMsg] = useState('');
  const [time, setTime] = useState(1500);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
        setTime(1500);
      }, time);
    }
  });

  const value = useMemo(
    () => ({
      msg,
      setMsg,
      time,
      setTime,
      visible,
      setVisible,
    }),
    [msg, time, visible]
  );

  return <ToastContext.Provider value={value} {...props} />;
};

export { ToastContextProvider, useToastContext };
