import { Dimensions } from 'react-native';
import { useEffect, useState } from 'react';

const useWindowDimensions = () => {
  const [screenData, setScreenData] = useState(Dimensions.get('window'));

  useEffect(() => {
    const onChange = result => {
      setScreenData(result.window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  });

  return {
    ...screenData,
    isLandscape: screenData.width > screenData.height,
    isLargeScreen: screenData.width > 1000,
  };
};

export default useWindowDimensions;
