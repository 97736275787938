import React, { createContext, useMemo, useState } from 'react';

const ModalContext = createContext();

const useResponseModalState = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useResponseModalState must be used within a ResponseModalContext Provider');
  }

  return context;
};

const ResponseModalContextProvider = props => {
  const privacyOptionsObject = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    ANONYMOUS: 'anonymous',
  };
  const [privacy, setPrivacy] = useState(privacyOptionsObject.PUBLIC);
  const [promotion, setPromotion] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [privacyOptions] = useState(privacyOptionsObject);
  const [modalMyResponse, setModalMyResponse] = useState('');

  const value = useMemo(
    () => ({
      privacy,
      setPrivacy,
      promotion,
      setPromotion,
      modalVisible,
      setModalVisible,
      privacyOptions,
      modalMyResponse,
      setModalMyResponse,
    }),
    [privacy, promotion, modalVisible, modalMyResponse, privacyOptions]
  );

  return <ModalContext.Provider value={value} {...props} />;
};

export { ResponseModalContextProvider, useResponseModalState };
