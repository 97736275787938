import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

const LoadingIndicator = ({
  animating = true,
  style,
  size = 'large',
  color = Colors.green,
  overrideStyles = false,
}) => {
  let appliedStyle = styles.loading;
  if (style) {
    if (overrideStyles) {
      appliedStyle = style;
    } else {
      appliedStyle = [styles.loading, style];
    }
  }

  return (
    <View style={appliedStyle}>
      <ActivityIndicator size={size} color={color} animating={animating} />
    </View>
  );
};

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LoadingIndicator;
