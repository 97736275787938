import { gql } from '@apollo/client';

const userDetailsFragment = gql`
  fragment userDetails on User {
    id
    username
  }
`;

const responseDetailsFragment = gql`
  fragment responseDetails on Response {
    id
    createdAt
    content
    published
    public
    private
    anonymous
  }
`;

const challengeDetailsFragment = gql`
  fragment challengeDetails on Challenge {
    id
    title
    createdAt
    endsAt
    public
    private
    anonymous

    user {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

const stepDetailsFragment = gql`
  fragment stepDetails on Step {
    id
    order
    content
    createdAt
    response {
      ...responseDetails
    }
  }
  ${responseDetailsFragment}
`;

export const getChallengeResponsesQuery = gql`
  query challengeResponses(
    $challengeId: ID!
    $limit: PositiveInt!
    $offset: NonNegativeInt!
    $highlightedAttemptId: ID
  ) {
    challengeResponses(
      challengeId: $challengeId
      highlightedAttemptId: $highlightedAttemptId
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "dateTime", order: desc }] }
    ) {
      ...challengeDetails
      now
      closed
      tag
      responses {
        ...responseDetails
        likesCount
        likedByMe
        flaggedByMe
        rank
        user {
          ...userDetails
        }
        comments {
          parentId
          id
          createdAt
          content
          published
          likesCount
          likedByMe
          flaggedByMe
          anonymous
          rank
          user {
            ...userDetails
          }
        }
      }
      steps {
        ...stepDetails
      }
    }
  }
  ${userDetailsFragment}
  ${challengeDetailsFragment}
  ${stepDetailsFragment}
  ${responseDetailsFragment}
`;

export const getChallengesQuery = gql`
  query challenges(
    $limit: PositiveInt!
    $offset: NonNegativeInt!
    $sort: String!
    $tag: String
    $otherUserId: ID
    $pinned: Boolean
    $isFollowing: Boolean
    $isScheduled: Boolean
  ) {
    challenges(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: $sort, order: desc }] }
      tag: $tag
      otherUserId: $otherUserId
      pinned: $pinned
      isFollowing: $isFollowing
      isScheduled: $isScheduled
    ) {
      ...challengeDetails
      flaggedByMe
      pinnedByMe
      tag
      responsesCount
      steps {
        ...stepDetails
      }
    }
  }
  ${stepDetailsFragment}
  ${challengeDetailsFragment}
`;

export const getMyChallengesQuery = gql`
  query myChallenges($limit: PositiveInt!, $offset: NonNegativeInt!) {
    myChallenges(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "createdAt", order: desc }] }
    ) {
      ...challengeDetails
      responsesCount
      steps {
        ...stepDetails
      }
    }
  }
  ${challengeDetailsFragment}
  ${stepDetailsFragment}
`;

export const myPrivateChallengesQuery = gql`
  query myPrivateChallenges($limit: PositiveInt!, $offset: NonNegativeInt!) {
    myPrivateChallenges(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "createdAt", order: desc }] }
    ) {
      ...challengeDetails
      responsesCount
      myResponse {
        ...responseDetails
      }
      steps {
        ...stepDetails
      }
    }
  }
  ${challengeDetailsFragment}
  ${responseDetailsFragment}
  ${stepDetailsFragment}
`;

export const myPublishedChallengesQuery = gql`
  query myPublishedChallenges($limit: PositiveInt!, $offset: NonNegativeInt!) {
    myPublishedChallenges(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "createdAt", order: desc }] }
    ) {
      ...challengeDetails
      myResponse {
        ...responseDetails
      }
      responsesCount
      userResponseCount
      steps {
        ...stepDetails
      }
    }
  }
  ${challengeDetailsFragment}
  ${responseDetailsFragment}
  ${stepDetailsFragment}
`;

export const getOtherUserResponsesQuery = gql`
  query getOtherUserResponses($limit: PositiveInt!, $offset: NonNegativeInt!, $username: String!) {
    getOtherUserResponses(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "createdAt", order: desc }] }
      username: $username
    ) {
      ...challengeDetails
      myResponse {
        ...responseDetails
      }
      responsesCount
      userResponseCount
      steps {
        ...stepDetails
      }
    }
  }
  ${challengeDetailsFragment}
  ${responseDetailsFragment}
  ${stepDetailsFragment}
`;

export const currentUserQuery = gql`
  query currentUser {
    currentUser {
      id
      isAdmin
      username
      bio
      website
      pushEnabled
      pushEnabledForPromotedPrompts
      achievements {
        title
        description
        progress
        condition
        conditionCount
        id
        isDone
        level
      }
      stats {
        numUniqueWords
        numWords
        numTrophies
        numTotalLikes
        numResponses
        numChallenges
        level
        score
      }
      prizes {
        id
        isPurchased
        title
        description
        cost
        order
        comingsoon
      }
    }
  }
`;

export const notificationsCountQuery = gql`
  query notificationsCount {
    notificationsCount {
      count
    }
  }
`;

export const allNotificationsQuery = gql`
  query allNotifications($limit: PositiveInt!, $offset: NonNegativeInt!) {
    allNotifications(
      pagination: { limit: $limit, skip: $offset, sort: [{ field: "createdAt", order: desc }] }
    ) {
      id
      type
      heading
      body
      read
      challengeId
      followerName
      commentUsername
    }
  }
`;

export const getUserByUsernameQuery = gql`
  query getOneUserByUsername($username: String!) {
    getOneUserByUsername(username: $username) {
      id
      username
      bio
      website
      followedByMe
      privileges {
        canEditBio
        canEditLink
        canPostAnnonymously
      }
    }
  }
`;
