import React, { useState } from 'react';
import {
  TextInput,
  View,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Platform,
  Keyboard,
  SafeAreaView,
} from 'react-native';
import Colors from '../../constants/Colors';
import { PageHeaderText, ContentText } from '../../components/StyledText';
import { FlatButton, BackButton } from '../../components/StyledButton';
import useWindowDimensions from '../../lib/useWindowDimensions';
import { useResponseModalState } from '../../lib/ResponseModalContext';
import ErrorView from '../../components/ErrorView';

const NewResponse = ({ navigation }) => {
  const {
    setModalVisible,
    modalMyResponse,
    setModalMyResponse,
    privacyOptions,
    privacy,
    promotion,
  } = useResponseModalState();

  const headerData = navigation.getParam('mappedData');
  const saveResponse = navigation.getParam('saveResponse');

  const { isLargeScreen } = useWindowDimensions();
  const [errors, setErrors] = useState({});

  const [myResponse, setMyResponse] = useState(modalMyResponse || '');

  const handleSetMyResponse = event => {
    // setIsResponding(!isResponding);
    setMyResponse(event?.nativeEvent?.text || '');
  };

  const saveOnPress = async () => {
    validateInputs();
    if (!isValidInputs()) {
      return;
    }
    await saveResponse({ content: myResponse, modalSelections: { privacy, promotion } });
    setModalMyResponse('');
    setMyResponse('');
  };

  const isValidInputs = () => myResponse?.length > 0;
  const validateInputs = () => {
    const newErrors = {};
    if (myResponse?.length === 0) {
      newErrors.response = 'Please write something before saving :)';
    }
    setErrors(newErrors);
  };

  const [responseVisible, setResponseVisible] = useState(true);
  const onPressContainer = Platform.OS === 'web' ? () => {} : Keyboard.dismiss;

  return (
    <View
      style={[
        { flex: 1, paddingHorizontal: 15 },
        {
          backgroundColor: Colors.backgroundGrey,
          // padding: 30,
          paddingHorizontal: '10%',
        },
      ]}>
      <TouchableWithoutFeedback onPress={onPressContainer}>
        <SafeAreaView
          style={[
            {
              flex: 1,
              // paddingTop: Platform.OS === 'android' ? 25 : 0,
            },
          ]}>
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 25,
              }}>
              <BackButton
                navigation={navigation}
                isLargeScreen={isLargeScreen}
                handlePress={() => {
                  setModalMyResponse(myResponse);
                  navigation.goBack();
                }}
              />

              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row' }}>
                  <FlatButton
                    title="Publish"
                    onPress={saveOnPress}
                    style={{
                      borderRightWidth: 1,
                      borderColor: Colors.lighterOranger,
                      backgroundColor: Colors.orange,
                      borderBottomLeftRadius: 10,
                      borderTopLeftRadius: 10,
                      paddingHorizontal: 10,
                    }}
                    important
                  />
                  <FlatButton
                    titleForAnalytics="new response up icon"
                    onPress={() => {
                      setModalVisible(prev => !prev);
                      setModalMyResponse(myResponse);
                    }}
                    style={{
                      borderRightWidth: 1,
                      backgroundColor:
                        privacy !== privacyOptions.PUBLIC || !promotion
                          ? Colors.lighterOranger
                          : Colors.orange,
                      borderBottomRightRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    imageSource={require('../../assets/images/icons/up.png')}
                    imageStyle={{
                      width: 20,
                      tintColor: 'white',
                      ...(true && { transform: [{ rotate: '180deg' }] }),
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={{ marginTop: 5 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <PageHeaderText onPress={() => setResponseVisible(!responseVisible)}>
                  {headerData?.title}
                </PageHeaderText>
                <FlatButton
                  titleForAnalytics="new response up icon"
                  style={{
                    borderColor: Colors.lightGrey,
                    zIndex: 999,
                  }}
                  testID={'Nav.Settings'}
                  imageSource={require('../../assets/images/icons/up.png')}
                  imageRightSide
                  imageStyle={{
                    width: 20,
                    ...(responseVisible && { transform: [{ rotate: '180deg' }] }),
                  }}
                  onPress={() => setResponseVisible(!responseVisible)}
                  active={responseVisible}
                />
              </View>
              {responseVisible && (
                <ContentText onPress={() => setResponseVisible(!responseVisible)}>
                  {headerData?.content}
                </ContentText>
              )}
            </View>
            <View
              style={{
                borderBottomColor: Colors.blueGrey,
                borderBottomWidth: 1,
                opacity: 0.3,
                // marginLeft: 30,
                marginVertical: 20,
              }}
            />
          </>

          <KeyboardAvoidingView
            behavior="padding"
            style={{ flex: 1 }}
            keyboardVerticalOffset={70}
            onPress={onPressContainer}>
            <ErrorView msg={errors.response} />
            <View style={{ flex: 1 }}>
              <TextInput
                placeholder={'Type your response...'}
                style={[
                  {
                    minHeight: '100%',
                  },
                  { backgroundColor: Colors.white, padding: 10, lineHeight: 20 },
                ]}
                multiline
                onChange={handleSetMyResponse}
                value={myResponse}
              />
              {/* <View style={{ flex: 1 }} /> */}
            </View>
          </KeyboardAvoidingView>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default NewResponse;
