import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  Image,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import PromptCard from '../../components/PromptCard';
import SettingsModal from '../../components/SettingsModal';
import {
  getChallengeResponsesQuery,
  currentUserQuery,
  myPublishedChallengesQuery,
  myPrivateChallengesQuery,
} from '../../api/graphql/queries';
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../lib/useWindowDimensions';
import { ListHeaderText, PageHeaderText } from '../../components/StyledText';
import { FlatButton, BackButton, RoundButton } from '../../components/StyledButton';
import ErrorView from '../../components/ErrorView';
import { createResponseMutation, createCommentMutation } from '../../api/graphql/mutations';
import { useResponseModalState } from '../../lib/ResponseModalContext';
import { analyticsActionForButtons } from '../../lib/analyticsButtonClickHelper';
import Loading from '../../components/LoadingIndicator';

let Helmet;
if (Platform.OS === 'web') {
  Helmet = require('react-helmet').Helmet;
}
import NotFound from '../404/';

// const unflatten = (array = [], parent, tree) => {
//   // console.log('----unflatten', array);

//   const children = array.filter(item => item.parentId);
//   console.log('---children', children);

//   const filteredParents = array.filter(item => !item.parentId);

//   children.forEach(child => {
//     const parentIndex = array.findIndex(item => item.id === child.parentId);
//     if (Array.isArray(filteredParents?.[parentIndex]?.children)) {
//       filteredParents[parentIndex].children.push(child);
//     } else {
//       filteredParents[parentIndex].children = [child];
//     }

//     console.log('---child', child.parentId, parentIndex);
//   });

//   return filteredParents;

//   // tree = typeof tree !== 'undefined' ? tree : [];
//   // parent = typeof parent !== 'undefined' ? parent : { id: 0 };

//   // var children = _.filter(array, child => {
//   //   console.log('\n\n----children', child.parentId);
//   //   console.log('----children', parent.id);

//   //   return child.parentId === parent.id;
//   // });
//   // if (!_.isEmpty(children)) {
//   //   if (parent.id == 0) {
//   //     tree = children;
//   //   } else {
//   //     parent['children'] = children;
//   //   }
//   //   _.each(children, function(child) {
//   //     unflatten(array, child);
//   //   });
//   // }

//   // return tree;
// };

const ListHeaderComponent = ({
  mappedData,
  navigation,
  isLargeScreen,
  saveResponse,
  handleSetResponsesVisilibity,
  responsesVisilibity,
  currentUser,
  errors,
  setErrors,
}) => {
  const {
    privacy,
    promotion,
    modalMyResponse,
    setModalMyResponse,
    privacyOptions,
    setModalVisible,
  } = useResponseModalState();

  const [myResponse, setMyResponse] = useState(modalMyResponse || '');

  const handleSetMyResponse = event => {
    // setIsResponding(!isResponding);
    setMyResponse(event?.nativeEvent?.text || '');
  };

  const saveOnPress = async () => {
    validateInputs();
    if (!isValidInputs()) {
      return;
    }
    await saveResponse({
      content: modalMyResponse.length > myResponse ? modalMyResponse : myResponse,
    });

    setMyResponse('');
    setModalMyResponse('');
  };

  const isValidInputs = () => myResponse?.length > 0;
  const validateInputs = () => {
    const newErrors = {};
    if (myResponse?.length === 0) {
      newErrors.response = 'Please write something before saving :)';
    }
    setErrors(newErrors);
  };

  return (
    <View style={{ maxWidth: 800, width: '100%', alignSelf: 'center' }}>
      <BackButton navigation={navigation} isLargeScreen={isLargeScreen} />

      <PromptCard
        {...mappedData}
        currentUser={currentUser}
        // onSharePress={() => Alert('onSharePress')}
        // onPinPress={() => Alert('onPinPress')}
        // onFlagPress={() => Alert('onFlagPress')}
      />

      {currentUser && isLargeScreen && (
        <>
          <View style={{ flexDirection: 'row', marginTop: 25 }}>
            <PageHeaderText>Response:</PageHeaderText>

            <View style={{ flex: 1, alignItems: 'flex-end' }}>
              <View style={{ flexDirection: 'row' }}>
                <FlatButton
                  title="Publish"
                  onPress={saveOnPress}
                  style={{
                    borderRightWidth: 1,
                    borderColor: Colors.lighterOranger,
                    backgroundColor: Colors.orange,
                    borderBottomLeftRadius: 10,
                    borderTopLeftRadius: 10,
                    paddingHorizontal: 10,
                  }}
                  important
                />
                <FlatButton
                  titleForAnalytics="Responses screen up icon"
                  onPress={() => {
                    setModalMyResponse(myResponse);
                    setModalVisible(prev => !prev);
                  }}
                  style={{
                    borderRightWidth: 1,
                    backgroundColor:
                      privacy !== privacyOptions.PUBLIC || !promotion
                        ? Colors.lighterOranger
                        : Colors.orange,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                  imageSource={require('../../assets/images/icons/up.png')}
                  imageStyle={{
                    width: 20,
                    tintColor: 'white',
                    ...(true && { transform: [{ rotate: '180deg' }] }),
                  }}
                />
              </View>
            </View>
          </View>

          {/* <PageHeaderText>Add New Comment</PageHeaderText> */}
          <View>
            <TextInput
              style={[
                {
                  borderColor: 'gray',
                  // borderWidth: 1,
                  opacity: 0.75,
                  padding: 10,
                  backgroundColor: Colors.white,
                  lineHeight: 20,
                  minHeight: 100,
                },
              ]}
              multiline
              // placeholderTextColor={Colors.promptText}
              placeholder="Start typing..."
              onChange={handleSetMyResponse}
              value={myResponse}
            />
          </View>
          <ErrorView msg={errors.response} />
        </>
      )}
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 20,
          alignItems: 'center',
        }}>
        <TouchableOpacity
          onPress={() => {
            handleSetResponsesVisilibity();
            analyticsActionForButtons('review/toggle challenge button - new response');
          }}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <ListHeaderText>Responses ({mappedData?.responses?.length})</ListHeaderText>
          <Image
            source={require('../../assets/images/icons/up.png')}
            style={{
              width: 10,
              height: 10,
              marginLeft: 5,
              ...(responsesVisilibity && { transform: [{ rotate: '180deg' }] }),
            }}
          />
        </TouchableOpacity>

        <View
          style={{
            borderBottomColor: Colors.blueGrey,
            borderBottomWidth: 1,
            opacity: 0.3,
            flex: 1,
            marginLeft: 30,
          }}
        />
      </View>
    </View>
  );
};

// const LABELS = {
//   START: 'Start',
//   CONTINUE: 'Continue',
//   VIEW: 'View',
// };
// const getChallengeButtonLabel = step => {
//   // TODO: copy=pasted from Prompts, should be unified
//   const isThereAResponse = !!step?.response;

//   const lastResponseIsPublished = step?.response?.published;
//   const lastResponseIsPublic = step?.response?.public;

//   console.log(
//     'step, isThereAResponse, lastResponseIsPublished, lastResponseIsPublic',
//     step,
//     isThereAResponse,
//     lastResponseIsPublished,
//     lastResponseIsPublic
//   );

//   // if (!isThereAResponse) {
//   //   return LABELS.START;
//   // } else
//   if (!lastResponseIsPublished && !lastResponseIsPublic) {
//     return LABELS.CONTINUE;
//   }

//   return LABELS.START;
// };

const Responses = ({ navigation }) => {
  const { isLargeScreen } = useWindowDimensions();

  const [isResponding] = useState(false);
  const [responsesVisilibity, setResponsesVisilibity] = useState(true);
  const [errors, setErrors] = useState({});

  const {
    privacy,
    setPrivacy,
    promotion,
    setPromotion,
    modalVisible,
    setModalVisible,
    privacyOptions,
    setModalMyResponse,
    modalMyResponse,
  } = useResponseModalState();

  const challengeId = navigation.state.params.id;
  const { loading, data, refetch: refetchGetChallengesResponses } = useQuery(
    getChallengeResponsesQuery,
    {
      variables: { challengeId, limit: 200, offset: 0, sort: 'createdAt' },
    }
  );

  const { data: userData = {} } = useQuery(currentUserQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const { currentUser } = userData;

  const [createResponse] = useMutation(createResponseMutation, {
    refetchQueries: [
      { query: myPublishedChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
      { query: myPrivateChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
    ],
  });
  const [createComment] = useMutation(createCommentMutation);

  const handleSetResponsesVisilibity = () => {
    setResponsesVisilibity(!responsesVisilibity);
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const description = document.querySelector('meta[name="description"]');
      if (description) {
        description.remove();
      }
    }

    return () => {
      setPrivacy(privacyOptions.PUBLIC);
      setPromotion(true);
      setModalMyResponse('');
      setModalVisible(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />;
  }

  const { challengeResponses } = data || { challengeResponses: [] };
  const lastStep = challengeResponses?.steps?.[0];
  const stepId = lastStep?.id; // TODO: legacy, get rid of this

  const mappedData = {
    ...challengeResponses,
    content: challengeResponses?.steps?.[0]?.content, // TODO Make this easier via the backend
    lastStep: challengeResponses?.steps?.[0],
    userId: challengeResponses?.user?.id,
    username: challengeResponses?.user?.username,
    responses: challengeResponses?.responses?.map(response => ({
      ...response,
      username: response?.user?.username,
      userId: response?.user?.id,
    })),
  };
  // console.log('---mappedData', mappedData?.responses);
  // console.log('---unflattened', unflatten(mappedData?.responses));

  const saveComment = async ({ content, parentId }) => {
    await createComment({
      variables: {
        response: {
          content,
          published: true,
          public: true,
          parentId,
        },
      },
    });
    await refetchGetChallengesResponses();
  };

  const saveResponse = async ({ content, modalSelections, navigation: modalNavigation }) => {
    const privacyLocal = modalSelections ? modalSelections.privacy : privacy;
    const promotionLocal = modalSelections ? modalSelections.promotion : promotion;

    // console.log('+++saveResponse+++');
    // console.log('content, privacy, promotion', content, privacyLocal, promotionLocal);
    // console.log('+++saveResponse+++');

    // if we submited from newResponses or modal's submit, we need to navigate to responses again
    navigation.navigate('responses', { id: challengeId });

    await createResponse({
      variables: {
        response: {
          content,
          challengeId,
          stepId,
          published: true,
          public: privacyLocal === privacyOptions.PUBLIC,
          anonymous: privacyLocal === privacyOptions.ANONYMOUS,
          private: privacyLocal === privacyOptions.PRIVATE,
          isPromoting: promotionLocal && privacyLocal !== privacyOptions.PRIVATE,
        },
      },
    });
    await refetchGetChallengesResponses();
  };

  const PostHeaderComponent = () => (
    <ListHeaderComponent
      mappedData={mappedData}
      // handleSetMyResponse={handleSetMyResponse}
      saveResponse={saveResponse}
      isResponding={isResponding}
      isLargeScreen={isLargeScreen}
      navigation={navigation}
      handleSetResponsesVisilibity={handleSetResponsesVisilibity}
      responsesVisilibity={responsesVisilibity}
      currentUser={currentUser}
      setErrors={setErrors}
      errors={errors}
    />
  );

  const onStartTypingPress = () => {
    if (!currentUser) {
      return navigation.navigate('login');
    }
    navigation.navigate('respond', {
      mappedData: mappedData,
      saveResponse: saveResponse,
      // saveOnPress: saveOnPress,
    });
  };

  if (challengeResponses.length === 0) {
    return <NotFound errorMessage="Prompt not found." navigation={navigation} />;
  }

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' && (
        <Helmet>
          <title>{mappedData?.title}</title>
          <meta name="description" content={mappedData?.content} />
        </Helmet>
      )}
      {isLargeScreen && <PainterImage />}
      <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }} keyboardVerticalOffset={70}>
        <FlatList
          data={(responsesVisilibity && mappedData?.responses) || []}
          ListHeaderComponent={<PostHeaderComponent />}
          renderItem={({ item }) => (
            <PromptCard
              {...item}
              private={item.private}
              refetch={refetchGetChallengesResponses}
              currentUser={currentUser}
              saveComment={saveComment}
            />
          )}
          keyExtractor={({ id }) => id}
          contentContainerStyle={{
            paddingVertical: 40,
            paddingHorizontal: 20,
            paddingBottom: isLargeScreen ? 40 : 100,
          }}
        />
      </KeyboardAvoidingView>
      {!isLargeScreen && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: Colors.white,
            height: 80,
            padding: 20,
            alignItems: 'center',
          }}>
          <RoundButton title="Start typing..." onPress={onStartTypingPress} />
        </View>
      )}
      <SettingsModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        navigation={navigation}
        privacyOptions={privacyOptions}
        privacy={privacy}
        setPrivacy={setPrivacy}
        promotion={promotion}
        setPromotion={setPromotion}
        modalTitle="Response Settings"
        onPublish={saveResponse}
        myResponse={modalMyResponse}
        setModalMyResponse={setModalMyResponse}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.backgroundGrey,
  },
  link: {
    color: 'blue',
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 16,
  },
  text: {
    alignItems: 'center',
    fontSize: 24,
    marginBottom: 24,
  },
});
export default Responses;

export const PainterImage = () => {
  return (
    <>
      <Image
        source={require('../../assets/images/icons/painter.png')}
        style={{
          width: 301,
          height: 219,
          position: 'absolute',
          right: '3%',
          top: 150,
          zIndex: -998,
        }}
      />
    </>
  );
};
