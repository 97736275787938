// import { Platform } from 'react-native';

// let DatePicker = null;

// if (Platform.OS === 'web') {
//   DatePicker = require('./WebDatePicker').default;
// } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
//   DatePicker = require('./RNDatePicker').default;
// }

import DayPicker from './DayPicker';

export default DayPicker;
