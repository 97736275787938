import React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';
const SingleLineForm = ({ FormElements, children, contentContainerStyle }) => {
  return (
    // <View style={{}}>
    <KeyboardAvoidingView
      keyboardVerticalOffset={150}
      behavior={'padding'}
      style={{ paddingBottom: 0, flex: 1 }}>
      <ScrollView contentContainerStyle={contentContainerStyle}>{children}</ScrollView>
    </KeyboardAvoidingView>
    // </View>
  );
};

export default SingleLineForm;
