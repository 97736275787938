import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const percent = Dimensions.get('window').width / 100;
const BASE_SPACING = 8;

export default {
  window: {
    width,
    height,
    percent,
  },
  isSmallDevice: width < 375,
  grid: {
    base: BASE_SPACING,
    double: BASE_SPACING * 2,
    triple: BASE_SPACING * 3,
  },
};
