import React from 'react';
import { Text } from 'react-native';
import { BrandText } from '../../components/StyledText';

import Colors from '../../constants/Colors';

const generateNotificationTextAndColorUsernameHelper = (body, read) => {
  let result = body.match(/@\w+/);
  let bodyText;
  let parts = result ? body.split(result[0]) : body;

  if (result) {
    parts = [
      parts[0],
      <Text
        key={result[0]}
        style={{
          color: Colors.blue,
        }}>
        {result[0]}
      </Text>,
      parts[1],
    ];
  }

  bodyText = (
    <BrandText
      style={{
        lineHeight: 20,
        marginLeft: 10,
        flexShrink: 1,
        fontWeight: read ? 'normal' : 'bold',
      }}>
      {parts}
    </BrandText>
  );

  return bodyText;
};

export default generateNotificationTextAndColorUsernameHelper;
