import React from 'react';
import { View, Linking } from 'react-native';

import Colors from '../../constants/Colors';
import styles from './styles';
// import { client, mixpanelanalytics } from '../../lib/apolloClient';
import { PageHeaderText } from '../../components/StyledText';
import { FlatButton } from '../../components/StyledButton';

const ListItem = props => (
  <FlatButton
    {...props}
    style={styles.listItem}
    imageSource={require('../../assets/images/icons/up.png')}
    imageRightSide
    textStyle={{ width: '100%', marginHorizontal: 30 }}
    imageStyle={{
      transform: [
        { rotate: '90deg' },
      ] /* change the deg (degree of rotation) between 0deg, 360deg*/,
    }}
  />
);

const SettingsScreen = ({ navigation }) => {
  return (
    <View style={{ backgroundColor: Colors.backgroundGrey, flex: 1 }}>
      <View style={styles.container}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: Colors.white,
          }}>
          <PageHeaderText style={[styles.title, { marginLeft: 15 }]}>Help & Support</PageHeaderText>

          <ListItem
            title="Contact Us"
            onPress={() => {
              Linking.openURL('mailto:arrygoo@gmail.com');
            }}
          />
          <ListItem
            title="Bug report or feature request"
            onPress={() => {
              Linking.openURL('https://baobab.canny.io');
            }}
          />
          <ListItem
            title="Feedback Questionnaire"
            onPress={() => {
              Linking.openURL('https://goo.gl/forms/rkHnUE7nvPpsyfod2');
            }}
          />
          {/* <ListItem
            title="Privacy Policy"
            onPress={() => {
              Linking.openURL('https://baobab.ooo/privacy_policy.html');
            }}
          />
          <ListItem
            title="Terms of Use"
            onPress={() => {
              Linking.openURL('https://baobab.ooo/terms_and_conditions.html');
            }}
          /> */}
        </View>
      </View>
    </View>
  );
};

export default SettingsScreen;
