import React from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';

import styles from './styles';
import { currentUserQuery } from '../../api/graphql/queries';
import { setPushEnabledForPromotedPromptsMutation } from '../../api/graphql/mutations';
import { PageHeaderText, TitleText } from '../../components/StyledText';
import { RoundButton, BackButton } from '../../components/StyledButton';
import SwitchItem from '../../components/SwitchItem';
import useWindowDimensions from '../../lib/useWindowDimensions';
import LoadingIndicator from '../../components/LoadingIndicator';

import { useToastContext } from '../../lib/ToastContext';

import {
  getPushNotificaitonToken,
  setPushNotificationToken,
} from '../../lib/pushNotificationTokenHelpers';

const SettingsScreen = ({ navigation }) => {
  const { isLargeScreen } = useWindowDimensions();
  const { data: currentUserData, loading, networkStatus } = useQuery(currentUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (!currentUserData) {
        return;
      }

      if (pushEnabled !== !!currentUserData?.currentUser?.pushEnabled) {
        setPushEnabled(!!currentUserData?.currentUser?.pushEnabled);
        setPushEnabledForPromotedPrompts(
          currentUserData?.currentUser?.pushEnabledForPromotedPrompts
        );
      }
    },
  });

  const currentUser = currentUserData?.currentUser;

  const [pushEnabled, setPushEnabled] = React.useState(!!currentUserData?.currentUser?.pushEnabled);
  const [pushEnabledForPromotedPrompts, setPushEnabledForPromotedPrompts] = React.useState(
    currentUserData?.currentUser?.pushEnabledForPromotedPrompts
  );
  const [runSetPushTokenForPromotedPrompts] = useMutation(setPushEnabledForPromotedPromptsMutation);

  const [pushHandlerIsRunning, setPushHandlerIsRunning] = React.useState(false);

  const { show } = useToastContext();

  if (loading || networkStatus === 4) {
    return <LoadingIndicator />;
  }

  const handlePushNotificationSettingsChange = async () => {
    let token = '';
    if (pushEnabled) {
      token = await getPushNotificaitonToken();
    }

    try {
      setPushHandlerIsRunning(true);
      await setPushNotificationToken(token);
      await runSetPushTokenForPromotedPrompts({
        variables: { pushEnabledForPromotedPrompts: pushEnabledForPromotedPrompts && pushEnabled },
      });
      show('Settings are updated.');
      setPushHandlerIsRunning(false);
    } catch (e) {
      console.error(e.message);
      show('Something went wrong, please try again later.');
    }
  };

  return (
    <View style={[styles.container]}>
      <View style={styles.modalHeader}>
        <BackButton
          navigation={navigation}
          isLargeScreen={isLargeScreen}
          style={styles.backButtonStyle}
        />
      </View>
      <View style={styles.modalTitle}>
        <PageHeaderText>Settings</PageHeaderText>
      </View>

      <View style={styles.section}>
        <TitleText style={styles.titleStyle}>Push Notifications</TitleText>
        <SwitchItem title="Enable" itemValue={pushEnabled} setItemValue={setPushEnabled} divider />

        <SwitchItem
          disabled={!pushEnabled}
          title="Send push notification when a cool prompt is posted"
          itemValue={pushEnabled ? pushEnabledForPromotedPrompts : false}
          setItemValue={setPushEnabledForPromotedPrompts}
          divider
        />
      </View>

      <View style={styles.buttonContainer}>
        <RoundButton
          style={styles.buttonStyle}
          important
          title="Save"
          onPress={handlePushNotificationSettingsChange}
          disabled={!currentUser || pushHandlerIsRunning}
        />
      </View>
    </View>
  );
};

export default SettingsScreen;
