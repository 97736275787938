import React, { useState } from 'react';
import { TextInput, View, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';
import { BrandText, FormLabel } from './StyledText';
import { debounce } from 'lodash';
export const FormInput = ({
  reference,
  secureTextEntry,
  label,
  onChange,
  onSubmitEditing,
  value,
  returnKeyType,
  errorMessage,
  placeholder,
  disabled,
  onChangeText,
  multiline,
  wordlimit,
}) => {
  const [editStarted, setEditedStarted] = useState(false);
  const noop = () => {};
  const debouncedOnChangeText = onChangeText ? debounce(onChangeText, 500) : noop;
  const handleOnChangeText = newText => {
    debouncedOnChangeText(newText);
    if (!editStarted) {
      setEditedStarted(true);
    }
  };

  return (
    <View style={styles.textInputWrapper}>
      <FormLabel>{label}</FormLabel>
      <TextInput
        style={[
          styles.textInput,
          errorMessage && { borderColor: Colors.orange },
          disabled && { backgroundColor: Colors.lightGrey },
          multiline && { minHeight: 100 },
        ]}
        disabled={disabled}
        defaultValue={value}
        onChange={onChange}
        onSubmitEditing={onSubmitEditing}
        // value={value}
        returnKeyType={returnKeyType}
        secureTextEntry={secureTextEntry}
        ref={reference}
        placeholder={placeholder}
        onChangeText={handleOnChangeText}
        multiline={multiline}
        {...(!editStarted && { value })}
      />
      <FormError errorMessage={errorMessage} />
      {wordlimit && (
        <BrandText style={{ color: Colors.textGrey, alignSelf: 'flex-end' }}>
          {value ? wordlimit - value.length : wordlimit}
        </BrandText>
      )}
    </View>
  );
};

export const FormError = ({ errorMessage, style, textStyle }) => (
  <View style={[styles.formErrorMessage, { marginTop: 3 }, style]}>
    <BrandText style={[styles.formErrorText, textStyle]}>{errorMessage || ' '}</BrandText>
  </View>
);

export const FormSuccess = ({ successMessage, style, textStyle }) => (
  <View style={[{ marginTop: 3 }, style]}>
    <BrandText style={[styles.formSuccessText, textStyle]}>{successMessage || ' '}</BrandText>
  </View>
);

const styles = StyleSheet.create({
  textInput: {
    // marginLeft: 10,
    borderWidth: 1,
    borderColor: Colors.textInputBorder,
    padding: 10,
    borderRadius: 5,
    width: '100%',
    // padding: 10,
    // textAlign: 'center',
    fontFamily: 'avenirnext-bold',
    height: 40,
    // alignSelf: 'center',
    // ...Platform.select({
    //   android: {
    //     fontSize: 18
    //   }
    // })
  },
  textInputWrapper: {
    alignSelf: 'stretch',
  },
  fieldErrorMessage: {
    paddingVertical: 4,
  },
  fieldErrorText: {
    fontSize: 10,
    color: Colors.red,
  },
  formErrorMessage: {
    // padding: 8,
    // backgroundColor: Colors.lightGrey
  },
  formErrorText: {
    fontSize: 14,
    color: Colors.orange,
    textAlign: 'right',
  },
  formSuccessText: {
    fontSize: 14,
    color: Colors.green,
    textAlign: 'right',
  },
});
