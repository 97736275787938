import React, { useState } from 'react';
import {
  TextInput,
  View,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Platform,
  SafeAreaView,
  Keyboard,
} from 'react-native';
// import Modal from 'modal-enhanced-react-native-web';
import { StackActions } from 'react-navigation';
// import DateTimePicker from '@react-native-community/datetimepicker';
import { useMutation, useQuery } from '@apollo/client';
import Colors from '../../constants/Colors';
import { createChallengeMutation } from '../../api/graphql/mutations';
import {
  currentUserQuery,
  getMyChallengesQuery,
  myPrivateChallengesQuery,
  getChallengesQuery,
} from '../../api/graphql/queries';
import { PageHeaderText } from '../../components/StyledText';
import { FlatButton, BackButton } from '../../components/StyledButton';
import useWindowDimensions from '../../lib/useWindowDimensions';
import ErrorView from '../../components/ErrorView';
import NewChallengeSettingsModal from '../../components/SettingsModal';
import { isSameDay, daysFromNowOn } from '../../lib/utils';

// const Option = ({ label, value }) =>
//   createElement('option', {
//     children: label,
//     value,
//   });

// import redirect from '../lib/web/redirect';
// import * as SecureStore from 'expo-secure-store';
// const StyledButton = Button;
// const StyledButtonText = Text;

// FIXME: Turn the form fields into some kind of validated components
const tagsRecommended = ['autobiographical', 'lyrics', 'poetry', 'short_fiction', 'short_story'];

const NewChallenge = ({ navigation }) => {
  const {} = useQuery(currentUserQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const { isLargeScreen } = useWindowDimensions();

  const [createChallenge] = useMutation(createChallengeMutation, {
    onError: err => {
      console.log('----err', err);
      // const errorMessage = err?.graphQLErrors?.[0]?.message;
    },
    refetchQueries: [
      { query: getMyChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
      { query: myPrivateChallengesQuery, variables: { limit: 30, offset: 0, sort: 'hotRank' } },
      {
        query: getChallengesQuery,
        variables: { limit: 30, offset: 0, sort: 'createdAt', isScheduled: true },
      },
    ],
  });

  const [modalVisible, setModalVisible] = useState(false);
  // Modal state: privaciy, promotion, tags - used inside createChallenge mutation
  const privacyOptions = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    ANONYMOUS: 'anonymous',
  };
  const [privacy, setPrivacy] = useState(privacyOptions.PUBLIC);
  const [promotion, setPromotion] = useState(true);
  const [tag, setTag] = useState('');

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [schedule, setSchedule] = useState(false);
  const [errors, setErrors] = useState({});

  const submitChallenge = async ({ modalSelections } = {}) => {
    const privacyLocal = modalSelections ? modalSelections.privacy : privacy;
    const tagLocal = modalSelections ? modalSelections.tag : tag;
    const promotionLocal = modalSelections ? modalSelections.promotion : promotion;
    // console.log(title, content, schedule, privacy, tag);
    // console.log('submitChallenge');
    // console.log('privacy', privacyLocal);
    // console.log('promotion', promotionLocal);
    // console.log('tag', tagLocal);
    // console.log('modalSelections', modalSelections);
    // console.log('submitChallenge');

    validateInputs();
    if (!isInputsValid()) {
      return;
    }

    const createChallengeResult = await createChallenge({
      notifyOnNetworkStatusChange: true,
      variables: {
        challenge: {
          title,
          publishInXDays: daysFromNowOn(schedule),
          steps: [
            {
              order: 0,
              content,
            },
          ],
          public: privacyLocal === privacyOptions.PUBLIC,
          private: privacyLocal === privacyOptions.PRIVATE,
          anonymous: privacyLocal === privacyOptions.ANONYMOUS,
          tag: tagLocal,
          isPromoting: promotionLocal && privacyLocal !== privacyOptions.PRIVATE,
        },
      },
    });
    console.log('----', createChallengeResult, createChallengeResult?.data?.createChallenge?.id);
    const pushAction = StackActions.replace({
      routeName: 'responses',
      params: { id: createChallengeResult?.data?.createChallenge?.id },
    });
    navigation.dispatch(pushAction);
  };

  const validateInputs = () => {
    let newErrors = {};
    if (title.length < 2) {
      newErrors.title = 'Woops, you forgot to enter a title.';
    }
    if (title.length > 80) {
      newErrors.title = 'Woops, your title is too long.';
    }
    if (content.length < 5 && !newErrors.title) {
      newErrors.content = 'Woops, you forgot to describe the prompt.';
    }
    if (content.length > 3000 && !newErrors.title) {
      newErrors.content = 'Woops, prompt is too long.';
    }

    setErrors(newErrors);
  };

  const isInputsValid = () => {
    return (
      title?.length >= 2 && title?.length <= 80 && content?.length >= 5 && content?.length <= 3000
    );
  };

  // const MIN_HEIGHT = 500;
  // const [pageHeight, setHeight] = useState(MIN_HEIGHT);
  // const [steps, setSteps] = useState([
  //   {
  //     order: 0,
  //     content: '',
  //   },
  // ]);

  const handleSetTitle = event => {
    setTitle(event?.nativeEvent?.text || '');
  };

  const handleSetContent = event => {
    setContent(event?.nativeEvent?.text || '');
  };

  // const handleSetSteps = (stepText, order) => {
  //   setSteps(prevState => {
  //     const newSteps = prevState.steps;
  //     newSteps[order].content = stepText;
  //     return {
  //       steps: newSteps,
  //     };
  //   });
  // };

  // const onContentSizeChange = event => {
  //   const {
  //     nativeEvent: {
  //       contentSize: { height },
  //     },
  //   } = event;
  //   setHeight(Math.max(MIN_HEIGHT, height));
  // };

  // const onSetSchedulePressed = () => {
  // setModal({
  //   title: 'Schedule post',
  //   message: "You're about report this post as inappropriate.",
  //   ContentComponent: DatePicker,
  //   buttons: {
  //     ok: {
  //       title: 'Save',
  //       action: async () => {},
  //     },
  //   },
  // });
  //   setModal({
  //     title: 'Coming soon',
  //     message:
  //       "Scheduling posts isn't available for all users yet, please check back in a few weeks :).",
  //     buttons: {
  //       ok: {
  //         title: 'OK',
  //         action: async () => {},
  //       },
  //     },
  //   });
  // };

  const onPressContainer = Platform.OS === 'web' ? () => {} : Keyboard.dismiss;

  const isScheduleSetAndNotToday =
    schedule && !isSameDay(schedule, new Date()) && schedule - new Date() > 0;

  return (
    <View
      style={[
        { flex: 1, paddingHorizontal: 15 },
        {
          backgroundColor: Colors.backgroundGrey,
          paddingHorizontal: '10%',
        },
      ]}>
      <TouchableWithoutFeedback onPress={onPressContainer}>
        <SafeAreaView
          style={[
            {
              flex: 1,
              // paddingTop: Platform.OS === 'android' ? 25 : 0,
            },
          ]}>
          <>
            <BackButton navigation={navigation} isLargeScreen={isLargeScreen} />

            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <PageHeaderText style={{ fontSize: 18, fontWeight: 'bold' }}>
                New Prompt
              </PageHeaderText>

              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row' }}>
                  <FlatButton
                    title="Publish"
                    onPress={submitChallenge}
                    style={{
                      borderRightWidth: 1,
                      borderColor: Colors.lighterOranger,
                      backgroundColor: Colors.orange,
                      borderBottomLeftRadius: 10,
                      borderTopLeftRadius: 10,
                      paddingHorizontal: 10,
                    }}
                    important
                  />
                  <FlatButton
                    titleForAnalytics="new challenge settings arrow"
                    onPress={() => {
                      setModalVisible(prev => !prev);
                    }}
                    style={{
                      borderRightWidth: 1,
                      backgroundColor:
                        tag ||
                        privacy !== privacyOptions.PUBLIC ||
                        !promotion ||
                        isScheduleSetAndNotToday
                          ? Colors.lighterOranger
                          : Colors.orange,
                      borderBottomRightRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    imageSource={require('../../assets/images/icons/up.png')}
                    imageStyle={{
                      width: 20,
                      tintColor: 'white',
                      ...(true && { transform: [{ rotate: '180deg' }] }),
                    }}
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <View style={{ width: '100%', borderBottomWidth: 0 }}>
                <TextInput
                  maxLength={30}
                  style={[
                    styles.title,
                    {
                      // opacity: title.length > 0 ? 1 : 0.75,
                      padding: 10,
                      backgroundColor: Colors.white,
                      fontSize: 22,
                    },
                  ]}
                  placeholderTextColor={Colors.promptText}
                  placeholder="Prompt Title..."
                  onChange={handleSetTitle}
                  // value={title}
                />
              </View>
            </View>

            <ErrorView msg={errors.title} />
          </>
          <KeyboardAvoidingView
            behavior="padding"
            style={{ flex: 1 }}
            keyboardVerticalOffset={70}
            onPress={Keyboard.dismiss}>
            <View style={{ flex: 1 }}>
              <TextInput
                placeholder={
                  'Prompt Description. e.g: Describe the standard life of a piece of chewing gum'
                }
                style={[
                  {
                    minHeight: '100%',
                  },
                  { backgroundColor: Colors.white, padding: 10, lineHeight: 20 },
                ]}
                multiline
                onChange={handleSetContent}
              />
              {/* <View style={{ flex: 1 }} /> */}
            </View>
            <ErrorView msg={errors.content} />
            <NewChallengeSettingsModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              navigation={navigation}
              privacyOptions={privacyOptions}
              tags={tagsRecommended}
              tag={tag}
              setTag={setTag}
              privacy={privacy}
              setPrivacy={setPrivacy}
              promotion={promotion}
              setPromotion={setPromotion}
              modalTitle="Prompt Settings"
              onPublish={submitChallenge}
              schedule={schedule}
              setSchedule={setSchedule}
            />
          </KeyboardAvoidingView>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = {
  title: {
    fontSize: 30,
    // color: Colors.green,
    marginVertical: 10,
  },
  placeholderTextColor: Colors.green,
  textarea: {
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 40,
    // paddingHorizontal: 20,
    borderColor: 'gray',
    borderWidth: 1,
    backgroundColor: '#FFF',
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
};

export default NewChallenge;
