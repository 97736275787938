import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { Platform, StatusBar, StyleSheet, SafeAreaView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { ApolloProvider } from '@apollo/client';
import { MenuProvider } from 'react-native-popup-menu';

import AppNavigator from './navigation/AppNavigator';
import { client } from './lib/apolloClient';
import ModalContext from './lib/context';
import NotificationModalContext from './lib/notificationModalContext';
import { ResponseModalContextProvider } from './lib/ResponseModalContext';
import { ToastContextProvider } from './lib/ToastContext';
import { AlertPopup, Toast } from './components/StyledPopup';

let Helmet;
if (Platform.OS === 'web') {
  Helmet = require('react-helmet').Helmet;
}

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [modalValue, setModal] = useState(null);
  const [notificationModal, setNotificationModal] = useState(null);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <SafeAreaView style={styles.container}>
        {Platform.OS === 'web' && (
          <Helmet>
            <title>Baobab</title>
            <meta
              name="description"
              content="Baobab is here to make the writing process easier. Baobab is a social platform which consists of writing, reading and responding to prompts in a community setting. Users can create astounding pieces of writing, using writing tools and social engagement!"
            />
          </Helmet>
        )}
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <ApolloProvider client={client}>
          <ModalContext.Provider value={[modalValue, setModal]}>
            <NotificationModalContext.Provider value={[notificationModal, setNotificationModal]}>
              <ToastContextProvider>
                <ResponseModalContextProvider>
                  <MenuProvider>
                    <AlertPopup context={ModalContext} />
                    <AlertPopup context={NotificationModalContext} />
                    <Toast />
                    <AppNavigator />
                  </MenuProvider>
                </ResponseModalContextProvider>
              </ToastContextProvider>
            </NotificationModalContext.Provider>
          </ModalContext.Provider>
        </ApolloProvider>
      </SafeAreaView>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/icons/flag.png'),
      require('./assets/images/icons/flame.png'), // TODO: All other images
    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'comfortaa-bold': require('./assets/fonts/Comfortaa-Bold.ttf'),
      montserrat: require('./assets/fonts/Montserrat-Regular.ttf'),
      'montserrat-bold': require('./assets/fonts/Montserrat-Bold.ttf'),
      avenirnext: require('./assets/fonts/AvenirNextLTPro-Regular.otf'),
      'avenirnext-bold': require('./assets/fonts/AvenirNextLTPro-Bold.otf'),
    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
