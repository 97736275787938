import React, { useContext } from 'react';
// import Modal from 'react-native-modal';
import { RoundButton, FlatButton } from './StyledButton';
import { BrandText, TitleText } from './StyledText';
import Colors from '../constants/Colors';
import { ScrollView, View, Platform, KeyboardAvoidingView, Text } from 'react-native';
import useWindowDimensions from '../lib/useWindowDimensions';
import { useToastContext } from '../lib/ToastContext';

const Modal =
  Platform.OS === 'web'
    ? require('modal-enhanced-react-native-web').default
    : require('react-native-modal').default;

export const AlertPopup = ({ context }) => {
  const ctx = useContext(context);
  const { isLargeScreen } = useWindowDimensions();
  const [modalValue, setModal] = ctx;
  const message = modalValue?.message;
  const title = modalValue?.title;
  const okButtonTitle = modalValue?.buttons?.ok?.title || 'Ok';
  const okButtonAction = async () => {
    await modalValue?.buttons?.ok?.action();
    setModal(null);
  };
  const cancelButtonTitle = modalValue?.buttons?.cancel?.title || 'Cancel';
  const cancelButtonAction = async () => {
    await modalValue?.buttons?.cancel?.action();
    setModal(null);
  };
  const extraButtonTitle = modalValue?.buttons?.extra?.title || 'Extra';
  const extraButtonAction = async () => {
    await modalValue?.buttons?.extra?.action();
    setModal(null);
  };
  const EmptyComponent = () => <></>;
  const ContentComponent = modalValue?.ContentComponent || EmptyComponent;
  const isVisible = !!message;

  return (
    <Modal
      animationType="slide"
      transparent={Platform.OS === 'web'}
      visible={isVisible}
      isVisible={isVisible}
      onRequestClose={() => {
        setModal(null);
        //   Alert.alert('Modal has been closed.');
      }}
      // presentationStyle="formSheet"
      // coverScreen={false}
      hasBackdrop
      style={{ backgroundColor: 'rgba(0,0,0, 0.8)', margin: 0 }}
      onBackdropPress={() => setModal(null)}>
      <View
        style={{
          alignSelf: 'center',
          width: isLargeScreen ? '50%' : '100%',
          backgroundColor: Colors.white,
          paddingVertical: 40,
          paddingHorizontal: 10,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 15,
        }}>
        <TitleText>{title}</TitleText>
        <BrandText style={{ marginTop: 20, marginBottom: 5, paddingHorizontal: 15 }}>
          {message}
        </BrandText>
        <ContentComponent />

        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}>
          <FlatButton
            title={cancelButtonTitle}
            onPress={cancelButtonAction}
            style={{ marginTop: 15, marginLeft: 15 }}
            titleForAnalytics="Alert popup cancel button - context/setModal"
          />

          <RoundButton
            style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
            important
            title={okButtonTitle}
            onPress={okButtonAction}
          />

          {modalValue?.buttons?.extra && (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <RoundButton
                style={{
                  backgroundColor: Colors.blueGrey,
                  marginTop: 15,
                }}
                important
                title={extraButtonTitle}
                onPress={extraButtonAction}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

export const PopUp = props => {
  const { isLargeScreen } = useWindowDimensions();
  return (
    <Modal
      animationType="slide"
      transparent={Platform.OS === 'web'}
      visible={props.modalVisible}
      onRequestClose={() => {
        //   Alert.alert('Modal has been closed.');
        props.setModalVisible(false);
      }}
      presentationStyle="formSheet"
      coverScreen={true}
      hasBackdrop
      style={{ backgroundColor: 'rgba(0,0,0, 0.8)', margin: 0 }}
      onBackdropPress={() => {
        console.log('----- CLOSING');
        props.setModalVisible(false);
      }}
      {...props}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'android' ? 'height' : 'padding'}
        keyboardVerticalOffset={0}
        style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            alignSelf: 'center',
            width: isLargeScreen ? '60%' : '100%',
            backgroundColor: Colors.white,
            paddingVertical: isLargeScreen ? 30 : 10,
            paddingHorizontal: isLargeScreen ? 40 : 10,
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}>
          {props.children}
        </ScrollView>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export const Toast = props => {
  const { visible, msg } = useToastContext();
  return (
    visible && (
      <View
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            backgroundColor: Colors.darkGrey,
            width: 300,
            margin: 'auto',
            paddingVertical: 20,
            paddingHorizontal: 30,
            borderRadius: 10,
          }}>
          <Text style={{ color: Colors.white, flexWrap: 'wrap', width: '100%' }}>{msg}</Text>
        </View>
      </View>
    )
  );
};
