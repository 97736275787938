// import { Platform, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

export default {
  actionButton: {
    padding: 10,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.green,
    // marginTop: 20
  },
  titleCardItem: {
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  styledTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  textFooter: {
    marginRight: 30,
    color: Colors.textGrey,
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 50,
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
  privateCard: {
    backgroundColor: Colors.white,
    borderRadius: 50,
    marginBottom: 20,
  },
  cardContent: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    borderWidth: 0,
  },
  privateResponseText: {
    fontSize: 15,
    marginBottom: 15,
    color: Colors.textGrey,
    lineHeight: 20,
  },
  privateResponseContainer: {
    borderBottomWidth: 0.4,
    borderBottomColor: Colors.textGrey,
    marginTop: 15,
    marginBottom: 15,
  },
  responseText: {
    lineHeight: 20,
  },
  styledAuthorName: {
    fontSize: 10,
    marginBottom: 5,
    color: Colors.textGrey,
  },
};
