/**
 * Helper for checking if scheduled day is today
 * Requires two dates object, so make sure objects are valid and not falsy values
 *
 * @param {Date} d1
 * @param {Date} d2
 * @returns {Boolean}
 */
export const isSameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const dateToYYYYMMDD = date => {
  const year = date.getFullYear();
  const month = +date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 9 ? '0' + month : month}-${day < 9 ? '0' + day : day}`;
};

/**
 * Helper for finding difference of today and schedule in days
 *
 * @param {Date|false} pickedDate - selected schedule date object
 * @returns {number} - difference of days from today and schedule (returns 0 if schedule false or previous day)
 */
export const daysFromNowOn = pickedDate => {
  if (!pickedDate) {
    return 0;
  }

  const today = new Date();
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const pickedUtcDate = Date.UTC(
    pickedDate.getFullYear(),
    pickedDate.getMonth(),
    pickedDate.getDate()
  );
  const todayUtc = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

  if (todayUtc >= pickedUtcDate) {
    return 0;
  }

  const diffDays = Math.floor((pickedUtcDate - todayUtc) / _MS_PER_DAY);

  return diffDays;
};
