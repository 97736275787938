import React from 'react';
import { createSwitchNavigator } from 'react-navigation';
import { createBrowserApp } from '@react-navigation/web';
import { PageHit } from 'expo-analytics';

import MainTabNavigator from './MainTabNavigator';
import { googleanalytics } from '../lib/apolloClient';
import getActiveRouteName from '../lib/getActiveRouteName';
import NavigationService from './NavigationService';

const AppNavigator = createSwitchNavigator({
  // You could add another route here for authentication.
  // Read more at https://reactnavigation.org/docs/en/auth-flow.html
  b: {
    screen: MainTabNavigator,
    path: '',
  },
});

const AppContainer = createBrowserApp(AppNavigator, { history: 'hash' });

let lastScreenChange = Date.now();
export default () => (
  <AppContainer
    onNavigationStateChange={(prevState, currentState, action) => {
      const currentRouteName = getActiveRouteName(currentState);
      const previousRouteName = getActiveRouteName(prevState);

      if (previousRouteName !== currentRouteName) {
        // the line below uses the @react-native-firebase/analytics tracker
        // change the tracker here to use other Mobile analytics SDK.
        const timeSpent = Date.now() - lastScreenChange;
        lastScreenChange = Date.now();
        googleanalytics.hit(new PageHit(currentRouteName));
      }
    }}
    ref={navigatorRef => {
      NavigationService.setTopLevelNavigator(navigatorRef);
    }}
  />
);
