// import { PhoneNumberUtil } from 'google-libphonenumber';

export default {
  validateEntered(value) {
    return value && typeof value === 'string' && value.trim().length >= 0;
  },

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email && typeof email === 'string' && email.trim().length > 0 && re.test(email);
  },

  validatePassword(password) {
    return password && typeof password === 'string' && password.length >= 1;
  },

  // validatePhoneNumber(phoneNumber) {
  //   let valid = false;

  //   try {
  //     const phoneUtil = PhoneNumberUtil.getInstance();
  //     const parsedPhonNumber = phoneUtil.parse(phoneNumber, 'US');
  //     // TODO: Parse for all supported countries and validate
  //     // CA - Canada
  //     // DE - Germany
  //     // UK - United Kingdom
  //     // CH - Switzerland
  //     // FR - France

  //     valid = phoneUtil.isValidNumber(parsedPhonNumber);
  //   } catch (error) {
  //     // it's okay
  //   }

  //   return valid;
  // }
};
