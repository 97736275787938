import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
export default {
  container: {
    flex: 1,
    paddingTop: '5%',
    paddingLeft: '8%',
    paddingRight: '8%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    alignSelf: 'center',
    marginBottom: 7,
  },
  mb: {
    marginBottom: 15,
  },
  imageContainer: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 30 * Layout.window.percent,
    height: 30 * Layout.window.percent,
    backgroundColor: Colors.darkGrey,
  },
  title: {
    fontSize: 24,
    color: Colors.green,
    marginBottom: 15,
    marginTop: 20,
    fontFamily: 'avenirnext-bold',
  },
  buttonContainer: { flex: 1, flexDirection: 'row', justifyContent: 'center' },
  buttonStyles: { height: 45, width: 140, marginBottom: 10, marginTop: 20 },
  listItem: {
    borderColor: Colors.lightGrey,
    borderBottomWidth: 0.1,
    paddingVertical: 10,
    flex: 1,
    width: '100%',
  },
  switchView: {
    paddingHorizontal: 30,
    paddingVertical: 20,
    width: '100%',
  },
};
