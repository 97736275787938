import { Platform, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';

export default StyleSheet.create({
  container: {
    ...(Platform.OS === 'web' && { flex: 1 }),
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 30 * Layout.window.percent,
    height: 30 * Layout.window.percent,
    backgroundColor: Colors.darkGrey,
  },
  textInput: {
    // marginLeft: 10,
    borderWidth: 1,
    borderColor: Colors.textInputBorder,
    padding: 10,
    borderRadius: 5,
    width: '100%',
    // padding: 10,
    // textAlign: 'center',
    fontFamily: 'avenirnext-bold',
    height: 40,
    // alignSelf: 'center',
    // ...Platform.select({
    //   android: {
    //     fontSize: 18
    //   }
    // })
  },
  textInputWrapper: {
    width: '100%',
  },
  fieldErrorMessage: {
    paddingVertical: 4,
  },
  genericErrorMessage: {
    paddingTop: 15,
  },
  fieldErrorText: {
    fontSize: 10,
    color: Colors.red,
  },
  formErrorMessage: {
    // padding: 8,
    // backgroundColor: Colors.lightGrey
  },
  formErrorText: {
    fontSize: 14,
    color: Colors.orange,
    textAlign: 'right',
  },
  actionButton: {
    // width: Layout.window.width * 0.9,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: Colors.blue,
    marginTop: 20,
  },
  actionText: {
    fontSize: 16,
    fontWeight: '800',
    fontFamily: 'avenirnext',
    color: 'white',
  },
  welcomeTextContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dontHaveAnAccountText: {
    fontSize: 14,
  },
  signUpText: {
    fontSize: 15,
    fontFamily: 'avenirnext-bold',
  },
  title: {
    fontSize: 25,
    fontFamily: 'avenirnext',
    marginVertical: 20,
  },
  signupContainer: {
    marginTop: 50,
    flexDirection: 'column',
    alignItems: 'center',
  },
});
