import Colors from '../../constants/Colors';

export default {
  challengeTitle: {
    fontSize: 24,
    color: Colors.green,
    marginBottom: 15,
    marginTop: 20,
    fontFamily: 'avenirnext-bold',
    // fontWeight: '900'

    // textAlign: 'center'
  },
  attemptCell: {
    marginTop: 5,
    // borderTopWidth: 1,
    borderColor: Colors.lightGrey,
  },
  notificationsList: {
    // borderBottomWidth: 1,
    borderColor: Colors.lightGrey,
    paddingVertical: 50,
  },
  loading: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
};
