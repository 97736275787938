import React from 'react';
import { StyleSheet, View, Switch, Platform } from 'react-native';

import Colors from '../constants/Colors';
import { FormLabel } from './StyledText';

const SwitchItem = ({ title, itemValue, setItemValue, divider = false, disabled = false }) => (
  <View style={[styles.sectionItemView, divider ? styles.divider : {}]}>
    <View style={styles.sectionItemLabelView}>
      <FormLabel style={styles.sectionItemLabel}>{title}</FormLabel>
    </View>
    <Switch
      disabled={disabled}
      onValueChange={value => setItemValue(value)}
      value={itemValue}
      thumbColor={Colors.orange}
      activeThumbColor={Colors.orange}
      activeTrackColor={Platform.OS === 'ios' ? Colors.orange : Colors.brightOrange}
      trackColor={
        Platform.OS === 'ios'
          ? {
              false: Colors.brightBlueGrey,
              true: Colors.brightOrange,
            }
          : { false: Colors.brightOrange, true: Colors.lighterOranger }
      }
    />
  </View>
);

const styles = StyleSheet.create({
  sectionItemView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingVertical: 20,
    flex: 1,
  },
  sectionItemLabelView: { width: '80%' },
  sectionItemLabel: { flexWrap: 'wrap' },
  divider: { borderColor: Colors.lightGrey, borderBottomWidth: 0.1 },
});

export default SwitchItem;
