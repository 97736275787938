import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useApolloClient } from '@apollo/client';
import cookie from 'cookie';
import * as SecureStore from 'expo-secure-store';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import Colors from '../constants/Colors';
import { RoundButton, FlatButton } from './StyledButton';

const signOut = async ({ apolloClient, navigation }) => {
  if (Platform.OS === 'web') {
    document.cookie = cookie.serialize('TOKEN_KEY', '', {
      maxAge: -1, // Expire the cookie immediately
    });
  } else {
    await SecureStore.deleteItemAsync('TOKEN_KEY');
  }

  setTimeout(() => {
    apolloClient.resetStore();
    navigation.navigate('prompts');
  }, 400);
};

const SignOutButton = ({ navigation, title, isLink = false, iconStyle }) => {
  const apolloClient = useApolloClient();

  const isButtonStyles = isLink ? {} : styles.isButtonStyles;
  return (
    <View style={[styles.iconView, isButtonStyles]}>
      {isLink ? (
        <FlatButton
          title={title ? title : 'Sign Out'}
          onPress={() => {
            signOut({ apolloClient, navigation });
            navigation.closeDrawer();
          }}
          textStyle={styles.textStyle}
          iconComponent={
            <MaterialCommunityIcons
              name="logout"
              size={24}
              style={[styles.defaultIconStyle, iconStyle]}
            />
          }
        />
      ) : (
        <RoundButton
          danger
          title={title ? title : 'Sign Out'}
          style={styles.rountButtonStyles}
          onPress={() => signOut({ apolloClient, navigation })}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  rountButtonStyles: { marginVertical: 20, alignSelf: 'center' },
  isButtonStyles: { justifyContent: 'center' },
  iconView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textStyle: {
    color: Colors.black,
    fontWeight: 'bold',
  },
  defaultIconStyle: {
    marginRight: 12,
  },
});

export default SignOutButton;
