import { Platform, Share } from 'react-native';
import copy from 'copy-to-clipboard';
import React, { useContext } from 'react';

import ModalContext from '../lib/context';
import { useToastContext } from '../lib/ToastContext';
import { FlatButton } from '../components/StyledButton';

const ShareButton = ({ partialUrl, style, title = 'Share your work' }) => {
  const url = `https://baobab.ooo/#/${partialUrl}`;
  const ctx = useContext(ModalContext);
  const { show } = useToastContext();
  const { setModal } = ctx;

  const shareUrl = url => {
    if (Platform.OS === 'web') {
      setModal({
        title,
        message: `${url}`,
        buttons: {
          ok: {
            title: 'Copy to clipboard',
            action: async () => {
              copy(url, {
                // debug: true,
                message: 'Copied',
              });
              show('Copied!', 400);
            },
          },
        },
      });
    } else {
      // TODO: Explore more sharing options
      const shareContent = Platform.select({
        ios: {
          url,
        },
        android: { message: url },
      });
      Share.share(shareContent);
    }
  };

  return (
    <FlatButton
      style={style}
      titleForAnalytics="share icon"
      imageSource={require('../assets/images/icons/share.png')}
      imageStyle={{ width: 17, height: 20, marginLeft: 10, resizeMode: 'contain' }}
      hitSlop={{ top: 15, bottom: 15, left: 10, right: 10 }}
      onPress={() => shareUrl(url)}
      testID={`Button.Share.${partialUrl}`}
    />
  );
};

export default ShareButton;
