import React from 'react';
import { Linking } from 'expo';
import { Text, Platform } from 'react-native';

const Anchor = ({ href = '', target = '_blank' }) => {
  let localHref = href ? href.toLowerCase() : '';

  if (!/^https?:\/\//i.test(href)) {
    localHref = 'http://' + href;
  }

  const handlePress = () => {
    if (Platform.OS === 'web') {
      return window.open(localHref, target);
    } else {
      return Linking.openURL(localHref);
    }
  };

  return <Text onPress={handlePress}>{href}</Text>;
};

export default Anchor;
