import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

export default function ErrorView({ msg, style, textStyle }) {
  return (
    <>
      {msg && msg?.length > 0 ? (
        <View style={[style ? style : {}, styles.container]}>
          <Text style={[styles.text, textStyle ? textStyle : {}]}>{msg}</Text>
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    marginBottom: 5,
  },
  text: {
    color: Colors.red,
  },
});
