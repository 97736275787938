import * as SecureStore from 'expo-secure-store';
// import ApolloClient from 'apollo-boost';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { Observable } from 'apollo-link';
import { Platform } from 'react-native';
import cookie from 'cookie';
import apolloLogger from 'apollo-link-logger';

import { Analytics, PageHit, Event } from 'expo-analytics';
// import ExpoMixpanelAnalytics from 'expo-mixpanel-analytics';
// import Constants from 'expo-constants';

const currentEnv = 'production'; // __DEV__ ? 'staging' : 'production'; // TODO: Remove before pushing to staging

const credentialsForAllEnvs = {
  local: {
    googleanalytics: '',
    mixpanel: '',
    serveruri: 'http://localhost:3000/graphql',
  },
  dev: {
    googleanalytics: '',
    mixpanel: '',
    serveruri: 'http://192.168.2.15:3000/graphql',
  },
  staging: {
    googleanalytics: 'UA-132353821-1',
    mixpanel: 'adf9aed49e91c9376c93708dca88278d',
    serveruri: 'https://baobab-staging.herokuapp.com/graphql',
  },
  production: {
    googleanalytics: 'UA-132381263-1',
    mixpanel: 'e6955ae5acfe5a7bb7a71c8388eaf68f',
    serveruri: 'https://baobab-production.herokuapp.com/graphql',
  },
};

let credentials = {};

// const { releaseChannel } = Constants.manifest;
// if (releaseChannel === undefined) {
if (currentEnv === 'staging') {
  credentials = { ...credentialsForAllEnvs.staging };
} else if (currentEnv === 'production') {
  credentials = { ...credentialsForAllEnvs.production };
} else if (currentEnv === 'local') {
  credentials = { ...credentialsForAllEnvs.local };
} else {
  credentials = { ...credentialsForAllEnvs.dev };
}
// }
// else if (releaseChannel.indexOf('staging') !== -1) {
//   credentials = { ...credentialsForAllEnvs.staging };
// } else if (releaseChannel.indexOf('prod') !== -1) {
//   credentials = { ...credentialsForAllEnvs.production };
// }

export const googleanalytics = new Analytics(credentials.googleanalytics);
googleanalytics
  .hit(new PageHit('Initialized'))
  .then(() => console.log('success'))
  .catch(e => console.log(e.message));

// export const mixpanelanalytics = new ExpoMixpanelAnalytics(credentials.mixpanel);

// mixpanelanalytics.track('Initalized', { Tested: 'Worked' });

function parseCookies(req, options = {}) {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie, options);
}

const request = async operation => {
  try {
    let token;
    if (Platform.OS === 'web') {
      token = parseCookies().TOKEN_KEY;
    } else {
      token = await SecureStore.getItemAsync('TOKEN_KEY');
    }
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'meteor-login-token': token,
      },
    });

    const operationWhitelist = ['mutation', 'query'];
    const operationType = operation?.query?.definitions?.[0]?.operation;
    const shouldTrack = operationWhitelist?.includes(operationType);

    const { operationName } = operation;
    if (operationName && shouldTrack) {
      // only track mutations.
      console.log('analyrics', operationName);
      googleanalytics.event(new Event('Operations', operationType, operationName));
    }

    // const userId = await SecureStore.getItemAsync('userId');
    // mixpanelanalytics.identify(userId);
  } catch (error) {
    console.log('---errorerrorerror--', error);
  }
};

// const analyticsLink = opts => {
//   const operationWhitelist = ['mutation'];
//   return new ApolloLink((operation, forward) => {
//     const operationType = operation.query.definitions[0].operation;
//     const shouldTrack = operationWhitelist.includes(operationType);
//     return forward(operation).map(data => {
//       const { operationName } = operation;
//       if (operationName && shouldTrack) {
//         // only track mutations.
//         console.log('analyrics');
//         googleanalytics.event(new Event('Operation', operationName));

//         analytics.track(operationName, operation);
//       }
//       return data;
//     });
//   });
// };

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);

export const client = new ApolloClient({
  link: from([
    apolloLogger,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    new HttpLink({
      uri: credentials.serveruri,
    }),
  ]),
  //   connectToDevTools: true,
  // link: ApolloLink.from([
  //   // onError(({ graphQLErrors, networkError }) => {
  //   //   if (graphQLErrors)
  //   //     graphQLErrors.map(({ message, locations, path }) =>
  //   //       console.log(
  //   //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //   //       )
  //   //     );
  //   //   if (networkError) console.log(`[Network error]: ${networkError}`);
  //   // }),
  //   // authMiddleware,
  //   HttpLink({
  //     uri: credentials.serveruri
  //   })
  // ]),
  cache: new InMemoryCache(),
});

// import { ApolloClient } from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { ApolloLink } from 'apollo-client-preset';
// import persist from './persist';

// let apolloClient = null;

// const httpLink = createHttpLink({
//   uri: 'http://192.168.2.15:3000/graphql',
//   credentials: 'include'
// });

// function createClient(headers, token, initialState) {
//   let accessToken = token;

//   (async () => {
//     // eslint-disable-next-line no-param-reassign
//     accessToken = token || (await persist.willGetAccessToken());
//   })();

//   const authLink = new ApolloLink((operation, forward) => {
//     operation.setContext({
//       headers: {
//         // TODO: Remove the unneeded one
//         authorization: `Bearer ${accessToken}`,
//         'meteor-login-token': accessToken
//       }
//     });
//     return forward(operation);
//   }).concat(httpLink);

//   return new ApolloClient({
//     headers,
//     link: authLink,
//     connectToDevTools: process.browser,
//     ssrMode: !process.browser,
//     cache: new InMemoryCache().restore(initialState || {})
//   });
// }

// export default (headers, token, initialState) => {
//   if (!process.browser) {
//     return createClient(headers, token, initialState);
//   }
//   if (!apolloClient) {
//     apolloClient = createClient(headers, token, initialState);
//   }
//   return apolloClient;
// };
