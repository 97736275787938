import React, { useState } from 'react';
import { StyleSheet, View, FlatList, TouchableOpacity, Image, Platform } from 'react-native';

// import { StyleSheet, View } from 'react-native';
import { useQuery } from '@apollo/client';
import { debounce, uniqBy } from 'lodash';
// import { withRouter } from 'next/router';
import { currentUserQuery, getChallengesQuery } from '../../api/graphql/queries';
import PromptCard from '../../components/PromptCard';
import Colors from '../../constants/Colors';
import { FlatButton } from '../../components/StyledButton';
import LoadingIndicator from '../../components/LoadingIndicator';
// import { BottomNavigation } from 'react-native-material-ui';
import { AntDesign } from '@expo/vector-icons';
import { analyticsActionForButtons } from '../../lib/analyticsButtonClickHelper';

// const Card = ({ id, title, tag, content, username, responsesCount, anonymous }) => (
//   <View accessibilityRole="link" href={`/prompts/${id}`} as={`/prompts/${id}`}>
//     <View>
//       <Text>{title}</Text>
//       <Text>{tag}</Text>
//     </View>
//     <Text>{content}</Text>
//     <Text>{username}</Text>
//     <Text>{responsesCount}</Text>
//     <Text>{anonymous}</Text>
//   </View>
// );

const Prompts = props => {
  const [selectedSort, updateSort] = useState('hotRank');
  const [tag, setTag] = useState('');

  const { loading, data, refetch, fetchMore, networkStatus } = useQuery(getChallengesQuery, {
    variables: { limit: 20, offset: 0, sort: selectedSort, tag: tag },
    notifyOnNetworkStatusChange: true,
  });
  const { data: userData = {} } = useQuery(currentUserQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const { currentUser } = userData;

  const mappedData = data?.challenges?.map(challenge => ({
    ...challenge,
    content: challenge?.steps?.[0]?.content || '',
    username: challenge?.user?.username,
    userId: challenge?.user?.id,
  }));

  const onListEndReachedWithoutDebounce = () => {
    if (props.navigation?.state?.routeName === 'prompts' && !loading) {
      fetchMore &&
        fetchMore({
          variables: {
            offset: data?.challenges?.length || 0,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }
            console.log('prev: ', prev.challenges.length);
            console.log('fetchMoreResult: ', fetchMoreResult.challenges.length);
            const updatedResult = Object.assign({}, prev, {
              challenges: uniqBy([...prev.challenges, ...fetchMoreResult.challenges], ch => ch?.id),
            });
            return updatedResult;
          },
        });
    }
  };

  const onListEndReached = debounce(onListEndReachedWithoutDebounce, 500);

  const sortingButtons = [
    {
      title: 'Hot',
      transparent: true,
      imageSource: require('../../assets/images/icons/flame.png'),
      imageRightSide: true,
      onPress: () => {
        updateSort('hotRank');
        // refetch();
        console.log(selectedSort);
      },
      active: selectedSort === 'hotRank',
    },
    {
      title: 'Top',
      transparent: true,
      imageRightSide: true,
      onPress: () => {
        console.log('-------');
        updateSort('numResponses');
        // refetch();
        console.log(selectedSort);
      },
      active: selectedSort === 'numResponses',
    },
    {
      title: 'New',
      transparent: true,
      imageRightSide: true,
      onPress: () => {
        console.log('-------');
        updateSort('createdAt');
        // refetch();
        console.log(selectedSort);
      },
      active: selectedSort === 'createdAt',
    },
  ];

  const ListHeaderComponent = () => (
    <View
      style={{
        paddingLeft: 20,
        alignSelf: 'center',
        maxWidth: 800,
        width: '100%',
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          width: '100%',
          justifyContent: 'flex-start',
        }}>
        <FlatButton
          titleForAnalytics="hot sort button (prompts)"
          {...sortingButtons[0]}
          style={{ minWidth: 70 }}
          testID={sortingButtons[0].title}
        />
        <FlatButton
          titleForAnalytics="top sort button (prompts)"
          {...sortingButtons[1]}
          style={{ minWidth: 70 }}
          testID={sortingButtons[1].title}
        />
        <FlatButton
          titleForAnalytics="new sort button (prompts)"
          {...sortingButtons[2]}
          testID={sortingButtons[2].title}
          style={{ minWidth: 70 }}
        />

        <LoadingIndicator
          size="small"
          style={{ marginLeft: 10 }}
          overrideStyles
          animating={loading}
          color={Colors.orange}
        />
      </View>
      {tag ? (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={{ position: 'relative', marginTop: 7 }}>
            <FlatButton
              testID={'prompts-tag-cancel-button'}
              style={{
                minWidth: 70,
                paddingRight: 40,
                paddingLeft: 10,
                paddingVertical: 2,
                backgroundColor: Colors.lightGrey,
                borderRadius: 6,
              }}
              textStyle={{ color: Colors.darkGrey, fontSize: 12 }}
              title={`#${tag}`}
              titleForAnalytics="prompts tag"
              onPress={() => {
                setTag('');
              }}
            />
            <AntDesign
              style={{ position: 'absolute', top: 6, right: 6 }}
              name="close"
              size={20}
              color={Colors.black}
              onPress={() => setTag('')}
            />
          </View>
        </View>
      ) : null}
    </View>
  );

  return (
    <View style={styles.container}>
      {/* {isLargeScreen && (
        <View style={{ alignItems: 'flex-end' }}>
          <View style={{ alignItems: 'flex-start', paddingTop: 50 }}>
            <FlatButton {...sortingButtons[0]} />
            <FlatButton {...sortingButtons[1]} />
            <FlatButton {...sortingButtons[2]} />
          </View>
        </View>
      )} */}
      {networkStatus === 4 || networkStatus === 3 ? <LoadingIndicator /> : null}
      <FlatList
        data={mappedData}
        onEndReached={onListEndReached}
        ListHeaderComponent={ListHeaderComponent}
        refreshing={false}
        onRefresh={refetch}
        renderItem={({ item }) => (
          <PromptCard
            {...item}
            cardType="prompt"
            onPress={() => {
              props.navigation.navigate('responses', { id: item.id });
            }}
            testID={`Prompt.${selectedSort}.${item.id}`}
            currentUser={currentUser}
            refetch={refetch}
            setTag={setTag}
          />
        )}
        keyExtractor={({ id }) => id}
        style={{ paddingVertical: Platform.OS === 'web' ? 50 : 10 }}
      />
      {currentUser && (
        <TouchableOpacity
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          testID={'Button.NewPrompt'}
          onPress={() => {
            props.navigation.navigate('create');
            analyticsActionForButtons('prompts screen - pen icon - create prompt');
          }}
          style={{ zIndex: 9999, position: 'absolute', bottom: 10, right: 10 }}>
          <Image
            source={require('../../assets/images/icons/pen.png')}
            style={{
              width: 100,
              height: 100,
            }}
            resizeMode="contain"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    // paddingTop: Platform.OS === 'web' ? 70 : 0,
    // alignItems: 'center',
    // flexGrow: 1,
    // justifyContent: 'center',
    // alignContent: 'center',
    backgroundColor: Colors.backgroundGrey,
    // paddingVertical: 100
  },
  link: {
    color: 'blue',
  },
  textContainer: {
    alignItems: 'center',
    marginTop: 16,
  },
  text: {
    alignItems: 'center',
    fontSize: 24,
    marginBottom: 24,
  },
});

export default Prompts;
